import React, { FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./_index.module.scss";
import TopAppBar from "./components/topAppBar";
import { MonthlyExamPreparationSubject } from "../../models/monthlyExamPreparationSubject";
import useMonthlyExamPreparations from "../../hooks/queries/useMonthlyExamPreparations";
import LoadingError from "../../components/loadingError";
import Spinner from "../../components/spinner";
import MonthlyExamPreparations from "../../models/monthlyExamPreparations";
import MonthlyExamPreparation from "../../models/monthlyExamPreparation";
import { AllAlienPositions } from "./models/alienPosition";
import go_forward from "./assets/go_forward.svg";
import go_backward from "./assets/go_backward.svg";
import AbsolutelyPositionedBox from "../../components/absolutelyPositionedBox";
import IconButton from "../loginCalendar/components/iconButton";
import ToggleSwitch from "./components/toggleSwitch";
import Planet from "./components/planet";
import AlienUiState from "./models/alienUiState";
import ClosestPreparationBalloon from "./components/closestPreparationBalloon";
import SelectedPreparationBalloon from "./components/selectedPreparationBalloon";
import { AlienAsset } from "./models/alienAsset";
import { chunked } from "../../collection";
import QuizCountSettingDialog from "../../components/quizCountSettingDialog";
import useQuizCountSettings from "../../hooks/queries/useQuizCountSettings";
import useSaveMonthlyExamPreparationQuizCountSetting from "../../hooks/queries/useSaveMonthlyExamPreparationQuizCountSetting";
import Toast from "../../components/toast";

const PER_PAGE = 4;

interface ContentProps {
  allPreparations: MonthlyExamPreparations;
  onStartClick: (
    subject: MonthlyExamPreparationSubject,
    preparationNumber: number
  ) => void;
}

const Content: FC<ContentProps> = ({ allPreparations, onStartClick }) => {
  const [subject, setSubject] = useState<MonthlyExamPreparationSubject>(
    MonthlyExamPreparationSubject.Science
  );
  const [selectedPreparation, setSelectedPreparation] =
    useState<MonthlyExamPreparation>();

  const alienAsset = useMemo(
    () =>
      subject === MonthlyExamPreparationSubject.Science
        ? AlienAsset.Pink
        : AlienAsset.Blue,
    [subject]
  );

  const pages = useMemo(() => {
    const preparations =
      subject === MonthlyExamPreparationSubject.Science
        ? allPreparations.science
        : allPreparations.socialStudies;

    return chunked(preparations, PER_PAGE);
  }, [allPreparations, subject]);

  const [pageIndex, setPageIndex] = useState(
    Math.max(
      pages.findIndex((page) =>
        page.some((preparation) => {
          const now = new Date();

          return now >= preparation.startAt && now <= preparation.endAt;
        })
      ),
      0
    )
  );

  const canGoBack = useMemo(() => pageIndex > 0, [pageIndex]);
  const canGoForward = useMemo(
    () => pageIndex < pages.length - 1,
    [pages, pageIndex]
  );

  const alienGroupList: AlienUiState[][] = useMemo(() => {
    const now = new Date();

    return pages.map((preparations) =>
      preparations.map((preparation, index) => ({
        preparation,
        asset: alienAsset,
        closestPreparationBalloon:
          AllAlienPositions[index].closestPreparationBalloon,
        selectedPreparationBalloon: {
          ...AllAlienPositions[index].selectedPreparationBalloon,
          preparationNumber: preparation.number,
        },
        isSelected: preparation.number === selectedPreparation?.number,
        isClosestExamPreparation:
          now >= preparation.startAt && now <= preparation.endAt,
        showLabel: selectedPreparation === undefined,
      }))
    );
  }, [pages, alienAsset, selectedPreparation]);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        flex: 1,
      }}
    >
      <svg className={styles["balloon-stroke-area"]}>
        {alienGroupList.map((alienGroup, groupIndex) =>
          alienGroup.map((alien) => (
            <g key={alien.preparation.number}>
              <line
                className={styles.stroke}
                style={{
                  opacity:
                    selectedPreparation === undefined &&
                    groupIndex === pageIndex &&
                    alien.isClosestExamPreparation
                      ? 1
                      : 0,
                }}
                fill="none"
                stroke="#C7F5FF"
                strokeWidth="3px"
                x1={alien.closestPreparationBalloon.stroke.x1}
                y1={alien.closestPreparationBalloon.stroke.y1}
                x2={alien.closestPreparationBalloon.stroke.x2}
                y2={alien.closestPreparationBalloon.stroke.y2}
              />

              <line
                className={styles.stroke}
                style={{
                  opacity: groupIndex === pageIndex && alien.isSelected ? 1 : 0,
                }}
                fill="none"
                stroke="#C7F5FF"
                strokeWidth="3px"
                x1={alien.selectedPreparationBalloon.stroke.x1}
                y1={alien.selectedPreparationBalloon.stroke.y1}
                x2={alien.selectedPreparationBalloon.stroke.x2}
                y2={alien.selectedPreparationBalloon.stroke.y2}
              />
            </g>
          ))
        )}
      </svg>

      <Planet
        pageIndex={pageIndex}
        alienGroupList={alienGroupList}
        onAlienClick={(preparation) => setSelectedPreparation(preparation)}
      />

      {canGoBack && (
        <AbsolutelyPositionedBox top="400px" left="16px">
          <IconButton onClick={() => setPageIndex(pageIndex - 1)}>
            <img src={go_backward} alt="" />
          </IconButton>
        </AbsolutelyPositionedBox>
      )}

      {canGoForward && (
        <AbsolutelyPositionedBox top="400px" right="16px">
          <IconButton onClick={() => setPageIndex(pageIndex + 1)}>
            <img src={go_forward} alt="" />
          </IconButton>
        </AbsolutelyPositionedBox>
      )}

      <div className={styles.toggle}>
        <ToggleSwitch
          isCheckedFirst={subject === MonthlyExamPreparationSubject.Science}
          onClick={() =>
            setSubject(
              subject === MonthlyExamPreparationSubject.Science
                ? MonthlyExamPreparationSubject.SocialStudies
                : MonthlyExamPreparationSubject.Science
            )
          }
        />
      </div>

      {selectedPreparation && (
        <button
          type="button"
          aria-label="閉じる"
          className={styles["click-handler"]}
          onClick={() => setSelectedPreparation(undefined)}
        />
      )}

      {alienGroupList.map((alienGroup, groupIndex) =>
        alienGroup.map((alien) => (
          <div key={alien.preparation.number}>
            <ClosestPreparationBalloon
              balloon={alien.closestPreparationBalloon}
              isVisible={
                selectedPreparation === undefined &&
                groupIndex === pageIndex &&
                alien.isClosestExamPreparation
              }
            />

            <SelectedPreparationBalloon
              balloon={alien.selectedPreparationBalloon}
              isVisible={groupIndex === pageIndex && alien.isSelected}
              onStartClick={(preparationNumber) =>
                onStartClick(subject, preparationNumber)
              }
            />
          </div>
        ))
      )}
    </div>
  );
};

const MonthlyExamPreparationSelection: FC = () => {
  const navigate = useNavigate();

  const {
    data: preparations,
    isError: isPreparationsLoadError,
    refetch: refetchPreparations,
  } = useMonthlyExamPreparations();

  const {
    isError: isSettingsLoadError,
    data: settings,
    refetch: refetchSettings,
  } = useQuizCountSettings();

  const {
    mutate,
    isError: isSaveError,
    reset,
  } = useSaveMonthlyExamPreparationQuizCountSetting();

  const isError = useMemo(
    () => isPreparationsLoadError || isSettingsLoadError,
    [isPreparationsLoadError, isSettingsLoadError]
  );

  const [showSettingDialog, setShowSettingDialog] = useState(false);

  const [showSaveError, setShowSaveError] = useState(false);

  useEffect(() => {
    setShowSaveError(isSaveError);
  }, [isSaveError]);

  const child = (() => {
    if (isError) {
      return (
        <LoadingError
          onRetry={() => {
            refetchPreparations();
            refetchSettings();
          }}
        />
      );
    }

    if (preparations === undefined || settings === undefined) {
      return <Spinner />;
    }

    return (
      <Content
        allPreparations={preparations}
        onStartClick={(subject, preparationNumber) =>
          navigate(`${subject.name}/${preparationNumber}`, { replace: true })
        }
      />
    );
  })();

  return (
    <div className={styles.MonthlyExamPreparationSelection}>
      <TopAppBar
        onHomeIconClick={() => navigate("/", { replace: true })}
        onPreviousClick={() => navigate("/", { replace: true })}
        onRocketClick={() => navigate("/rocket", { replace: true })}
        onSettingClick={() => setShowSettingDialog(true)}
      />

      {child}

      {showSettingDialog && (
        <QuizCountSettingDialog
          initialSetting={settings?.monthlyExamPreparation}
          variant="navy"
          onDismiss={(setting) => {
            if (settings?.monthlyExamPreparation !== setting) {
              mutate(setting);
            }
            setShowSettingDialog(false);
          }}
        >
          1回の「月例テスト対策」で出題される問題数を設定できます
        </QuizCountSettingDialog>
      )}

      {showSaveError && (
        <div className={styles["toast-container"]}>
          <Toast
            onDismiss={() => {
              reset();
            }}
          >
            設定の保存に失敗しました 時間をおいて再度お試しください
          </Toast>
        </div>
      )}
    </div>
  );
};

export default MonthlyExamPreparationSelection;
