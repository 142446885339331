import React, { FC, useMemo } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { UiState } from "./models/uiState/uiState";
import Acquired from "./models/uiState/acquired";
import { Subject } from "../../models/subject";
import {
  mapNavQuizTypeToModel,
  mapQuizTypeToNavModel,
  NavQuizType,
} from "../../navigation/models/navQuizType";
import NormalResult from "./components/normalResult";
import NormalNavigationActions from "./components/normalNavigationActions";
import QuizCleared from "./components/quizCleared";
import ClearedNavigationActions from "./components/cleardNavigationActions";
import Normal from "./models/uiState/normal";
import styles from "./_index.module.scss";

export interface QuizFinishedState {
  incorrectQuizzes: NavQuizType[];
  categoryName: string;
  correctAnswerCount?: number;
  totalQuizCount?: number;
}

const extractUiState = (state: QuizFinishedState): UiState | undefined => {
  if (!state) {
    return undefined;
  }

  const incorrectQuizzes = state.incorrectQuizzes.map(mapNavQuizTypeToModel);

  if (
    state.correctAnswerCount !== undefined &&
    state.totalQuizCount !== undefined &&
    state.correctAnswerCount <= state.totalQuizCount
  ) {
    return new Normal(
      incorrectQuizzes,
      state.categoryName,
      state.correctAnswerCount,
      state.totalQuizCount
    );
  }

  return new Acquired(state.categoryName);
};

export interface QuizFinishedProps {
  subject: Subject;
}

const QuizFinished: FC<QuizFinishedProps> = ({ subject }) => {
  const { state } = useLocation();
  const uiState = useMemo(
    () => extractUiState(state as QuizFinishedState),
    [state]
  );

  const { categoryId } = useParams();

  const navigate = useNavigate();

  if (uiState === undefined || categoryId === undefined) {
    return <Navigate to="/" replace />;
  }

  const onHomeClick = () => navigate("/", { replace: true });
  const onAnotherClick = () =>
    navigate(`/quiz/subject/${subject.name}`, { replace: true });

  return (
    <div className={styles.QuizFinished}>
      {uiState instanceof Acquired ? (
        <QuizCleared text={`${uiState.categoryName}をクリアしたよ！`}>
          <ClearedNavigationActions
            onHomeClick={onHomeClick}
            onBackClick={onAnotherClick}
          />
        </QuizCleared>
      ) : (
        <NormalResult
          correctAnswerCount={uiState.correctAnswerCount}
          totalQuizCount={uiState.totalQuizCount}
        >
          <NormalNavigationActions
            reviewDisabled={uiState.incorrectQuizzes.length === 0}
            onHomeClick={onHomeClick}
            onRetryClick={() => {
              navigate(`/quiz/subject/${subject.name}/category/${categoryId}`, {
                replace: true,
                state: {
                  categoryName: uiState.categoryName,
                },
              });
            }}
            onBackClick={onAnotherClick}
            onReviewClick={() => {
              navigate(`/quiz/subject/${subject.name}/category/${categoryId}`, {
                replace: true,
                state: {
                  categoryName: uiState.categoryName,
                  incorrectQuizzes: uiState.incorrectQuizzes.map(
                    mapQuizTypeToNavModel
                  ),
                },
              });
            }}
          />
        </NormalResult>
      )}
    </div>
  );
};

export default QuizFinished;
