import Quiz from "models/quiz";
import SelectionQuizBChoice from "models/selectionQuizB/selectionQuizBChoice";

export default class SelectionQuizB implements Quiz {
  constructor(
    public readonly id: string,
    public readonly sentence: string,
    public readonly correctAnswerImageUrl: string,
    public readonly wrongAnswerImageUrl: string,
    public readonly timeLimitSeconds: number,
    public readonly consecutiveCorrectAnswerCount: number,
    public readonly imageUrl: string,
    public readonly choices: SelectionQuizBChoice[],
    public readonly correctChoiceId: string
  ) {}

  get quizAssetUrls(): string[] {
    return [this.imageUrl];
  }

  copy(args: { consecutiveCorrectAnswerCount: number }): SelectionQuizB {
    return new SelectionQuizB(
      this.id,
      this.sentence,
      this.correctAnswerImageUrl,
      this.wrongAnswerImageUrl,
      this.timeLimitSeconds,
      args.consecutiveCorrectAnswerCount,
      this.imageUrl,
      this.choices,
      this.correctChoiceId
    );
  }
}
