import { Subject } from "models/subject";
import { MonthlyExamPreparationSubject } from "../../models/monthlyExamPreparationSubject";

export const getQuizCategoriesQueryKey = (subject: Subject) => [
  "quizCategories",
  subject,
];

export const getQuizzesQueryKey = (categoryId: string) => [
  "quizzes",
  categoryId,
];

export const getTrainingCategoriesQueryKey = (subject: Subject) => [
  "trainingCategories",
  subject,
];

export const getTrainingPagesQueryKey = (categoryId: string) => [
  "trainingPages",
  categoryId,
];

export const getLoggedInDatesQueryKey = (year: number, month: number) => [
  "loggedInDates",
  year,
  month,
];

export const getCorrectAnswerRateQueryKey = (year: number, month: number) => [
  "correctAnswerRate",
  year,
  month,
];

export const getStudentQueryKey = () => ["currentStudent"];

export const getMonthlyExamPreparationsKey = () => ["monthlyExamPreparations"];

export const getMonthlyExamQuizzesKey = (
  subject: MonthlyExamPreparationSubject,
  preparationNumber: number
) => ["monthlyExamQuizzes", subject, preparationNumber];

export const getDebugQuizKey = (quizId: string) => ["debugQuiz", quizId];

export const getQuizCountSettingsKey = () => ["quizCountSettings"];
