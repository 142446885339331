import React, { FC, ReactNode } from "react";
import styles from "./_index.module.scss";

export interface LabeledIconButtonProps {
  icon: ReactNode;
  label: string;
  onClick: () => void;
}

const LabeledIconButton: FC<LabeledIconButtonProps> = ({
  icon,
  label,
  onClick,
}) => (
  <div className={styles.LabeledIconButton}>
    <button className={styles.button} type="button" onClick={onClick}>
      {icon}
    </button>

    <span className={styles.label}>{label}</span>
  </div>
);

export default LabeledIconButton;
