import SelectionQuizAChoice from "models/selectionQuizA/selectionQuizAChoice";
import Quiz from "models/quiz";

export default class SelectionQuizA implements Quiz {
  constructor(
    public readonly id: string,
    public readonly sentence: string,
    public readonly correctAnswerImageUrl: string,
    public readonly wrongAnswerImageUrl: string,
    public readonly timeLimitSeconds: number,
    public readonly consecutiveCorrectAnswerCount: number,
    public readonly imageUrl: string,
    public readonly choices: SelectionQuizAChoice[],
    public readonly correctChoiceId: string
  ) {}

  get quizAssetUrls(): string[] {
    return [this.imageUrl];
  }

  copy(args: { consecutiveCorrectAnswerCount: number }): SelectionQuizA {
    return new SelectionQuizA(
      this.id,
      this.sentence,
      this.correctAnswerImageUrl,
      this.wrongAnswerImageUrl,
      this.timeLimitSeconds,
      args.consecutiveCorrectAnswerCount,
      this.imageUrl,
      this.choices,
      this.correctChoiceId
    );
  }
}
