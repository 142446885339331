import classNames from "classnames";
import AbsolutelyPositionedBox from "components/absolutelyPositionedBox";
import RubiedText from "components/rubiedText";
import useCountRubiedText from "features/quiz/hooks/useCountRubiedText";
import SelectionQuizB from "models/selectionQuizB/selectionQuizB";
import SelectionQuizBAnswer from "models/selectionQuizB/selectionQuizBAnswer";
import SelectionQuizBChoice from "models/selectionQuizB/selectionQuizBChoice";
import React, { FC, useMemo } from "react";
import styles from "./_index.module.scss";

type ButtonState = "neutral" | "selected" | "not_selected";

interface ChoiceButtonProps {
  choice: SelectionQuizBChoice;
  state: ButtonState;
  fontStyle: string;
  onClick: () => void;
}

const ChoiceButton: FC<ChoiceButtonProps> = ({
  choice,
  state,
  fontStyle,
  onClick,
}) => {
  const classes = classNames({
    [styles["button-selected"]]: state === "selected",
    [styles["button-not-selected"]]: state === "not_selected",
  });

  return (
    <button type="button" className={classes} onClick={onClick}>
      <RubiedText className={fontStyle} rubiedTextHtml={choice.name} />
    </button>
  );
};

export interface QuizFmtSelectionBProps {
  quiz: SelectionQuizB;
  answer?: SelectionQuizBAnswer;
  onAnswerChanged: (answer: SelectionQuizBAnswer) => void;
}

const QuizFmtSelectionB: FC<QuizFmtSelectionBProps> = ({
  quiz,
  answer,
  onAnswerChanged,
}) => {
  const maxLength = Math.max(
    ...quiz.choices.map((element) => {
      const length = useCountRubiedText(element.name);
      return length;
    })
  );

  const fontStyles = useMemo(() => {
    const isLongText = maxLength > 6;
    return classNames(isLongText ? styles["small-font"] : styles["large-font"]);
  }, [quiz]);

  return (
    <div className={styles.QuizFmtSelectionB}>
      <AbsolutelyPositionedBox top="130px" left="140px">
        <div className={styles["question-img-container"]}>
          <img className={styles["question-img"]} alt="" src={quiz.imageUrl} />
        </div>
      </AbsolutelyPositionedBox>
      <AbsolutelyPositionedBox top="110px" left="840px">
        <div className={styles["choice-zone"]}>
          {quiz.choices.map((choice) => (
            <ChoiceButton
              key={choice.id}
              choice={choice}
              fontStyle={fontStyles}
              state={(() => {
                if (!answer) {
                  return "neutral";
                }

                return choice.id === answer.choiceId
                  ? "selected"
                  : "not_selected";
              })()}
              onClick={() =>
                onAnswerChanged(new SelectionQuizBAnswer(choice.id))
              }
            />
          ))}
        </div>
      </AbsolutelyPositionedBox>
    </div>
  );
};

export default QuizFmtSelectionB;
