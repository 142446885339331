import React, { FC, ReactNode } from "react";
import styles from "./_index.module.scss";

export interface IconButtonProps {
  children: ReactNode;
  onClick: () => void;
}

const IconButton: FC<IconButtonProps> = ({ children, onClick }) => (
  <button className={styles.IconButton} type="button" onClick={onClick}>
    {children}
  </button>
);

export default IconButton;
