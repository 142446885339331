import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./_index.module.scss";
import AlertDialogButton from "../../components/alertDialogButton";
import Spacer from "../../components/spacer";

const Inquiry: FC = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.Inquiry}>
      <div className={styles.container}>
        <span className={styles.title}>お問い合わせご連絡先</span>

        <Spacer size="24px" />

        <span className={styles.description}>
          ご質問・不具合等に関するお問い合わせは
          <br />
          下記の宛先までご連絡ください
        </span>

        <Spacer size="48px" />

        <span className={styles.company}>四谷大塚出版</span>

        <Spacer size="8px" />

        <span className={styles.email}>
          <a href="mailto:syuppan@yotsuya-otsuka.co.jp">
            syuppan@yotsuya-otsuka.co.jp
          </a>
        </span>

        <Spacer size="64px" />

        <span className={styles.title}>推奨環境について</span>

        <Spacer size="24px" />

        <span className={styles["sub-title"]}>iPad / iPhone からのご利用</span>

        <Spacer size="4px" />

        <span className={styles.description}>
          iOS 13 以降 (ブラウザは Safari または Chrome をご利用ください)
        </span>

        <Spacer size="24px" />

        <span className={styles["sub-title"]}>Android からのご利用</span>

        <Spacer size="4px" />

        <span className={styles.description}>
          Android 12 以降 (ブラウザは Chrome をご利用ください)
        </span>

        <Spacer size="24px" />

        <span className={styles["sub-title"]}>PC からのご利用</span>

        <Spacer size="4px" />

        <span className={styles.description}>
          Chrome 116 以降 / Safari 13 以降
        </span>

        <Spacer size="64px" />

        <div className={styles.button}>
          <AlertDialogButton onClick={() => navigate("/", { replace: true })}>
            ホームに戻る
          </AlertDialogButton>
        </div>
      </div>
    </div>
  );
};

export default Inquiry;
