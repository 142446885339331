import React, { FC } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import invertedPenguin from "features/quizUnlockAnimation/assets/invertedPenguin.svg";
import AbsolutelyPositionedBox from "components/absolutelyPositionedBox";
import { Subject } from "models/subject";
import styles from "./_index.module.scss";
import QuizCategoryList from "./components/quizCategoryList";
import QuizCategoryModal from "./components/quizCategoryModal";
import useListQuizCategories from "../../hooks/queries/useListQuizCategories";
import Spinner from "../../components/spinner";
import LoadingError from "../../components/loadingError";

type QuizUnlockAnimationProps = {
  subject: Subject;
};

type UnlockCategory = {
  id: number;
  name: string;
};

const QuizUnlockAnimation: FC<QuizUnlockAnimationProps> = ({ subject }) => {
  let className: string;
  const navigate = useNavigate();
  const location = useLocation();
  const unlockCategory = location.state as UnlockCategory;

  const { isLoading, isError, data, refetch } = useListQuizCategories({
    subject,
  });

  if (unlockCategory.name === undefined) {
    return <Navigate to="/" replace />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (isError || data === undefined) {
    return <LoadingError onRetry={refetch} />;
  }

  switch (subject) {
    case Subject.Science:
      className = styles.CategorySelectionScience;
      break;
    default:
      className = styles.CategorySelectionSocialStudies;
      break;
  }

  return (
    <div className={className}>
      <AbsolutelyPositionedBox top="0px" left="10px">
        <div className={styles.unlockFukidashi}>
          <p>
            {`しゅぎょうモード「${unlockCategory.name}」のロックが解除されたよ！`}
          </p>
        </div>
      </AbsolutelyPositionedBox>
      <AbsolutelyPositionedBox top="-30px" left="600px">
        <img src={invertedPenguin} alt="penguin" />
      </AbsolutelyPositionedBox>
      <div className={styles.categoryList}>
        <QuizCategoryList
          quizCategories={data}
          unlockCategory={unlockCategory.name}
        />
      </div>
      <QuizCategoryModal
        quiz={() =>
          navigate(`/quiz/subject/${subject.name}`, {
            replace: true,
            state: { targetCategoryId: unlockCategory.id },
          })
        }
        other={() =>
          navigate(`/training/subject/${subject.name}`, { replace: true })
        }
        home={() => navigate("/", { replace: true })}
      />
    </div>
  );
};

export default QuizUnlockAnimation;
