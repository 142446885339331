export const Subject = {
  Science: {
    name: "science",
    label: "理科",
    labelRubi: "りか",
  },
  SocialStudies: {
    name: "social-studies",
    label: "社会",
    labelRubi: "しゃかい",
  },
} as const;

// eslint-disable-next-line
export type Subject = typeof Subject[keyof typeof Subject];
export const AllSubjects: Subject[] = Object.values(Subject);
