import SelectionQuizAAnswer from "models/selectionQuizA/selectionQuizAAnswer";
import RearrangementQuizAnswer from "models/rearrangementQuiz/rearrangementQuizAnswer";
import { QuizType } from "models/quizType";
import { QuizAnswerType } from "models/quizAnswerType";
import { useImmerReducer } from "use-immer";
import { Draft } from "immer";
import SelectionQuizBAnswer from "models/selectionQuizB/selectionQuizBAnswer";
import SelectionQuizCAnswer from "models/selectionQuizC/selectionQuizCAnswer";
import TextInputQuizAnswer from "models/textInputQuiz/textInputQuizAnswer";

export interface QuizScreenState {
  currentQuizIndex: number;
  isAnswering: boolean;
  isPausing: boolean;
  selectionQuizAAnswers: { [quizId: string]: SelectionQuizAAnswer };
  selectionQuizBAnswers: { [quizId: string]: SelectionQuizBAnswer };
  selectionQuizCAnswers: { [quizId: string]: SelectionQuizCAnswer };
  rearrangementQuizAnswers: { [quizId: string]: RearrangementQuizAnswer };
  textInputQuizAnswers: { [quizId: string]: TextInputQuizAnswer };
  elapsedTimeMillisMap: { [quizId: string]: number | undefined };
}

export type QuizScreenAction =
  | {
      type: "answer_quiz";
      quiz: QuizType;
      answer: QuizAnswerType;
    }
  | {
      type: "confirm_answer";
      quiz: QuizType;
      elapsedTimeMillis: number;
    }
  | {
      type: "pause_timer";
      quiz: QuizType;
      elapsedTimeMillis: number;
    }
  | {
      type: "confirm_answer_explanation";
    }
  | {
      type: "pause_answer_explanation";
    }
  | {
      type: "resume";
    }
  | {
      type: "reset";
    }
  | {
      type: "back";
    };

/* eslint-disable no-param-reassign */
const setAnswer = (
  draft: Draft<QuizScreenState>,
  quiz: QuizType,
  answer: QuizAnswerType
) => {
  if (answer instanceof SelectionQuizAAnswer) {
    draft.selectionQuizAAnswers[quiz.id] = answer;
    return;
  }

  if (answer instanceof SelectionQuizBAnswer) {
    draft.selectionQuizBAnswers[quiz.id] = answer;
    return;
  }

  if (answer instanceof SelectionQuizCAnswer) {
    draft.selectionQuizCAnswers[quiz.id] = answer;
    return;
  }

  if (answer instanceof RearrangementQuizAnswer) {
    const newCardPosition = answer.positions[0];
    const currentAnswer = draft.rearrangementQuizAnswers[quiz.id];
    const newCardPositions = (currentAnswer?.positions ?? [])
      .filter(
        (position) => position.card.choiceId !== newCardPosition.card.choiceId
      )
      .concat(newCardPosition);
    draft.rearrangementQuizAnswers[quiz.id] = new RearrangementQuizAnswer(
      newCardPositions
    );
    return;
  }

  draft.textInputQuizAnswers[quiz.id] = answer;
};

/* eslint-disable no-param-reassign */
const incrementElapsedTime = (
  draft: Draft<QuizScreenState>,
  quiz: QuizType,
  elapsedTimeMillis: number
) => {
  const currentElapsedTimeMillis = draft.elapsedTimeMillisMap[quiz.id] ?? 0;
  draft.elapsedTimeMillisMap[quiz.id] =
    currentElapsedTimeMillis + elapsedTimeMillis;
};

/* eslint-disable no-param-reassign */
const confirmAnswer = (
  draft: Draft<QuizScreenState>,
  quiz: QuizType,
  elapsedTimeMillis: number
) => {
  incrementElapsedTime(draft, quiz, elapsedTimeMillis);

  draft.isAnswering = false;
};

const reducer = (draft: Draft<QuizScreenState>, action: QuizScreenAction) => {
  switch (action.type) {
    case "answer_quiz":
      setAnswer(draft, action.quiz, action.answer);
      break;
    case "confirm_answer":
      confirmAnswer(draft, action.quiz, action.elapsedTimeMillis);
      break;
    case "pause_timer":
      incrementElapsedTime(draft, action.quiz, action.elapsedTimeMillis);
      draft.isPausing = true;
      break;
    case "confirm_answer_explanation":
      draft.currentQuizIndex += 1;
      draft.isAnswering = true;
      break;
    case "pause_answer_explanation":
      draft.isPausing = true;
      break;
    case "resume":
      draft.isPausing = false;
      break;
    case "reset":
      draft.currentQuizIndex = 0;
      draft.isAnswering = true;
      draft.isPausing = false;
      draft.selectionQuizAAnswers = {};
      draft.selectionQuizBAnswers = {};
      draft.selectionQuizCAnswers = {};
      draft.rearrangementQuizAnswers = {};
      draft.textInputQuizAnswers = {};
      draft.elapsedTimeMillisMap = {};
      break;
    case "back":
      draft.isAnswering = true;
      break;
    default:
      break;
  }
};

const useQuizScreenState = () =>
  useImmerReducer<QuizScreenState, QuizScreenAction>(reducer, {
    currentQuizIndex: 0,
    isAnswering: true,
    isPausing: false,
    selectionQuizAAnswers: {},
    selectionQuizBAnswers: {},
    selectionQuizCAnswers: {},
    rearrangementQuizAnswers: {},
    textInputQuizAnswers: {},
    elapsedTimeMillisMap: {},
  });

export default useQuizScreenState;
