import React, { FC } from "react";
import home from "assets/home.svg";
import styles from "./_index.module.scss";

export interface TopAppBarProps {
  onHomeClick: () => void;
}

const TopAppBar: FC<TopAppBarProps> = ({ onHomeClick }) => (
  <div className={styles.TopAppBar}>
    <button className={styles.home} type="button" onClick={onHomeClick}>
      <img src={home} alt="home" width="65.41px" height="67.18px" />
    </button>
  </div>
);

export default TopAppBar;
