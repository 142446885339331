import { DragLayerMonitor, useDragLayer, XYCoord } from "react-dnd";
import { CSSProperties, useContext } from "react";
import {
  PageContainerContext,
  PageContainerContextData,
} from "components/layouts/pageContainer";

function normalizePoint(
  point: XYCoord | null,
  context: PageContainerContextData
): XYCoord {
  if (point === null) {
    return { x: 0, y: 0 };
  }

  const { scale, translateX, translateY } = context;

  return {
    x: (point.x - translateX) / scale,
    y: (point.y - translateY) / scale,
  };
}

function subtract(a: XYCoord, b: XYCoord): XYCoord {
  return {
    x: a.x - b.x,
    y: a.y - b.y,
  };
}

export interface DragPreviewState<T> {
  item: T;
  style: CSSProperties;
}

export const useDragPreview = <T>(): DragPreviewState<T> | undefined => {
  const pageContainerContext = useContext(PageContainerContext);

  const collectedProps = useDragLayer((monitor: DragLayerMonitor) => ({
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
    item: monitor.getItem() as T,
    monitor,
  }));

  if (!collectedProps.isDragging || collectedProps.currentOffset === null) {
    return undefined;
  }

  const { item, monitor } = collectedProps;

  // ドラッグ中の要素を掴んだ時の要素の左上からのずれ
  const offsetFromLeftTop = subtract(
    normalizePoint(monitor.getInitialClientOffset(), pageContainerContext),
    normalizePoint(monitor.getInitialSourceClientOffset(), pageContainerContext)
  );

  const offset = subtract(
    normalizePoint(monitor.getClientOffset(), pageContainerContext),
    offsetFromLeftTop
  );

  const style: CSSProperties = {
    pointerEvents: "none",
    position: "fixed",
    top: 0,
    left: 0,
    WebkitTransform: `translate(${offset.x}px, ${offset.y}px)`,
  };

  return {
    item,
    style,
  };
};
