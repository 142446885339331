import { QuizUiState } from "features/quiz/models/uiState/quizUiState";
import { QuizType } from "models/quizType";
import LoadingQuiz from "features/quiz/models/uiState/loadingQuiz";
import { useCallback, useMemo } from "react";
import QuizLoadError from "features/quiz/models/uiState/quizLoadError";
import AnsweringQuiz from "features/quiz/models/uiState/answeringQuiz";
import { StudentAnswerType } from "models/studentAnswerType";
import SelectionQuizA from "models/selectionQuizA/selectionQuizA";
import SelectionQuizAStudentAnswer from "models/selectionQuizA/selectionQuizAStudentAnswer";
import RearrangementQuizStudentAnswer from "models/rearrangementQuiz/rearrangementQuizStudentAnswer";
import useQuizScreenState from "features/quiz/hooks/useQuizScreenState";
import ExplainingAnswer from "features/quiz/models/uiState/explainingAnswer";
import { QuizAnswerType } from "models/quizAnswerType";
import SelectionQuizB from "models/selectionQuizB/selectionQuizB";
import SelectionQuizBStudentAnswer from "models/selectionQuizB/selectionQuizBStudentAnswer";
import SelectionQuizC from "models/selectionQuizC/selectionQuizC";
import SelectionQuizCStudentAnswer from "models/selectionQuizC/selectionQuizCStudentAnswer";
import RearrangementQuiz from "models/rearrangementQuiz/rearrangementQuiz";
import TextInputQuizStudentAnswer from "models/textInputQuiz/textInputQuizStudentAnswer";
import home from "features/quiz/assets/home.svg";
import next from "features/quiz/assets/next.svg";
import pause from "features/quiz/assets/pause.svg";
import penguin from "features/quiz/assets/penguin.svg";
import penguinCorrectAnswer from "features/quiz/assets/penguin_correct_answer.svg";
import penguinWrongAnswer from "features/quiz/assets/penguin_wrong_answer.svg";
import stopwatch from "features/quiz/assets/stopwatch.svg";
import timeUp from "features/quiz/assets/time_up.svg";

import useImages from "hooks/useImages";

const uiAssetUrls = [
  home,
  next,
  pause,
  penguin,
  penguinCorrectAnswer,
  penguinWrongAnswer,
  stopwatch,
  timeUp,
];

const useDebugQuizUiState = (
  quizId: string,
  isLoadingQuiz: boolean,
  isQuizLoadError: boolean,
  quizzes: QuizType[]
): {
  uiState: QuizUiState;
  isPausing: boolean;
  onAnswerQuiz: (quiz: QuizType, answer: QuizAnswerType) => void;
  onConfirmAnswer: (quiz: QuizType, elapsedTimeMillis: number) => void;
  onPauseTimer: (quiz: QuizType, elapsedTimeMillis: number) => void;
  onPauseAnswerExplanation: () => void;
  onResume: () => void;
  onReset: () => void;
  onBack: () => void;
} => {
  const [
    {
      currentQuizIndex,
      isAnswering,
      isPausing,
      selectionQuizAAnswers,
      selectionQuizBAnswers,
      selectionQuizCAnswers,
      rearrangementQuizAnswers,
      textInputQuizAnswers,
      elapsedTimeMillisMap,
    },
    dispatch,
  ] = useQuizScreenState();

  const studentAnswers = useMemo<StudentAnswerType[]>(
    () =>
      quizzes.map((quiz) => {
        if (quiz instanceof SelectionQuizA) {
          return new SelectionQuizAStudentAnswer(
            quiz,
            selectionQuizAAnswers[quiz.id],
            elapsedTimeMillisMap[quiz.id]
          );
        }

        if (quiz instanceof SelectionQuizB) {
          return new SelectionQuizBStudentAnswer(
            quiz,
            selectionQuizBAnswers[quiz.id],
            elapsedTimeMillisMap[quiz.id]
          );
        }

        if (quiz instanceof SelectionQuizC) {
          return new SelectionQuizCStudentAnswer(
            quiz,
            selectionQuizCAnswers[quiz.id],
            elapsedTimeMillisMap[quiz.id]
          );
        }

        if (quiz instanceof RearrangementQuiz) {
          return new RearrangementQuizStudentAnswer(
            quiz,
            rearrangementQuizAnswers[quiz.id],
            elapsedTimeMillisMap[quiz.id]
          );
        }

        return new TextInputQuizStudentAnswer(
          quiz,
          textInputQuizAnswers[quiz.id],
          elapsedTimeMillisMap[quiz.id]
        );
      }),
    [
      quizzes,
      selectionQuizAAnswers,
      selectionQuizBAnswers,
      selectionQuizCAnswers,
      rearrangementQuizAnswers,
      textInputQuizAnswers,
      elapsedTimeMillisMap,
      isPausing,
    ]
  );

  const currentQuizNumber = useMemo(
    () => currentQuizIndex + 1,
    [currentQuizIndex]
  );

  const preloadImages = useMemo(() => {
    if (quizzes.length === 0) {
      return uiAssetUrls;
    }

    return uiAssetUrls.concat(quizzes[0].quizAssetUrls);
  }, [quizzes, uiAssetUrls]);
  const { isLoading: isImagesLoading } = useImages(preloadImages);

  const uiState = useMemo(() => {
    if (isQuizLoadError) {
      return new QuizLoadError();
    }

    if (isLoadingQuiz || isImagesLoading) {
      return new LoadingQuiz();
    }

    if (isAnswering) {
      return new AnsweringQuiz(
        currentQuizNumber,
        quizzes.length,
        studentAnswers[currentQuizIndex],
        isPausing
      );
    }

    return new ExplainingAnswer(
      currentQuizNumber,
      quizzes.length,
      studentAnswers[currentQuizIndex],
      currentQuizNumber !== quizzes.length
        ? quizzes[currentQuizIndex + 1]
        : undefined
    );
  }, [
    isLoadingQuiz,
    isImagesLoading,
    isQuizLoadError,
    studentAnswers,
    currentQuizIndex,
    isAnswering,
  ]);

  const onAnswerQuiz = useCallback((quiz: QuizType, answer: QuizAnswerType) => {
    dispatch({ type: "answer_quiz", quiz, answer });
  }, []);
  const onConfirmAnswer = useCallback(
    (quiz: QuizType, elapsedTimeMillis: number) => {
      dispatch({ type: "confirm_answer", quiz, elapsedTimeMillis });
    },
    []
  );
  const onPauseTimer = useCallback(
    (quiz: QuizType, elapsedTimeMillis: number) => {
      dispatch({ type: "pause_timer", quiz, elapsedTimeMillis });
    },
    []
  );
  const onBack = useCallback(() => {
    dispatch({ type: "back" });
  }, []);

  const onPauseAnswerExplanation = useCallback(() => {
    dispatch({ type: "pause_answer_explanation" });
  }, []);
  const onResume = useCallback(() => {
    dispatch({ type: "resume" });
  }, []);
  const onReset = useCallback(() => {
    dispatch({ type: "reset" });
  }, []);

  return {
    uiState,
    isPausing,
    onAnswerQuiz,
    onConfirmAnswer,
    onPauseTimer,
    onBack,
    onPauseAnswerExplanation,
    onResume,
    onReset,
  };
};

export default useDebugQuizUiState;
