import { useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

export const AssetType = {
  Image: 0,
} as const;

// eslint-disable-next-line
export type AssetType = typeof AssetType[keyof typeof AssetType];

export interface Asset {
  type: AssetType;
  url: string;
}

function loadImage(url: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.onload = () => resolve();
    image.onerror = () => reject();
  });
}

function loadAsset({ type, url }: Asset): Promise<void> {
  switch (type) {
    case AssetType.Image:
      return loadImage(url);
    default:
      return loadImage(url);
  }
}

export const usePreload = (assets: Asset[]) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useDeepCompareEffect(() => {
    setIsLoading(true);
    setIsError(false);

    Promise.all(assets.map(loadAsset))
      .then(() => {})
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [assets]);

  return { isLoading, isError };
};
