import {
  AllSubjectStyle,
  SubjectStyle,
} from "features/dojo/models/subjectStyle";
import React, { FC } from "react";
import SubjectButton from "../subjectButton";
import styles from "./_index.module.scss";

export interface SubjectButtonsProps {
  handleSubjectClicked: (subjectStyle: SubjectStyle) => void;
}

export const SubjectButtons: FC<SubjectButtonsProps> = ({
  handleSubjectClicked,
}) => (
  <div className={styles.SubjectButtons}>
    {AllSubjectStyle.map((subjectStyle) => (
      <SubjectButton
        key={subjectStyle.subject.name}
        handleSubjectClicked={handleSubjectClicked}
        subjectStyle={subjectStyle}
      />
    ))}
  </div>
);
