import { CorrectAnswerRate } from "models/correctAnswerRate";
import { useQuery } from "react-query";
import apiClient from "services/apiClient";
import { getCorrectAnswerRateQueryKey } from "./queryKeys";

type Params = {
  year: number;
  month: number;
};

const fetchCorrectAnswerRate = async (
  parameters: Params
): Promise<CorrectAnswerRate> => {
  const res = await apiClient.get<CorrectAnswerRate>(
    "/v1/calendar/correct-answer-rate",
    {
      params: parameters,
    }
  );
  return res.data;
};

const useCorrectAnswerRate = (
  params: Params,
  options?: { enabled?: boolean }
) =>
  useQuery<number>(
    getCorrectAnswerRateQueryKey(params.year, params.month),
    async () => (await fetchCorrectAnswerRate(params)).correctAnswerRate,
    options
  );

export default useCorrectAnswerRate;
