import React, { FC, ReactNode } from "react";
import classNames from "classnames";
import styles from "./_index.module.scss";

export interface LabeledIconButtonProps {
  icon: ReactNode;
  label: string;
  labelColor?: string;
  invisible?: boolean;
  onClick: () => void;
}

const LabeledIconButton: FC<LabeledIconButtonProps> = ({
  icon,
  label,
  labelColor,
  invisible = false,
  onClick,
}) => {
  const className = classNames(styles.LabeledIconButton, {
    [styles.invisible]: invisible,
  });

  return (
    <div className={className}>
      <button className={styles.button} type="button" onClick={onClick}>
        {icon}
      </button>

      <span className={styles.label} style={{ color: labelColor }}>
        {label}
      </span>
    </div>
  );
};

export default LabeledIconButton;
