import React, { FC } from "react";
import AbsolutelyPositionedBox from "components/absolutelyPositionedBox";
import rocket from "../../assets/rocketBox.svg";
import completedRocket from "../../assets/masteredRocketBox.svg";
import lock from "../../assets/lock.svg";
import styles from "./_index.module.scss";
import CategoryBox from "../categoryBox";
import Ruby from "../../../../components/ruby";

export interface QuizCategoryBoxProps {
  name: string;
  imageUrl: string;
  count: number;
  isUnlocked: boolean;
  numberOfQuizzes: number;
  numberOfMasteredQuizzes: number;
  isMastered: boolean;
  onCategoryClick: () => void;
}

const QuizCategoryBox: FC<QuizCategoryBoxProps> = ({
  name,
  imageUrl,
  count,
  isUnlocked,
  numberOfQuizzes,
  numberOfMasteredQuizzes,
  isMastered,
  onCategoryClick,
}) => {
  const rocketBox = isMastered ? completedRocket : rocket;

  return (
    <button
      className={styles.QuizCategoryBox}
      type="button"
      onClick={onCategoryClick}
    >
      <AbsolutelyPositionedBox top="32px" right="-20px">
        <div className={styles.successRateBox}>
          <Ruby
            className={styles.numberOfMasteredQuizzesText}
            text="習得問題数"
            ruby="しゅうとくもんだいすう"
          />
          <div>
            <span className={styles.numberOfMasteredQuizzes}>
              {numberOfMasteredQuizzes}/
            </span>
            <span className={styles.numberOfQuizzes}>{numberOfQuizzes}</span>
          </div>
        </div>
      </AbsolutelyPositionedBox>
      <CategoryBox
        name={name}
        imageUrl={imageUrl}
        count={count}
        rocketBox={rocketBox}
      />
      {!isUnlocked && (
        <div className={styles.lockedItem}>
          <div className={styles.backToTraining}>まなぶ</div>
          <div className={styles.lock}>
            <img src={lock} alt="locked" width="82px" height="110px" />
          </div>
        </div>
      )}
    </button>
  );
};
export default QuizCategoryBox;
