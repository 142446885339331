import React, { FC } from "react";
import home from "assets/home.svg";
import previous from "assets/previous.svg";
import rocket from "assets/rocket.svg";
import setting from "../../assets/setting.svg";
import styles from "./_index.module.scss";
import IconButton from "../../../loginCalendar/components/iconButton";

export interface TopAppBarProps {
  onHomeIconClick: () => void;
  onPreviousClick: () => void;
  onRocketClick: () => void;
  onSettingClick: () => void;
}

const TopAppBar: FC<TopAppBarProps> = ({
  onHomeIconClick,
  onPreviousClick,
  onRocketClick,
  onSettingClick,
}) => (
  <div className={styles.TopAppBar}>
    <div className={styles["navigation-button"]}>
      <IconButton onClick={onPreviousClick}>
        <img src={previous} alt="戻る" />
      </IconButton>
    </div>

    <div className={styles.title}>
      <span className={styles.title}>月例テスト対策</span>
    </div>

    <div className={styles.actions}>
      <div className={styles["action-buttons"]}>
        <IconButton onClick={onSettingClick}>
          <img src={setting} alt="設定" />
        </IconButton>

        <IconButton onClick={onRocketClick}>
          <img src={rocket} alt="ロケット" />
        </IconButton>

        <IconButton onClick={onHomeIconClick}>
          <img src={home} alt="ホーム" />
        </IconButton>
      </div>
    </div>
  </div>
);
export default TopAppBar;
