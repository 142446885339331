import React from "react";
import styles from "./_index.module.scss";

const Spinner: React.FC = () => (
  <div className={styles.viewLoading}>
    <div className={styles.spinner} />
  </div>
);

export default Spinner;
