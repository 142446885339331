import { useMemo } from "react";
import sanitizeHtml from "sanitize-html";

const useCountRubiedText = (text: string): number =>
  // text からルビを除いた文字列を作って文字数を数える
  useMemo(
    () =>
      sanitizeHtml(text, {
        // exclusiveFilter に通すために rp タグと rt タグだけ許す
        // ※ ruby タグはこの時点で取り除かれる (タグの中の文字列はそのまま残る)
        allowedTags: ["rp", "rt"],
        allowedAttributes: false,
        selfClosing: [],
        allowedSchemes: false,
        allowedSchemesAppliedToAttributes: [],
        enforceHtmlBoundary: true,
        // rp タグと rt タグだけが残った文字列がここに来る
        // -> 文字数を数えるために rp タグと rt タグをタグの中の文字列も含めて全て取り除く
        exclusiveFilter: () => true,
      }).length,
    [text]
  );

export default useCountRubiedText;
