// eslint-disable-next-line import/prefer-default-export
export const shuffle = <T>(array: T[]): T[] => {
  const clone = [...array];

  for (let i = clone.length - 1; i >= 0; i -= 1) {
    const randomIndex = Math.floor(Math.random() * i);

    [clone[i], clone[randomIndex]] = [clone[randomIndex], clone[i]];
  }

  return clone;
};

export const chunked = <T>(array: T[], size: number): T[][] => {
  if (array.length === 0) {
    return [[]];
  }

  return Array.from({ length: Math.ceil(array.length / size) }, (_, i) =>
    array.slice(i * size, i * size + size)
  );
};
