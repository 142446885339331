import useStudent from "hooks/queries/useStudent";
import AuthState from "models/authState";
import React, { ReactNode, useMemo } from "react";
import { UNAUTHORIZED_STATUS_CODE } from "services/config";
import AuthContext from "./authContext";
import { AuthContextProps } from "./authContextProps";

type Props = {
  children?: ReactNode;
};

const AuthProvider: React.FC<Props> = ({ children }) => {
  const { isLoading, isError, error, data } = useStudent();

  const state = useMemo((): AuthContextProps => {
    if (isLoading) return { state: AuthState.IsLoading };
    if (isError && error.response?.status === UNAUTHORIZED_STATUS_CODE)
      return { state: AuthState.UnAuthorized };
    if (data !== undefined) return { state: AuthState.Authorized };
    return { state: AuthState.UnExpected };
  }, [isError, data, isLoading]);

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
