import classNames from "classnames";
import RubiedText from "components/rubiedText";
import home from "features/quiz/assets/home.svg";
import penguinCorrectAnswer from "features/quiz/assets/penguin_correct_answer.svg";
import penguinWrongAnswer from "features/quiz/assets/penguin_wrong_answer.svg";
import LabeledIconButton from "features/quiz/components/labeledIconButton";
import { TopAppBar } from "features/quiz/components/layouts/topAppBar";
import useCountRubiedText from "features/quiz/hooks/useCountRubiedText";
import React, { FC, useMemo } from "react";
import styles from "./_index.module.scss";

export interface AnswerExplanationAppBarProps {
  title: string;
  isCorrect: boolean;
  onNavigationIconClick: () => void;
}

const AnswerExplanationAppBar: FC<AnswerExplanationAppBarProps> = ({
  title,
  isCorrect,
  onNavigationIconClick,
}) => {
  const penguinSrc = useMemo(
    () => (isCorrect ? penguinCorrectAnswer : penguinWrongAnswer),
    [isCorrect]
  );
  const textLength = useCountRubiedText(title);
  const fontStyles = useMemo(() => {
    const isLongText = textLength > 50;

    return classNames(
      styles.title,
      isLongText ? styles["small-font"] : styles["large-font"]
    );
  }, [title]);

  return (
    <TopAppBar>
      <LabeledIconButton
        icon={<img src={home} alt={isCorrect.toString()} />}
        label="ホーム"
        onClick={onNavigationIconClick}
      />
      <img className={styles["penguin-image"]} src={penguinSrc} alt="" />
      <RubiedText className={fontStyles} rubiedTextHtml={title} />
    </TopAppBar>
  );
};

export default AnswerExplanationAppBar;
