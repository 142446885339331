import StudentAnswer from "models/studentAnswer";
import RearrangementQuiz from "models/rearrangementQuiz/rearrangementQuiz";
import RearrangementQuizAnswer from "models/rearrangementQuiz/rearrangementQuizAnswer";
import { CardPositionType } from "features/quiz/models/rearrangementQuiz/cardPositionType";

export default class RearrangementQuizStudentAnswer
  implements StudentAnswer<RearrangementQuiz>
{
  constructor(
    public readonly quiz: RearrangementQuiz,
    public readonly answer?: RearrangementQuizAnswer,
    public readonly elapsedTimeMillis?: number
  ) {}

  get isAnswered(): boolean {
    if (this.answer === undefined) {
      return false;
    }

    const answerCardCount = this.answer.positions.filter(
      (position) => position.type === CardPositionType.Answer
    ).length;

    return answerCardCount === this.quiz.cards.length;
  }

  get isCorrect(): boolean {
    if (this.answer === undefined || !this.isAnswered) {
      return false;
    }

    const answerCards = this.answer.positions.filter(
      (position) => position.type === CardPositionType.Answer
    );

    return answerCards.reduce((previous, current) => {
      const dropZone = this.quiz.cards[current.index];
      const isCorrect = current.card.choiceId === dropZone.id;

      return isCorrect && previous;
    }, true);
  }

  // eslint-disable-next-line class-methods-use-this
  get answerText(): string | undefined {
    return undefined;
  }
}
