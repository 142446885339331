import React, { FC } from "react";
import styles from "./_index.module.scss";
import QuizResultButton from "../quizResultButton";
import Ruby from "../../../../components/ruby";

export interface ClearedNavigationActionsProps {
  onHomeClick: () => void;
  onBackClick?: () => void;
}

const ClearedNavigationActions: FC<ClearedNavigationActionsProps> = ({
  onHomeClick,
  onBackClick,
}) => (
  <div className={styles.ClearedNavigationActions}>
    <QuizResultButton
      onClick={onHomeClick}
      text={
        <span>
          <Ruby text="終了" ruby="しゅうりょう" />
          してホームに
          <Ruby text="戻" ruby="もど" />る
        </span>
      }
    />

    {onBackClick && (
      <QuizResultButton
        onClick={onBackClick}
        text={
          <span>
            ほかの
            <Ruby text="単元" ruby="たんげん" />
            もやる
          </span>
        }
      />
    )}
  </div>
);

export default ClearedNavigationActions;
