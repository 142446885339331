import { NavSelectionQuizA } from "./navSelectionQuizA";
import { NavSelectionQuizB } from "./navSelectionQuizB";
import { NavSelectionQuizC } from "./navSelectionQuizC";
import { NavTextInputQuiz } from "./navTextInputQuiz";
import { NavRearrangementQuiz } from "./navRearrangementQuiz";
import { QuizType } from "../../models/quizType";
import SelectionQuizA from "../../models/selectionQuizA/selectionQuizA";
import SelectionQuizB from "../../models/selectionQuizB/selectionQuizB";
import SelectionQuizC from "../../models/selectionQuizC/selectionQuizC";
import RearrangementQuiz from "../../models/rearrangementQuiz/rearrangementQuiz";
import TextInputQuiz from "../../models/textInputQuiz/textInputQuiz";

export type NavQuizType =
  | NavSelectionQuizA
  | NavSelectionQuizB
  | NavSelectionQuizC
  | NavTextInputQuiz
  | NavRearrangementQuiz;

export function mapQuizTypeToNavModel(quiz: QuizType): NavQuizType {
  if (quiz instanceof SelectionQuizA) {
    return { ...quiz, type: "selection_quiz_a" };
  }
  if (quiz instanceof SelectionQuizB) {
    return { ...quiz, type: "selection_quiz_b" };
  }
  if (quiz instanceof SelectionQuizC) {
    return { ...quiz, type: "selection_quiz_c" };
  }
  if (quiz instanceof RearrangementQuiz) {
    return { ...quiz, type: "rearrangement_quiz" };
  }
  return { ...quiz, type: "text_input_quiz" };
}

export function mapNavQuizTypeToModel(quiz: NavQuizType): QuizType {
  switch (quiz.type) {
    case "selection_quiz_a":
      return new SelectionQuizA(
        quiz.id,
        quiz.sentence,
        quiz.correctAnswerImageUrl,
        quiz.wrongAnswerImageUrl,
        quiz.timeLimitSeconds,
        quiz.consecutiveCorrectAnswerCount,
        quiz.imageUrl,
        quiz.choices,
        quiz.correctChoiceId
      );
    case "selection_quiz_b":
      return new SelectionQuizB(
        quiz.id,
        quiz.sentence,
        quiz.correctAnswerImageUrl,
        quiz.wrongAnswerImageUrl,
        quiz.timeLimitSeconds,
        quiz.consecutiveCorrectAnswerCount,
        quiz.imageUrl,
        quiz.choices,
        quiz.correctChoiceId
      );
    case "selection_quiz_c":
      return new SelectionQuizC(
        quiz.id,
        quiz.sentence,
        quiz.correctAnswerImageUrl,
        quiz.wrongAnswerImageUrl,
        quiz.timeLimitSeconds,
        quiz.consecutiveCorrectAnswerCount,
        quiz.imageUrl,
        quiz.choices,
        quiz.correctChoiceId
      );
    case "rearrangement_quiz":
      return new RearrangementQuiz(
        quiz.id,
        quiz.sentence,
        quiz.correctAnswerImageUrl,
        quiz.wrongAnswerImageUrl,
        quiz.timeLimitSeconds,
        quiz.consecutiveCorrectAnswerCount,
        quiz.cards
      );
    case "text_input_quiz":
      return new TextInputQuiz(
        quiz.id,
        quiz.sentence,
        quiz.correctAnswerImageUrl,
        quiz.wrongAnswerImageUrl,
        quiz.timeLimitSeconds,
        quiz.consecutiveCorrectAnswerCount,
        quiz.imageUrl,
        quiz.characters,
        quiz.correctAnswer
      );
    default:
      throw new Error();
  }
}
