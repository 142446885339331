import React, { FC, useMemo } from "react";
import { UseQuizState } from "../../hooks/useQuizUiState";
import LoadingQuiz from "../../models/uiState/loadingQuiz";
import Spinner from "../../../../components/spinner";
import QuizLoadError from "../../models/uiState/quizLoadError";
import AnsweringQuiz from "../../models/uiState/answeringQuiz";
import QuizContent from "../quizContent";
import AnswerExplanation from "../answerExplanation";
import styles from "./_index.module.scss";
import MenuDialog from "../menuDialog";

export interface QuizRootProps {
  useQuizState: UseQuizState;
  onHomeClick: () => void;
}

const QuizRoot: FC<QuizRootProps> = ({ useQuizState, onHomeClick }) => {
  const {
    uiState,
    isPausing,
    onAnswerQuiz,
    onConfirmAnswer,
    onPauseTimer,
    onConfirmAnswerExplanation,
    onPauseAnswerExplanation,
    onResume,
    onReset,
  } = useQuizState;

  const child = useMemo(() => {
    if (uiState instanceof LoadingQuiz) {
      return <Spinner />;
    }

    if (uiState instanceof QuizLoadError) {
      return <span />;
    }

    if (uiState instanceof AnsweringQuiz) {
      return (
        <QuizContent
          currentQuizNumber={uiState.currentQuizNumber}
          totalQuizCount={uiState.totalQuizCount}
          studentAnswer={uiState.studentAnswer}
          isPausing={uiState.isPausing}
          onNavigationIconClick={onHomeClick}
          onAnswerChanged={onAnswerQuiz}
          onConfirmAnswer={onConfirmAnswer}
          onPause={onPauseTimer}
        />
      );
    }

    return (
      <AnswerExplanation
        currentQuizNumber={uiState.currentQuizNumber}
        totalQuizCount={uiState.totalQuizCount}
        studentAnswer={uiState.studentAnswer}
        nextQuiz={uiState.nextQuiz}
        onNavigationIconClick={onHomeClick}
        onPauseClick={onPauseAnswerExplanation}
        onNextClick={onConfirmAnswerExplanation}
      />
    );
  }, [uiState]);

  return (
    <div className={styles.QuizRoot}>
      {child}

      {isPausing && (
        <MenuDialog
          onResume={onResume}
          onNavigateToHome={onHomeClick}
          onReset={onReset}
        />
      )}
    </div>
  );
};

export default QuizRoot;
