import { useMutation } from "react-query";
import apiClient from "services/apiClient";

type Data = {
  categoryId: string;
};

const postTrainingResult = async (requestData: Data): Promise<void> => {
  const res = await apiClient.post<void>("/v1/trainings/result", requestData);
  return res.data;
};

const useSaveTrainingResult = () =>
  useMutation({
    mutationFn: async (data: Data) => postTrainingResult(data),
  });

export default useSaveTrainingResult;
