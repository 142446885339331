import React, { FC, useRef, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useTrainingPages from "hooks/queries/useTrainingPages";
import useSaveTrainingResult from "hooks/queries/useSaveTrainingResult";
import Spinner from "components/spinner";
import LoadingError from "components/loadingError";
import AlertDialogWithRetry from "components/alertDialogWithRetry";
import { TopAppBar } from "./components/topAppBar";
import BottomAppBar from "./components/bottomAppBar";
import MenuDialog from "./components/menuDialog";
import styles from "./_index.module.scss";

export type NavArgs = {
  name: string;
  count: number;
};

const Training: FC = () => {
  const location = useLocation();
  const { categoryId } = useParams();
  const navArgs = location.state as NavArgs;
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [isPausing, setIsPausing] = useState(false);
  const saveTrainingResult = useSaveTrainingResult();
  const saving = useRef(false);

  if (navArgs.name === undefined || categoryId === undefined) {
    return <Navigate to="/" replace />;
  }

  const { isLoading, isError, data, refetch } = useTrainingPages({
    categoryId,
  });

  const finishTraining = () => {
    if (saving.current) {
      return;
    }

    saving.current = true;

    saveTrainingResult.mutate(
      { categoryId },
      {
        onSuccess: () => {
          navigate(`finished`, {
            state: navArgs,
            replace: true,
          });
        },
        onSettled: () => {
          saving.current = false;
        },
      }
    );
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (isError || data === undefined || data.length === 0) {
    return <LoadingError onRetry={refetch} />;
  }

  return (
    <div className={styles.Training}>
      <TopAppBar
        title={data[index].title}
        onNavigationIconClick={() => {
          navigate("/", { replace: true });
        }}
      />
      <div className={styles["image-container"]}>
        <img src={data[index].imageUrl} alt={data[index].title} />
      </div>
      <BottomAppBar
        onPreviousClicked={() => {
          setIndex((prev) => (prev > 0 ? prev - 1 : prev));
        }}
        onPauseClicked={() => {
          setIsPausing(true);
        }}
        onNextClicked={
          index === data.length - 1
            ? finishTraining
            : () =>
                setIndex((prev) => (prev < data.length - 1 ? prev + 1 : prev))
        }
        pageNum={index + 1}
        maxPageNum={data.length}
        isLastPage={index === data.length - 1}
      />

      {isPausing && (
        <MenuDialog
          onResume={() => {
            setIsPausing(false);
          }}
          onNavigateToHome={() => {
            navigate("/", { replace: true });
          }}
          onReset={() => {
            setIsPausing(false);
            setIndex(0);
          }}
        />
      )}

      {saveTrainingResult.isLoading && (
        <div className={styles.Spinner}>
          <Spinner />
        </div>
      )}

      {saveTrainingResult.isError && (
        <AlertDialogWithRetry onRetry={finishTraining} />
      )}
    </div>
  );
};

export default Training;
