import React, { FC } from "react";
import styles from "./_index.module.scss";

const SessionExpirationError: FC = () => (
  <div className={styles.SessionExpirationError}>
    <div className={styles.content}>
      <p className={styles.title}>セッションが無効になりました</p>

      <p className={styles.description}>もういちどログインしてください</p>
    </div>
  </div>
);

export default SessionExpirationError;
