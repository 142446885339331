import React, { CSSProperties, FC, ReactNode } from "react";
import CSS from "csstype";
import styles from "./_index.module.css";

export interface FilledButtonProps {
  children: ReactNode;
  containerColor: CSS.Property.Color;
  contentColor: CSS.Property.Color;
  onClick: () => void;
}

const FilledButton: FC<FilledButtonProps> = ({
  children,
  containerColor,
  contentColor,
  onClick,
}) => {
  const buttonStyles: CSSProperties = {
    background: containerColor,
    color: contentColor,
  };

  return (
    <button
      className={styles.FilledButton}
      style={buttonStyles}
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default FilledButton;
