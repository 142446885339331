import React, { FC, useMemo } from "react";
import { StudentAnswerType } from "../../../../models/studentAnswerType";
import useImages from "../../../../hooks/useImages";
import styles from "../../../quiz/components/answerExplanation/_index.module.scss";
import AnswerExplanationTopAppBar from "../../../quiz/components/answerExplanationTopAppBar";
import Spinner from "../../../../components/spinner";
import AnswerExplanationBottomAppBar from "../answerExplanationBottomAppBar";

export interface AnswerExplanationProps {
  currentQuizNumber: number;
  totalQuizCount: number;
  studentAnswer: StudentAnswerType;
  onNavigationIconClick: () => void;
  onPauseClick: () => void;
  onBackClick: () => void;
}

const AnswerExplanation: FC<AnswerExplanationProps> = ({
  currentQuizNumber,
  totalQuizCount,
  studentAnswer,
  onNavigationIconClick,
  onPauseClick,
  onBackClick,
}) => {
  const isCorrect = useMemo(() => studentAnswer.isCorrect, [studentAnswer]);

  const appBarTitle = useMemo(() => {
    const { answerText } = studentAnswer;

    return answerText === undefined
      ? "答えは下の解説を見てね！"
      : `答え : ${answerText}`;
  }, [studentAnswer]);

  const contentImageUrl = useMemo(
    () =>
      isCorrect
        ? studentAnswer.quiz.correctAnswerImageUrl
        : studentAnswer.quiz.wrongAnswerImageUrl,
    [isCorrect]
  );
  const { isLoading: isImageLoading } = useImages([contentImageUrl]);

  return (
    <div className={styles.AnswerExplanation}>
      <AnswerExplanationTopAppBar
        title={appBarTitle}
        isCorrect={isCorrect}
        onNavigationIconClick={onNavigationIconClick}
      />

      {isImageLoading ? (
        <div className={styles["spinner-frame"]}>
          <Spinner />
        </div>
      ) : (
        <div className={styles.content}>
          <img src={contentImageUrl} alt="" />
        </div>
      )}

      <AnswerExplanationBottomAppBar
        currentQuizNumber={currentQuizNumber}
        totalQuizCount={totalQuizCount}
        onPauseClick={onPauseClick}
        onBackClick={onBackClick}
      />
    </div>
  );
};

export default AnswerExplanation;
