import alien_pink from "../assets/alien_pink.svg";
import alien_pink_active from "../assets/alien_pink_active.svg";
import alien_blue from "../assets/alien_blue.svg";
import alien_blue_active from "../assets/alien_blue_active.svg";

export const AlienAsset = {
  Pink: {
    normal: alien_pink,
    active: alien_pink_active,
  },
  Blue: {
    normal: alien_blue,
    active: alien_blue_active,
  },
} as const;

// eslint-disable-next-line
export type AlienAsset = typeof AlienAsset[keyof typeof AlienAsset];
