import { useMutation } from "react-query";
import { AxiosResponse } from "axios";
import apiClient from "../../services/apiClient";
import { StudentAnswerType } from "../../models/studentAnswerType";
import QuizAnswerRequest from "../../services/models/quizAnswerRequest";
import { QuizResultSaveResponse } from "../../services/models/quizResultSaveResponse";
import QuizResultSaveResult from "../../models/quizResultSaveResult";
import { MonthlyExamPreparationSubject } from "../../models/monthlyExamPreparationSubject";
import queryClient from "../../services/queryClient";
import { getMonthlyExamPreparationsKey } from "./queryKeys";
import MonthlyExamQuizResultRequest from "../../services/models/monthlyExamQuizResultRequest";
import { mapToQuizAnswerRequest } from "./useSaveQuizResult";

const convertSubjectParam = (subject: MonthlyExamPreparationSubject) => {
  switch (subject) {
    case MonthlyExamPreparationSubject.SocialStudies:
      return "SOCIOLOGY";
    case MonthlyExamPreparationSubject.Science:
      return "SCIENCE";
    default:
      return "";
  }
};

type Data = {
  preparationNumber: number;
  subject: MonthlyExamPreparationSubject;
  answers: StudentAnswerType[];
};

const saveMonthlyExamQuizResult = async ({
  subject,
  preparationNumber,
  answers,
}: Data): Promise<QuizResultSaveResponse> => {
  const apiAnswers = answers
    .map((studentAnswer) => mapToQuizAnswerRequest(studentAnswer))
    .filter((request): request is QuizAnswerRequest => request !== undefined);

  return (
    await apiClient.post<
      QuizResultSaveResponse,
      AxiosResponse<QuizResultSaveResponse>,
      MonthlyExamQuizResultRequest
    >(`v1/monthly_exams/${preparationNumber}/quizzes/result`, {
      monthlyExamNumber: Number(preparationNumber),
      subject: convertSubjectParam(subject),
      answers: apiAnswers,
    })
  ).data;
};

const useSaveMonthlyExamQuizResult = () =>
  useMutation<QuizResultSaveResult, unknown, Data, unknown>(
    async (data) => {
      const { completed } = await saveMonthlyExamQuizResult(data);

      return {
        categoryCompleted: completed,
      };
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getMonthlyExamPreparationsKey());
      },
    }
  );

export default useSaveMonthlyExamQuizResult;
