import React, { FC, useMemo } from "react";
import nextMonth from "features/loginCalendar/assets/nextMonth.svg";
import previousMonth from "features/loginCalendar/assets/previousMonth.svg";
import dayjs from "dayjs";
import styles from "./_index.module.scss";
import MonthText from "../monthText";
import useCalendarDate from "../../hooks/useCalendarDate";
import useMonthlyImage from "../../hooks/useMonthlyImage";
import AbsolutelyPositionedBox from "../../../../components/absolutelyPositionedBox";

export interface CalendarHeaderProps {
  date: Date;
  today: Date;
  onPreviousMonthClick: () => void;
  onNextMonthClick: () => void;
}

const CalendarHeader: FC<CalendarHeaderProps> = ({
  date,
  today,
  onPreviousMonthClick,
  onNextMonthClick,
}) => {
  const calendarDate = useCalendarDate(date);
  const calendarImages = useMonthlyImage(date);
  const canDisplayPreviousMonth = useMemo(() => {
    const previousMonthDate = dayjs(date).subtract(1, "month");
    const dateOneYearAgo = dayjs(today).subtract(1, "year").endOf("month");
    return previousMonthDate.isAfter(dateOneYearAgo);
  }, [date, today]);
  const canDisplayNextMonth = useMemo(
    () => date.getMonth() !== today.getMonth(),
    [date, today]
  );
  return (
    <div className={styles.CalendarHeader}>
      {canDisplayPreviousMonth && (
        <AbsolutelyPositionedBox left="303px">
          <button
            className={styles.button}
            type="button"
            onClick={onPreviousMonthClick}
          >
            <img src={previousMonth} alt="previousMonth" />
            まえの月
          </button>
        </AbsolutelyPositionedBox>
      )}
      <img src={calendarImages.left} alt="" />
      <MonthText month={calendarDate.currentMonth} />
      <img src={calendarImages.right} alt="" />
      {canDisplayNextMonth && (
        <AbsolutelyPositionedBox right="303px">
          <button
            className={styles.button}
            type="button"
            onClick={onNextMonthClick}
          >
            <img src={nextMonth} alt="nextMonth" />
            つぎの月
          </button>
        </AbsolutelyPositionedBox>
      )}
    </div>
  );
};
export default CalendarHeader;
