import RearrangementQuizCard from "models/rearrangementQuiz/rearrangementQuizCard";
import Quiz from "models/quiz";
import { shuffle } from "../../collection";

type CardPositionMap = { [choiceId: string]: number };

export default class RearrangementQuiz implements Quiz {
  public readonly initialCardPositionMap: CardPositionMap;

  constructor(
    public readonly id: string,
    public readonly sentence: string,
    public readonly correctAnswerImageUrl: string,
    public readonly wrongAnswerImageUrl: string,
    public readonly timeLimitSeconds: number,
    public readonly consecutiveCorrectAnswerCount: number,
    public readonly cards: RearrangementQuizCard[]
  ) {
    this.initialCardPositionMap = shuffle(cards).reduce<CardPositionMap>(
      (previous, current, currentIndex) => ({
        ...previous,
        [current.id]: currentIndex,
      }),
      {}
    );
  }

  get quizAssetUrls(): string[] {
    return this.cards.map((card) => card.imageUrl);
  }

  copy(args: { consecutiveCorrectAnswerCount: number }): RearrangementQuiz {
    return new RearrangementQuiz(
      this.id,
      this.sentence,
      this.correctAnswerImageUrl,
      this.wrongAnswerImageUrl,
      this.timeLimitSeconds,
      args.consecutiveCorrectAnswerCount,
      this.cards
    );
  }
}
