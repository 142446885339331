import React, { FC } from "react";
import rocket from "../../assets/rocketBox.svg";
import rocketRed from "../../assets/rocketRedBox.svg";
import styles from "./_index.module.scss";
import CategoryBox from "../categoryBox";

export interface TrainingCategoryBoxProps {
  name: string;
  imageUrl: string;
  count: number;
  onCategoryClick: () => void;
}

const TrainingCategoryBox: FC<TrainingCategoryBoxProps> = ({
  name,
  imageUrl,
  count,
  onCategoryClick,
}) => {
  const rocketBox = count === 0 ? rocketRed : rocket;

  return (
    <button
      className={styles.TrainingCategoryBox}
      type="button"
      onClick={onCategoryClick}
    >
      <CategoryBox
        name={name}
        imageUrl={imageUrl}
        count={count}
        rocketBox={rocketBox}
      />
    </button>
  );
};
export default TrainingCategoryBox;
