import { SubjectStyle } from "features/dojo/models/subjectStyle";
import React, { FC } from "react";
import styles from "./_index.module.scss";
import Ruby from "../../../../components/ruby";

export interface SubjectButtonProps {
  handleSubjectClicked: (subject: SubjectStyle) => void;
  subjectStyle: SubjectStyle;
}

const SubjectButton: FC<SubjectButtonProps> = ({
  handleSubjectClicked,
  subjectStyle,
}) => (
  <button
    type="button"
    className={styles.SubjectButton}
    onClick={() => handleSubjectClicked(subjectStyle)}
    style={{ background: subjectStyle.background }}
  >
    <img className={styles.icon} src={subjectStyle.icon} alt="" />

    <Ruby
      text={subjectStyle.subject.label}
      ruby={subjectStyle.subject.labelRubi}
    />
  </button>
);

export default SubjectButton;
