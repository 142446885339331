import React, { FC } from "react";
import preparation_start_button from "../../assets/preparation_start_button.svg";
import styles from "./_index.module.scss";
import ImageButton from "../../../../components/imageButton";
import AbsolutelyPositionedBox from "../../../../components/absolutelyPositionedBox";
import { SelectedPreparationBalloon as BalloonModel } from "../../models/alienBalloon";

export interface SelectedPreparationBalloonProps {
  balloon: BalloonModel;
  isVisible: boolean;
  onStartClick: (preparationNumber: number) => void;
}

const SelectedPreparationBalloon: FC<SelectedPreparationBalloonProps> = ({
  balloon,
  isVisible,
  onStartClick,
}) => (
  <div
    className={styles.SelectedPreparationBalloon}
    style={{
      opacity: isVisible ? 1 : 0,
      visibility: isVisible ? "visible" : "hidden",
      left: `${balloon.offset.x}px`,
      top: `${balloon.offset.y}px`,
    }}
  >
    <div className={styles.frame}>
      <AbsolutelyPositionedBox top="75px" left="61px">
        <span className={styles.label}>第{balloon.preparationNumber}回</span>
      </AbsolutelyPositionedBox>

      <AbsolutelyPositionedBox top="50px" left="304px">
        <ImageButton onClick={() => onStartClick(balloon.preparationNumber)}>
          <img src={preparation_start_button} alt="演習を開始" />
        </ImageButton>
      </AbsolutelyPositionedBox>
    </div>
  </div>
);

export default SelectedPreparationBalloon;
