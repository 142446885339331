import StudentAnswer from "models/studentAnswer";
import SelectionQuizB from "models/selectionQuizB/selectionQuizB";
import SelectionQuizBAnswer from "models/selectionQuizB/selectionQuizBAnswer";

export default class SelectionQuizBStudentAnswer
  implements StudentAnswer<SelectionQuizB>
{
  constructor(
    public readonly quiz: SelectionQuizB,
    public readonly answer?: SelectionQuizBAnswer,
    public readonly elapsedTimeMillis?: number
  ) {}

  get isAnswered(): boolean {
    return this.answer !== undefined;
  }

  get isCorrect(): boolean {
    return this.quiz.correctChoiceId === this.answer?.choiceId;
  }

  get answerText(): string | undefined {
    return this.quiz.choices.find(
      (choice) => choice.id === this.quiz.correctChoiceId
    )?.name;
  }
}
