import AbsolutelyPositionedBox from "components/absolutelyPositionedBox";
import next from "features/training/assets/next.svg";
import previous from "features/training/assets/previous.svg";
import pause from "features/training/assets/pause.svg";
import React, { FC, useMemo } from "react";
import LabeledIconButton from "../labeledIconButton";
import styles from "./_index.module.scss";

export interface BottomAppBarProps {
  onPreviousClicked: () => void;
  onPauseClicked: () => void;
  onNextClicked: () => void;
  pageNum: number;
  maxPageNum: number;
  isLastPage: boolean;
}

const BottomAppBar: FC<BottomAppBarProps> = ({
  onPreviousClicked,
  onPauseClicked,
  onNextClicked,
  pageNum,
  maxPageNum,
  isLastPage,
}) => {
  const isFirstPage = useMemo(() => pageNum === 1, [pageNum]);

  return (
    <div className={styles.BottomAppBar}>
      <LabeledIconButton
        icon={<img src={previous} alt="" />}
        label="もどる"
        invisible={isFirstPage}
        onClick={onPreviousClicked}
      />

      <LabeledIconButton
        icon={<img src={pause} alt="" />}
        label="ひとやすみ"
        onClick={onPauseClicked}
      />

      <LabeledIconButton
        icon={<img src={next} alt="" />}
        label={isLastPage ? "おわり" : "つぎへ"}
        labelColor={isLastPage ? "#FF999B" : "var(--color-text-primary)"}
        onClick={onNextClicked}
      />
      <AbsolutelyPositionedBox right="10px" bottom="10px">
        <span className={styles.pageCount}>
          {pageNum}ページ目/{maxPageNum}ページ
        </span>
      </AbsolutelyPositionedBox>
    </div>
  );
};

export default BottomAppBar;
