import React, { FC } from "react";
import styles from "features/loginCalendar/components/calendarDate/_index.module.scss";
import useCalendarDate from "features/loginCalendar/hooks/useCalendarDate";
import loggedLogo from "features/loginCalendar/assets/logged.svg";
import notLoggedLogo from "features/loginCalendar/assets/notLogged.svg";
import classNames from "classnames";
import useNumberOfRowsInCalendar from "../../hooks/useNumberOfRowsInCalendar";

export interface CalendarDateProps {
  date: Date;
  today: Date;
  loggedInDates: Date[];
}

const CalendarDate: FC<CalendarDateProps> = ({
  date,
  today,
  loggedInDates,
}) => {
  const calendarDate = useCalendarDate(date);
  const numberOfRowsInCalendar = useNumberOfRowsInCalendar(
    calendarDate.startDayOfWeek,
    calendarDate.endDay,
    calendarDate.endDayOfWeek
  );
  const wrapperClassName =
    numberOfRowsInCalendar === 5
      ? styles.dateBoxWrapper
      : styles.dateBoxWrapper6rows;
  const dateBoxClassName =
    numberOfRowsInCalendar === 5 ? styles.dateBox : styles.dateBox6rows;
  /** 空白の日を作成する */
  const createBlankBox = (num: number, key: string) =>
    [...Array(num).keys()].map((i) => (
      <div
        className={wrapperClassName}
        key={`${date.getFullYear()}-${date.getMonth()}-${i}-${key}`}
      >
        <div className={dateBoxClassName} />
      </div>
    ));
  /** 未来の日付を作成する */
  const createFutureDateBox = (displayDate: number, key: string) => (
    <div className={wrapperClassName} key={key}>
      <div className={dateBoxClassName}>
        {displayDate}
        <span className={styles.futureDate}>?</span>
      </div>
    </div>
  );
  /** 過去の日付を作成する */
  const createPastDateBox = (displayDate: number, key: string) => {
    const hasLoggedIn = loggedInDates.some((d) => {
      const loggedInDate = new Date(d);
      return (
        loggedInDate.getFullYear() === date.getFullYear() &&
        loggedInDate.getMonth() === date.getMonth() &&
        loggedInDate.getDate() === displayDate
      );
    });
    const classes = classNames(dateBoxClassName, {
      [styles.loggedDate]: hasLoggedIn,
    });
    return (
      <div className={wrapperClassName} key={key}>
        <div className={classes}>
          {displayDate}
          <img
            src={hasLoggedIn ? loggedLogo : notLoggedLogo}
            alt="icon"
            className={styles.icon}
          />
        </div>
      </div>
    );
  };
  /** 表示する月の日付を作成する */
  const currentMonthDays = [...Array(calendarDate.endDay)].map((_, i) => {
    const key = `${date.getFullYear()}-${date.getMonth()}-${i + 1}`;
    if (date.getMonth() === today.getMonth() && i + 1 > today.getDate()) {
      return createFutureDateBox(i + 1, key);
    }
    return createPastDateBox(i + 1, key);
  });
  const previousMonthDays = createBlankBox(
    calendarDate.startDayOfWeek,
    "previous"
  );
  const nextMonthDays = createBlankBox(6 - calendarDate.endDayOfWeek, "next");
  const calendarBoxes = previousMonthDays
    .concat(currentMonthDays)
    .concat(nextMonthDays);

  return <div className={styles.Calendar}>{calendarBoxes}</div>;
};
export default CalendarDate;
