import { useMemo } from "react";
import sanitizeHtml from "sanitize-html";

const useSanitizedRubiedTextHtml = (html: string): string =>
  useMemo(
    () =>
      sanitizeHtml(html, {
        allowedTags: ["ruby", "rp", "rt"],
        allowedAttributes: { ruby: ["data-ruby"] },
        selfClosing: [],
        allowedSchemes: [],
        allowedSchemesAppliedToAttributes: [],
        enforceHtmlBoundary: true,
      }),
    [html]
  );

export default useSanitizedRubiedTextHtml;
