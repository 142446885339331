import React, { FC } from "react";
import BottomAppBar from "features/debugQuiz/components/bottomAppBar";
import LabeledIconButton from "features/quiz/components/labeledIconButton";
import pause from "features/quiz/assets/pause.svg";
import previous from "features/debugQuiz/assets/previous.svg";

export interface AnswerExplanationBottomAppBarProps {
  currentQuizNumber: number;
  totalQuizCount: number;
  onPauseClick: () => void;
  onBackClick: () => void;
}

const AnswerExplanationBottomAppBar: FC<AnswerExplanationBottomAppBarProps> = ({
  currentQuizNumber,
  totalQuizCount,
  onPauseClick,
  onBackClick,
}) => (
  <BottomAppBar
    currentQuizNumber={currentQuizNumber}
    totalQuizCount={totalQuizCount}
    centerItem={
      <LabeledIconButton
        icon={<img src={pause} alt="" />}
        label="ひとやすみ"
        onClick={onPauseClick}
      />
    }
    leftItem={
      <LabeledIconButton
        icon={<img src={previous} alt="" />}
        label="もどる"
        onClick={onBackClick}
      />
    }
  />
);

export default AnswerExplanationBottomAppBar;
