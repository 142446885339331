import React, { FC } from "react";
import styles from "./_index.module.scss";

export interface ImgLinkProps {
  imgSrc: string;
  label: string;
  href: string;
  top: string;
  left: string;
  width: string;
}

const ImgLink: FC<ImgLinkProps> = ({
  imgSrc,
  label,
  href,
  top,
  left,
  width,
}) => (
  <div className={styles.ImgLink} style={{ top, left }}>
    <a
      className={styles.a}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img className={styles.img} src={imgSrc} alt={label} style={{ width }} />
    </a>
  </div>
);

export default ImgLink;
