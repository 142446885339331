import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import TopAppBar from "./componets/topAppBar";
import LoginCalendarButton from "./componets/loginCalendarButton";
import styles from "./_index.module.scss";
import Squirrel from "./assets/squirrel.svg";
import Penguin from "./assets/penguin.svg";
import Speedometer1 from "./assets/speedometer1.svg";
import Speedometer2 from "./assets/speedometer2.svg";
import Speedometer3 from "./assets/speedometer3.svg";
import Lever from "./assets/lever.svg";

const HomeRocket: FC = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.HomeRocket}>
      <TopAppBar onHomeClick={() => navigate("/", { replace: true })} />
      <div className={styles.squirrel}>
        <img src={Squirrel} alt="squirrel" width="407px" height="443px" />
      </div>
      <div className={styles.penguin}>
        <img src={Penguin} alt="penguin" width="393px" height="398px" />
      </div>
      <div className={styles.items}>
        <div className={styles.speedometers}>
          <img
            src={Speedometer1}
            alt="speedometer1"
            width="100px"
            height="100px"
          />
          <img
            src={Speedometer2}
            alt="speedometer2"
            width="100px"
            height="100px"
          />
          <img
            src={Speedometer3}
            alt="speedometer3"
            width="100px"
            height="100px"
          />
        </div>
        <div className={styles.loginCalendarButton}>
          <LoginCalendarButton
            onClick={() => navigate("login-calendar", { replace: true })}
          />
        </div>
        <img src={Lever} alt="lever" width="150px" height="220px" />
      </div>
    </div>
  );
};

export default HomeRocket;
