import { useQuery } from "react-query";
import { QuizType } from "../../models/quizType";
import { getDebugQuizKey } from "./queryKeys";
import apiClient from "../../services/apiClient";
import QuizzesResponse from "../../services/models/quizzesResponse";
import { parseQuizResponse } from "./useListQuiz";

const fetchDebugQuiz = async (quizId: string): Promise<QuizType[]> => {
  const params = new URLSearchParams();
  // サーバ側にcategoryIdを渡す必要があるので、一旦固定で指定してる
  params.append("category_id", "1");
  params.append("quiz_ids", quizId);
  const response = (
    await apiClient.get<QuizzesResponse>(`debug/quizzes`, {
      params,
    })
  ).data;

  return response.quizSet.quizzes
    .map((quizResponse) => parseQuizResponse(quizResponse))
    .filter((quiz): quiz is QuizType => quiz !== undefined);
};

const useListDebugQuiz = (quizId: string) =>
  useQuery<QuizType[]>(
    getDebugQuizKey(quizId),
    async () => fetchDebugQuiz(quizId),
    {
      staleTime: 0,
      cacheTime: 0,
    }
  );

export default useListDebugQuiz;
