import React, { FC } from "react";
import styles from "features/loginCalendar/components/monthText/_index.module.scss";

export interface MonthTextProps {
  month: string;
}

const MonthText: FC<MonthTextProps> = ({ month }) => (
  <div className={styles.MonthText}>
    <span className={styles.monthNum}>{month}</span>
    <span className={styles.monthString}>月</span>
  </div>
);

export default MonthText;
