import React, { FC } from "react";
import AlertDialogButton from "components/alertDialogButton";
import { useNavigate } from "react-router-dom";
import styles from "./_index.module.scss";

export interface LoadingErrorProps {
  onRetry: () => void;
}

const LoadingError: FC<LoadingErrorProps> = ({ onRetry }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.LoadingError}>
      <div className={styles.content}>
        <p className={styles.message}>データを読み込めませんでした</p>

        <AlertDialogButton color="red" onClick={onRetry}>
          もう一度読み込む
        </AlertDialogButton>

        <AlertDialogButton onClick={() => navigate("/", { replace: true })}>
          ホームに戻る
        </AlertDialogButton>
      </div>
    </div>
  );
};

export default LoadingError;
