import { QuizType } from "../../../../models/quizType";

export default class Normal {
  constructor(
    public readonly incorrectQuizzes: QuizType[],
    public readonly categoryName: string,
    public readonly correctAnswerCount: number,
    public readonly totalQuizCount: number
  ) {}
}
