import Quiz from "models/quiz";

export default class TextInputQuiz implements Quiz {
  constructor(
    public readonly id: string,
    public readonly sentence: string,
    public readonly correctAnswerImageUrl: string,
    public readonly wrongAnswerImageUrl: string,
    public readonly timeLimitSeconds: number,
    public readonly consecutiveCorrectAnswerCount: number,
    public readonly imageUrl: string,
    public readonly characters: string[],
    public readonly correctAnswer: string
  ) {}

  get quizAssetUrls(): string[] {
    return [this.imageUrl];
  }

  copy(args: { consecutiveCorrectAnswerCount: number }): TextInputQuiz {
    return new TextInputQuiz(
      this.id,
      this.sentence,
      this.correctAnswerImageUrl,
      this.wrongAnswerImageUrl,
      this.timeLimitSeconds,
      args.consecutiveCorrectAnswerCount,
      this.imageUrl,
      this.characters,
      this.correctAnswer
    );
  }
}
