import React, { FC } from "react";
import styles from "./_index.module.scss";
import QuizResultButton from "../quizResultButton";
import homeIcon from "../../assets/home_icon.svg";
import retryIcon from "../../assets/retry_icon.svg";
import backIcon from "../../assets/back_icon.svg";
import reviewIcon from "../../assets/review_icon.svg";
import Ruby from "../../../../components/ruby";

export interface NormalNavigationActionsProps {
  reviewDisabled: boolean;
  onHomeClick: () => void;
  onRetryClick: () => void;
  onReviewClick: () => void;
  onBackClick?: () => void;
}

const NormalNavigationActions: FC<NormalNavigationActionsProps> = ({
  reviewDisabled,
  onHomeClick,
  onRetryClick,
  onBackClick,
  onReviewClick,
}) => (
  <div className={styles.NormalNavigationActions}>
    <QuizResultButton
      onClick={onHomeClick}
      text="ホーム"
      leadingIcon={<img src={homeIcon} alt="" />}
    />

    <QuizResultButton
      onClick={onRetryClick}
      text={
        <span>
          <Ruby text="同" ruby="おな" />
          じ
          <Ruby text="単元" ruby="たんげん" />
        </span>
      }
      leadingIcon={<img src={retryIcon} alt="" />}
      spread
    />

    {onBackClick && (
      <QuizResultButton
        onClick={onBackClick}
        text={
          <span>
            ほかの
            <Ruby text="単元" ruby="たんげん" />
          </span>
        }
        leadingIcon={<img src={backIcon} alt="" />}
      />
    )}

    <QuizResultButton
      onClick={onReviewClick}
      text={
        <span>
          <Ruby text="間違" ruby="まちが" />
          えた
          <Ruby text="問題" ruby="もんだい" />
        </span>
      }
      leadingIcon={<img src={reviewIcon} alt="" />}
      spread
      disabled={reviewDisabled}
    />
  </div>
);

export default NormalNavigationActions;
