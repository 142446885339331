import AuthProvider from "components/auth/authProvider";
import React from "react";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import ReactDOM from "react-dom/client";
import { QueryClientProvider } from "react-query";
import { HashRouter } from "react-router-dom";
import queryClient from "services/queryClient";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./styles/global.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
        <HashRouter>
          <AuthProvider>
            <App />
          </AuthProvider>
        </HashRouter>
      </DndProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
