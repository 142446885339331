import classNames from "classnames";
import RubiedText from "components/rubiedText";
import home from "features/quiz/assets/home.svg";
import LabeledIconButton from "features/quiz/components/labeledIconButton";
import { TopAppBar } from "features/quiz/components/layouts/topAppBar";
import Stopwatch from "features/quiz/components/stopwatch";
import React, { FC, useMemo } from "react";
import useCountRubiedText from "features/quiz/hooks/useCountRubiedText";
import styles from "./_index.module.scss";

export interface QuizTopAppBarProps {
  title: string;
  timeLeftMillis: number;
  onNavigationIconClick: () => void;
}

const QuizTopAppBar: FC<QuizTopAppBarProps> = ({
  title,
  timeLeftMillis,
  onNavigationIconClick,
}) => {
  const textLength = useCountRubiedText(title);
  const fontStyles = useMemo(() => {
    const isLongText = textLength > 50;

    return classNames(
      styles.title,
      isLongText ? styles["small-font"] : styles["large-font"]
    );
  }, [title]);

  return (
    <TopAppBar>
      <LabeledIconButton
        icon={<img src={home} alt="" />}
        label="ホーム"
        onClick={onNavigationIconClick}
      />
      <RubiedText className={fontStyles} rubiedTextHtml={title} />
      <Stopwatch timeLeftMillis={timeLeftMillis} />
    </TopAppBar>
  );
};

export default QuizTopAppBar;
