import AuthContext from "components/auth/authContext";
import AuthState from "models/authState";
import React, { ReactElement, useContext } from "react";
import SessionExpirationError from "../sessionExpirationError";

interface PrivateRouteProps {
  children: ReactElement;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { state } = useContext(AuthContext);

  return state === AuthState.UnAuthorized ? (
    <SessionExpirationError />
  ) : (
    children
  );
};

export default PrivateRoute;
