import { QuizCategories, QuizCategory } from "models/quizCategory";
import { Subject } from "models/subject";
import { useQuery } from "react-query";
import apiClient from "services/apiClient";
import { getQuizCategoriesQueryKey } from "./queryKeys";
import { convertSubjectParam } from "./useListTrainingCategories";

type Params = {
  subject: Subject;
};

const fetchQuizCategories = async (
  parameters: Params
): Promise<QuizCategory[]> => {
  const res = await apiClient.get<QuizCategories>(
    `/v1/quizzes/categories?subject=${convertSubjectParam(parameters.subject)}`
  );
  return res.data.categories;
};

const useListQuizCategories = (
  params: Params,
  options?: { enabled?: boolean }
) =>
  useQuery<QuizCategory[]>(
    getQuizCategoriesQueryKey(params.subject),
    async () => fetchQuizCategories(params),
    options
  );

export default useListQuizCategories;
