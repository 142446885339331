import React, { FC, useMemo } from "react";
import { AlienAsset } from "../../models/alienAsset";
import styles from "./_index.module.scss";

export interface AlienProps {
  asset: AlienAsset;
  active: boolean;
  onClick: () => void;
}

const Alien: FC<AlienProps> = ({ asset, active, onClick }) => {
  const src = useMemo(
    () => (active ? asset.active : asset.normal),
    [asset, active]
  );

  return (
    <button className={styles.Alien} type="button" onClick={onClick}>
      <img src={src} alt="" />
    </button>
  );
};

export default Alien;
