import React, { FC, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Subject } from "models/subject";
import useListTrainingCategories from "hooks/queries/useListTrainingCategories";
import queryString from "query-string";
import styles from "./_index.module.scss";
import TrainingCategoryBox from "../components/trainingCategoryBox";
import { TrainingCategory } from "../../../models/trainingCategory";
import CategorySelection from "../components/categorySelection";
import { chunked } from "../../../collection";
import { CATEGORY_COUNT_PER_PAGE } from "../const";
import BackButton from "../components/backButton";
import ForwardButton from "../components/forwardButton";
import ActionButton from "../components/actionButton";
import home from "../../../assets/home.svg";

interface TrainingCategoryListContentProps {
  trainingCategories: TrainingCategory[];
  initialTargetCategoryId?: number;
}

const TrainingCategoryListContent: FC<TrainingCategoryListContentProps> = ({
  trainingCategories,
  initialTargetCategoryId,
}) => {
  const navigate = useNavigate();

  const chunkedTrainingCategories = useMemo(
    () => chunked(trainingCategories, CATEGORY_COUNT_PER_PAGE),
    [trainingCategories]
  );

  const [pageIndex, setPageIndex] = useState(
    initialTargetCategoryId !== undefined
      ? chunkedTrainingCategories.findIndex((categories) =>
          categories.some(
            (quizCategory) =>
              quizCategory.category.id === initialTargetCategoryId
          )
        )
      : 0
  );

  const currentTrainingCategories = useMemo(
    () => chunkedTrainingCategories[pageIndex],
    [chunkedTrainingCategories, pageIndex]
  );

  const canGoBack = useMemo(() => pageIndex > 0, [pageIndex]);
  const canGoForward = useMemo(
    () => pageIndex < chunkedTrainingCategories.length - 1,
    [chunkedTrainingCategories, pageIndex]
  );

  const className = classNames(styles.CategoryList, {
    [styles.CategoryListOneItem]: currentTrainingCategories.length === 1,
  });

  return (
    <div className={styles.frame}>
      <div className={className}>
        {currentTrainingCategories.map((trainingCategory) => (
          <TrainingCategoryBox
            key={trainingCategory.category.id}
            name={trainingCategory.category.name}
            imageUrl={trainingCategory.category.imageUrl}
            count={trainingCategory.count}
            onCategoryClick={() => {
              navigate(`category/${trainingCategory.category.id}`, {
                state: {
                  name: trainingCategory.category.name,
                  count: trainingCategory.count,
                },
                replace: true,
              });
            }}
          />
        ))}
      </div>

      <div className={styles["paging-buttons"]}>
        <BackButton
          onClick={() => setPageIndex(pageIndex - 1)}
          disabled={!canGoBack}
        />
        <ForwardButton
          onClick={() => setPageIndex(pageIndex + 1)}
          disabled={!canGoForward}
        />
      </div>
    </div>
  );
};

interface TrainingCategoryListState {
  targetCategoryId?: number;
}

export interface TrainingCategoryListProps {
  subject: Subject;
}

const TrainingCategoryList: FC<TrainingCategoryListProps> = ({ subject }) => {
  const location = useLocation();
  const state = location.state
    ? (location.state as TrainingCategoryListState)
    : undefined;

  const { isLoading, isError, data, refetch } = useListTrainingCategories({
    subject,
  });
  const navigate = useNavigate();

  return (
    <CategorySelection
      subject={subject}
      isLoading={isLoading}
      isError={isError}
      onPreviousClick={() =>
        navigate(
          {
            pathname: "/dojo",
            search: queryString.stringify({
              isQuizMode: false,
              subject: subject.name,
            }),
          },
          { replace: true }
        )
      }
      onRetry={refetch}
      actions={
        <ActionButton onClick={() => navigate("/", { replace: true })}>
          <img src={home} alt="home" />
        </ActionButton>
      }
    >
      {data && (
        <TrainingCategoryListContent
          trainingCategories={data}
          initialTargetCategoryId={state?.targetCategoryId}
        />
      )}
    </CategorySelection>
  );
};

export default TrainingCategoryList;
