import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import styles from "./_index.module.scss";

export interface ToastProps {
  children: ReactNode;
  onDismiss: () => void;
}

const Toast: FC<ToastProps> = ({ children, onDismiss }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      containerRef.current?.addEventListener("animationend", onDismiss);
      setHidden(true);
    }, 4_000);
  }, []);

  return (
    <div
      className={classNames(styles.Toast, {
        [styles.ToastHidden]: hidden,
      })}
      ref={containerRef}
    >
      <div className={styles.container}>{children}</div>
    </div>
  );
};

export default Toast;
