import React, { FC, ReactNode } from "react";
import styles from "./_index.module.scss";

export interface DialogProps {
  message: ReactNode;
  children: ReactNode;
}

const AlertDialog: FC<DialogProps> = ({ message, children }) => (
  <div className={styles.AlertDialog}>
    <div className={styles.frame}>
      <div className={styles["message-frame"]}>
        <p className={styles.message}>{message}</p>
      </div>

      <div className={styles.actions}>{children}</div>
    </div>
  </div>
);

export default AlertDialog;
