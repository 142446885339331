import React, { FC } from "react";
import AbsolutelyPositionedBox from "components/absolutelyPositionedBox";
import penguin from "assets/penguin.svg";
import balloon from "features/loginCalendar/assets/balloon.svg";
import styles from "./_index.module.scss";
import Ruby from "../../../../components/ruby";

export interface CalendarFooterProps {
  correctAnswerRate: number;
  numberOfLoggedInDates: number;
}

const CalendarFooter: FC<CalendarFooterProps> = ({
  correctAnswerRate,
  numberOfLoggedInDates,
}) => (
  <div className={styles.CalendarFooter}>
    <div className={styles.penguin}>
      <img src={penguin} alt="penguin" />
    </div>
    <div className={styles.balloon}>
      <img src={balloon} alt="balloon" />
      <AbsolutelyPositionedBox top="18px" left="64px">
        {`たくさんログインして\nメダルのスタンプをためよう！`}
      </AbsolutelyPositionedBox>
    </div>
    <div className={styles.box}>
      <div className={styles.correctAnswerRateText}>
        <Ruby text="正解率" ruby="せいかいりつ" />
      </div>
      {(correctAnswerRate * 100).toFixed()}
      <span className={styles.percent}>%</span>
    </div>
    <div className={styles.box}>
      <div className={styles.loginText}>
        ログイン
        <Ruby text="日数" ruby="にっすう" />
      </div>
      {numberOfLoggedInDates}
      <span className={styles.date}>日</span>
    </div>
  </div>
);
export default CalendarFooter;
