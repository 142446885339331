import React, { FC } from "react";
import { useDrop } from "react-dnd";
import Card from "features/quiz/models/rearrangementQuiz/card";
import CardPosition from "features/quiz/models/rearrangementQuiz/cardPosition";
import { DraggableItemType } from "features/quiz/components/draggableItemType";
import { CardPositionType } from "features/quiz/models/rearrangementQuiz/cardPositionType";
import classNames from "classnames";
import ImageCard, { ImageCardDragItem } from "../imageCard";
import styles from "./_index.module.scss";

export interface DraggableZoneProps {
  index: number;
  card?: Card;
  onCardDropped: (position: CardPosition) => void;
}

interface DropState {
  isOver: boolean;
  canDrop: boolean;
}

const DraggableZone: FC<DraggableZoneProps> = ({
  index,
  card,
  onCardDropped,
}) => {
  const [{ isOver, canDrop }, drop] = useDrop<
    ImageCardDragItem,
    unknown,
    DropState
  >(() => ({
    accept: DraggableItemType.ImageCard,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: (item) => {
      const position: CardPosition = {
        type: CardPositionType.Initial,
        index,
        card: item.card,
      };
      onCardDropped(position);
    },
  }));

  const classes = classNames(styles["drop-zone"], {
    [styles.over]: isOver && canDrop,
  });

  return (
    <div className={styles.DraggableZone}>
      <div className={classes} ref={drop} />

      {card && (
        <div className={styles["card-container"]}>
          <ImageCard card={card} />
        </div>
      )}
    </div>
  );
};

export default DraggableZone;
