import React, { FC, ReactNode } from "react";
import styles from "./_index.module.scss";

export interface ActionButtonProps {
  children: ReactNode;
  onClick: () => void;
}

const ActionButton: FC<ActionButtonProps> = ({ children, onClick }) => (
  <button type="button" className={styles.ActionButton} onClick={onClick}>
    {children}
  </button>
);

export default ActionButton;
