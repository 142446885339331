import React, { createContext, CSSProperties, FC, useMemo } from "react";
import { useWindowSize } from "hooks/useWindowSize";
import { Outlet } from "react-router-dom";
import styles from "components/layouts/pageContainer/_index.module.scss";

export const ORIGINAL_PAGE_WIDTH = 1_366;
export const ORIGINAL_PAGE_HEIGHT = 1_024;

export class PageContainerContextData {
  public readonly scale: number;

  public readonly translateX: number;

  public readonly translateY: number;

  constructor(
    public readonly originalPageWidth: number,
    public readonly originalPageHeight: number,
    public readonly windowWidth: number,
    public readonly windowHeight: number
  ) {
    const fitWidthScale = Math.max(windowWidth, 1) / this.originalPageWidth;
    const fitWidthSize = {
      width: this.originalPageWidth * fitWidthScale,
      height: this.originalPageHeight * fitWidthScale,
    };

    const fitHeightScale = Math.max(windowHeight, 1) / this.originalPageHeight;
    const fitHeightSize = {
      width: this.originalPageWidth * fitHeightScale,
      height: this.originalPageHeight * fitHeightScale,
    };

    if (fitWidthSize.height > windowHeight) {
      this.scale = fitHeightScale;
      this.translateX = (windowWidth - fitHeightSize.width) / 2;
      this.translateY = 0;
    } else {
      this.scale = fitWidthScale;
      this.translateX = 0;
      this.translateY = (windowHeight - fitWidthSize.height) / 2;
    }
  }
}

export const PageContainerContext = createContext<PageContainerContextData>(
  new PageContainerContextData(ORIGINAL_PAGE_WIDTH, ORIGINAL_PAGE_HEIGHT, 0, 0)
);

const PageContainer: FC = () => {
  const windowSize = useWindowSize();

  const contextData = useMemo(
    () =>
      new PageContainerContextData(
        ORIGINAL_PAGE_WIDTH,
        ORIGINAL_PAGE_HEIGHT,
        windowSize.width,
        windowSize.height
      ),
    [windowSize]
  );

  const {
    originalPageWidth,
    originalPageHeight,
    translateX,
    translateY,
    scale,
  } = contextData;
  const containerStyle: CSSProperties = {
    width: originalPageWidth,
    height: originalPageHeight,
    transform: `translate(${translateX}px, ${translateY}px) scale(${scale})`,
  };

  return (
    <div className={styles.PageContainer}>
      <div className={styles.inner} style={containerStyle}>
        <PageContainerContext.Provider value={contextData}>
          <Outlet />
        </PageContainerContext.Provider>
      </div>
    </div>
  );
};

export default PageContainer;
