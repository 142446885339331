import React, { FC, ReactNode } from "react";
import TimeUpOverlay from "features/quiz/components/timeUpOverlay";
import QuizTopAppBar from "features/quiz/components/quizTopAppBar";
import QuizBottomAppBar from "features/quiz/components/quizBottomAppBar";
import styles from "./_index.module.scss";

export interface QuizLayoutProps {
  sentence: string;
  timeLeftMillis: number;
  isAnswered: boolean;
  isTimeUpOverlayVisible: boolean;
  currentQuizNumber: number;
  totalQuizCount: number;
  consecutiveCorrectAnswerCount: number;
  onNavigationIconClick: () => void;
  onPauseClick: () => void;
  onNextClick: () => void;
  children: ReactNode;
}

const QuizLayout: FC<QuizLayoutProps> = ({
  sentence,
  timeLeftMillis,
  isAnswered,
  isTimeUpOverlayVisible,
  currentQuizNumber,
  totalQuizCount,
  consecutiveCorrectAnswerCount,
  onNavigationIconClick,
  onPauseClick,
  onNextClick,
  children,
}) => (
  <div className={styles.QuizLayout}>
    <QuizTopAppBar
      title={sentence}
      timeLeftMillis={timeLeftMillis}
      onNavigationIconClick={onNavigationIconClick}
    />

    <div className={styles.content}>{children}</div>

    <QuizBottomAppBar
      currentQuizNumber={currentQuizNumber}
      totalQuizCount={totalQuizCount}
      consecutiveCorrectAnswerCount={consecutiveCorrectAnswerCount}
      isNextButtonVisible={isAnswered}
      onPauseClick={onPauseClick}
      onNextClick={onNextClick}
    />

    {isTimeUpOverlayVisible && (
      <div className={styles["time-up-overlay"]}>
        <TimeUpOverlay />
      </div>
    )}
  </div>
);

export default QuizLayout;
