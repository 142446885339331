import React, { FC, ReactNode } from "react";
import styles from "./_index.module.scss";
import QuizResultContainer from "../quizResultContainer";
import Ruby from "../../../../components/ruby";
import penguin_blue from "../../assets/penguin_blue.svg";
import penguin_pink from "../../assets/penguin_pink.svg";
import cherry_blossom_large from "../../assets/cherryblossom_big.svg";
import cherry_blossom_small from "../../assets/cherryblossom_small.svg";

export interface QuizClearedProps {
  text: ReactNode;
  children: ReactNode;
}

const QuizCleared: FC<QuizClearedProps> = ({ text, children }) => (
  <div className={styles.QuizCleared}>
    <div className={styles["cherry-blossom-wrapper"]}>
      <img
        className={styles["cherry-blossom-large-left"]}
        src={cherry_blossom_large}
        alt=""
      />

      <img
        className={styles["cherry-blossom-small-left"]}
        src={cherry_blossom_small}
        alt=""
      />

      <img
        className={styles["cherry-blossom-small-right"]}
        src={cherry_blossom_small}
        alt=""
      />

      <img
        className={styles["cherry-blossom-large-right"]}
        src={cherry_blossom_large}
        alt=""
      />
      <QuizResultContainer
        title={
          <span>
            ぜんもん
            <Ruby text="修得" ruby="しゅうとく" />！
          </span>
        }
      >
        <div className={styles.content}>
          <div className={styles["cleared-message"]}>
            <p>{text}</p>
            <p>がんばったね！</p>
          </div>

          <div className={styles.description}>
            <p>
              <Ruby text="何回" ruby="なんかい" />
              でも
              <Ruby text="遊" ruby="あそ" />
              べるから
            </p>
            <p>
              また
              <Ruby text="勉強" ruby="べんきょう" />
              しようね！
            </p>
          </div>
        </div>
      </QuizResultContainer>
    </div>

    <div className={styles.actions}>{children}</div>

    <img className={styles["blue-penguin"]} src={penguin_blue} alt="" />
    <img className={styles["pink-penguin"]} src={penguin_pink} alt="" />
  </div>
);

export default QuizCleared;
