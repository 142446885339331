import React, { FC } from "react";
import classNames from "classnames";
import SelectionQuizCChoice from "models/selectionQuizC/selectionQuizCChoice";
import SelectionQuizC from "models/selectionQuizC/selectionQuizC";
import SelectionQuizCAnswer from "models/selectionQuizC/selectionQuizCAnswer";
import SelectionQuizBAnswer from "models/selectionQuizB/selectionQuizBAnswer";
import styles from "./_index.module.scss";

type ButtonState = "neutral" | "selected" | "not_selected";

export interface ChoiceButtonProps {
  choice: SelectionQuizCChoice;
  state: ButtonState;
  onClick: () => void;
}

const ChoiceButton: FC<ChoiceButtonProps> = ({ choice, state, onClick }) => {
  const classes = classNames({
    [styles["button-selected"]]: state === "selected",
    [styles["button-not-selected"]]: state === "not_selected",
  });

  return (
    <button type="button" className={classes} onClick={onClick}>
      <img alt={choice.id} src={choice.imageUrl} />
    </button>
  );
};

export interface QuizFmtSelectionCProps {
  quiz: SelectionQuizC;
  answer?: SelectionQuizCAnswer;
  onAnswerChanged: (answer: SelectionQuizBAnswer) => void;
}

const QuizFmtSelectionC: FC<QuizFmtSelectionCProps> = ({
  quiz,
  answer,
  onAnswerChanged,
}) => (
  <div className={styles.QuizFmtSelectionC}>
    <img className={styles["question-img"]} alt="" src={quiz.imageUrl} />
    <div className={styles["choice-zone"]}>
      {quiz.choices.map((choice) => (
        <ChoiceButton
          key={choice.id}
          choice={choice}
          state={(() => {
            if (!answer) {
              return "neutral";
            }

            return choice.id === answer.choiceId ? "selected" : "not_selected";
          })()}
          onClick={() => onAnswerChanged(new SelectionQuizCAnswer(choice.id))}
        />
      ))}
    </div>
  </div>
);

export default QuizFmtSelectionC;
