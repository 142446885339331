import React, { FC, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString, { ParsedQuery } from "query-string";
import { TopAppBar } from "./components/topAppBar";
import { ToggleSwitch } from "./components/toggleSwitch";
import { Overlay } from "./components/overlay";
import styles from "./_index.module.scss";
import { SubjectButtons } from "./components/subjectButtons";
import { AllSubjectStyle, SubjectStyle } from "./models/subjectStyle";

interface UiState {
  isQuizMode?: boolean;
  subject?: SubjectStyle;
}

const parseState = (query: ParsedQuery): UiState => ({
  isQuizMode: query.isQuizMode !== undefined && query.isQuizMode === "true",
  subject:
    query.subject !== undefined
      ? AllSubjectStyle.find((sub) => sub.subject.name === query.subject)
      : undefined,
});

const Dojo: FC = () => {
  const query = queryString.parse(useLocation().search);
  const initialUiState = useMemo(() => parseState(query), []);

  const [isQuizMode, setQuizMode] = useState(
    initialUiState.isQuizMode ?? false
  ); // falseで「まなぶ」, trueで「しゅぎょう」

  const [subject, setSubject] = useState<SubjectStyle | undefined>(
    initialUiState.subject
  );

  const navigate = useNavigate();

  const handleSubjectClicked = (sub: SubjectStyle) => {
    navigate(
      {
        search: queryString.stringify({
          subject: sub.subject.name,
          isQuizMode,
        }),
      },
      { replace: true }
    );
  };

  // クエリパラメータから、モードと科目を反映
  useEffect(() => {
    const uiState = parseState(query);

    if (uiState.isQuizMode !== undefined) {
      setQuizMode(uiState.isQuizMode);
    }

    if (uiState.subject !== undefined) {
      setSubject(uiState.subject);
    }
  }, [query]);

  return (
    <div className={styles.Dojo}>
      <TopAppBar
        title="クイズ道場"
        onPreviousClick={() => navigate("/", { replace: true })}
        onRocketClick={() => navigate("/rocket", { replace: true })}
        onHomeClick={() => navigate("/", { replace: true })}
      />
      <ToggleSwitch
        label="まなぶ・しゅぎょう切り替え"
        checked={isQuizMode}
        onToggleClick={() =>
          navigate(
            {
              search: queryString.stringify({
                subject: subject?.subject.name,
                isQuizMode: !isQuizMode,
              }),
            },
            { replace: true }
          )
        }
      />
      <SubjectButtons handleSubjectClicked={handleSubjectClicked} />
      {subject && (
        <Overlay
          subjectStyle={subject}
          handleClick={() =>
            isQuizMode
              ? navigate(`/quiz/subject/${subject.subject.name}`, {
                  replace: true,
                })
              : navigate(`/training/subject/${subject.subject.name}`, {
                  replace: true,
                })
          }
        />
      )}
    </div>
  );
};

export default Dojo;
