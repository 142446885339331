import { StudentAnswerType } from "models/studentAnswerType";

export default class AnsweringQuiz {
  constructor(
    public readonly currentQuizNumber: number,
    public readonly totalQuizCount: number,
    public readonly studentAnswer: StudentAnswerType,
    public readonly isPausing: boolean
  ) {}
}
