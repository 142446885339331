import React, { FC, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { Subject } from "models/subject";
import useListQuizCategories from "hooks/queries/useListQuizCategories";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import QuizCategoryBox from "../components/quizCategoryBox";
import styles from "./_index.module.scss";
import CategorySelection from "../components/categorySelection";
import { QuizCategory } from "../../../models/quizCategory";
import { chunked } from "../../../collection";
import { CATEGORY_COUNT_PER_PAGE } from "../const";
import BackButton from "../components/backButton";
import ForwardButton from "../components/forwardButton";
import ActionButton from "../components/actionButton";
import settingsIcon from "../assets/settings.svg";
import home from "../../../assets/home.svg";
import QuizCountSettingDialog from "../../../components/quizCountSettingDialog";
import useQuizCountSettings from "../../../hooks/queries/useQuizCountSettings";
import useSaveQuizCountSetting from "../../../hooks/queries/useSaveQuizCountSetting";
import Toast from "../../../components/toast";

export interface QuizCategoryListContentProps {
  subject: Subject;
  quizCategories: QuizCategory[];
  initialTargetCategoryId?: number;
}

const QuizCategoryListContent: FC<QuizCategoryListContentProps> = ({
  subject,
  quizCategories,
  initialTargetCategoryId,
}) => {
  const navigate = useNavigate();

  const chunkedQuizCategories = useMemo(
    () => chunked(quizCategories, CATEGORY_COUNT_PER_PAGE),
    [quizCategories]
  );

  const [pageIndex, setPageIndex] = useState(
    initialTargetCategoryId !== undefined
      ? chunkedQuizCategories.findIndex((categories) =>
          categories.some(
            (quizCategory) =>
              quizCategory.category.id === initialTargetCategoryId
          )
        )
      : 0
  );

  const currentQuizCategories = useMemo(
    () => chunkedQuizCategories[pageIndex],
    [chunkedQuizCategories, pageIndex]
  );

  const canGoBack = useMemo(() => pageIndex > 0, [pageIndex]);
  const canGoForward = useMemo(
    () => pageIndex < chunkedQuizCategories.length - 1,
    [chunkedQuizCategories, pageIndex]
  );

  const className = classNames(styles.CategoryList, {
    [styles.CategoryListOneItem]: currentQuizCategories.length === 1,
  });

  return (
    <div className={styles.frame}>
      <div className={className}>
        {currentQuizCategories.map((quizCategory) => (
          <QuizCategoryBox
            key={quizCategory.category.id}
            name={quizCategory.category.name}
            imageUrl={quizCategory.category.imageUrl}
            count={quizCategory.count}
            isUnlocked={quizCategory.isUnlocked}
            numberOfQuizzes={quizCategory.numberOfQuizzes}
            numberOfMasteredQuizzes={quizCategory.numberOfMasteredQuizzes}
            isMastered={quizCategory.isMastered}
            onCategoryClick={() => {
              if (!quizCategory.isUnlocked) {
                navigate(`/training/subject/${subject.name}`, {
                  replace: true,
                  state: { targetCategoryId: quizCategory.category.id },
                });
                return;
              }

              navigate(`category/${quizCategory.category.id}`, {
                state: { categoryName: quizCategory.category.name },
                replace: true,
              });
            }}
          />
        ))}
      </div>

      <div className={styles["paging-buttons"]}>
        <BackButton
          onClick={() => setPageIndex(pageIndex - 1)}
          disabled={!canGoBack}
        />
        <ForwardButton
          onClick={() => setPageIndex(pageIndex + 1)}
          disabled={!canGoForward}
        />
      </div>
    </div>
  );
};

interface QuizCategoryListState {
  targetCategoryId?: number;
}

export interface QuizCategoryListProps {
  subject: Subject;
}

const QuizCategoryList: FC<QuizCategoryListProps> = ({ subject }) => {
  const location = useLocation();
  const state = location.state
    ? (location.state as QuizCategoryListState)
    : undefined;

  const {
    isLoading: isLoadingCategories,
    isError: isCategoriesLoadError,
    data: categories,
    refetch: refetchCategories,
  } = useListQuizCategories({
    subject,
  });

  const {
    isLoading: isLoadingSettings,
    isError: isSettingsLoadError,
    data: settings,
    refetch: refetchSettings,
  } = useQuizCountSettings();

  const { mutate, isError: isSaveError, reset } = useSaveQuizCountSetting();

  const isLoading = useMemo(
    () => isLoadingCategories || isLoadingSettings,
    [isLoadingCategories, isLoadingSettings]
  );
  const isError = useMemo(
    () => isCategoriesLoadError || isSettingsLoadError,
    [isCategoriesLoadError, isSettingsLoadError]
  );

  const navigate = useNavigate();

  const [showSettingDialog, setShowSettingDialog] = useState(false);

  const [showSaveError, setShowSaveError] = useState(false);

  useEffect(() => {
    setShowSaveError(isSaveError);
  }, [isSaveError]);

  return (
    <>
      <CategorySelection
        subject={subject}
        isLoading={isLoading}
        isError={isError}
        onPreviousClick={() =>
          navigate(
            {
              pathname: "/dojo",
              search: queryString.stringify({
                isQuizMode: true,
                subject: subject.name,
              }),
            },
            { replace: true }
          )
        }
        onRetry={() => {
          refetchCategories();
          refetchSettings();
        }}
        actions={
          <>
            <ActionButton onClick={() => setShowSettingDialog(true)}>
              <img src={settingsIcon} alt="settings" />
            </ActionButton>

            <ActionButton onClick={() => navigate("/", { replace: true })}>
              <img src={home} alt="home" />
            </ActionButton>
          </>
        }
      >
        {categories && (
          <QuizCategoryListContent
            subject={subject}
            quizCategories={categories}
            initialTargetCategoryId={state?.targetCategoryId}
          />
        )}
      </CategorySelection>

      {showSettingDialog && (
        <QuizCountSettingDialog
          initialSetting={settings?.quiz}
          variant="orange"
          onDismiss={(setting) => {
            if (settings?.quiz !== setting) {
              mutate(setting);
            }
            setShowSettingDialog(false);
          }}
        >
          1回の「しゅぎょう」で出題される問題数を設定できます
        </QuizCountSettingDialog>
      )}

      {showSaveError && (
        <div className={styles["toast-container"]}>
          <Toast
            onDismiss={() => {
              reset();
            }}
          >
            設定の保存に失敗しました 時間をおいて再度お試しください
          </Toast>
        </div>
      )}
    </>
  );
};

export default QuizCategoryList;
