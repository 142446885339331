import React, { FC } from "react";
import { useDrop } from "react-dnd";
import RubiedText from "components/rubiedText";
import Card from "features/quiz/models/rearrangementQuiz/card";
import CardPosition from "features/quiz/models/rearrangementQuiz/cardPosition";
import { CardPositionType } from "features/quiz/models/rearrangementQuiz/cardPositionType";
import CardDropZone from "features/quiz/models/rearrangementQuiz/cardDropZone";
import ImageCard, {
  ImageCardDragItem,
} from "features/quiz/components/imageCard";
import { DraggableItemType } from "features/quiz/components/draggableItemType";
import classNames from "classnames";
import styles from "./_index.module.scss";

export interface LabeledDropZoneProps {
  index: number;
  cardDropZone: CardDropZone;
  card?: Card;
  onCardDropped: (position: CardPosition) => void;
}

interface DropState {
  isOver: boolean;
  canDrop: boolean;
}

const LabeledDropZone: FC<LabeledDropZoneProps> = ({
  index,
  cardDropZone,
  card,
  onCardDropped,
}) => {
  const [{ isOver, canDrop }, drop] = useDrop<
    ImageCardDragItem,
    unknown,
    DropState
  >(() => ({
    accept: DraggableItemType.ImageCard,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: (item) => {
      const position: CardPosition = {
        type: CardPositionType.Answer,
        index,
        card: item.card,
      };
      onCardDropped(position);
    },
  }));

  const classes = classNames(styles["drop-zone"], {
    [styles.over]: isOver && canDrop,
  });

  return (
    <div className={styles.LabeledDropZone}>
      <RubiedText
        className={styles.label}
        rubiedTextHtml={cardDropZone.label}
      />

      <div className={styles["drop-zone-container"]}>
        <div className={classes} ref={drop}>
          ？
        </div>

        {card && (
          <div className={styles["card-container"]}>
            <ImageCard card={card} />
          </div>
        )}
      </div>
    </div>
  );
};

export default LabeledDropZone;
