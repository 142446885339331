import { useQuery } from "react-query";
import { QuizType } from "../../models/quizType";
import { getMonthlyExamQuizzesKey } from "./queryKeys";
import apiClient from "../../services/apiClient";
import QuizzesResponse from "../../services/models/quizzesResponse";
import { MonthlyExamPreparationSubject } from "../../models/monthlyExamPreparationSubject";
import { parseQuizResponse } from "./useListQuiz";

const convertSubjectParam = (subject: MonthlyExamPreparationSubject) => {
  switch (subject) {
    case MonthlyExamPreparationSubject.SocialStudies:
      return "SOCIOLOGY";
    case MonthlyExamPreparationSubject.Science:
      return "SCIENCE";
    default:
      return "";
  }
};

const fetchMonthlyExamQuizzes = async (
  subject: MonthlyExamPreparationSubject,
  preparationNumber: number
): Promise<QuizType[]> => {
  const response = (
    await apiClient.get<QuizzesResponse>(
      `v1/monthly_exams/${preparationNumber}/quizzes?subject=${convertSubjectParam(
        subject
      )}`
    )
  ).data;

  return response.quizSet.quizzes
    .map((quizResponse) => parseQuizResponse(quizResponse))
    .filter((quiz): quiz is QuizType => quiz !== undefined);
};

const useListMonthlyExamQuizzes = (
  subject: MonthlyExamPreparationSubject,
  preparationNumber: number
) =>
  useQuery<QuizType[]>(
    getMonthlyExamQuizzesKey(subject, preparationNumber),
    async () => fetchMonthlyExamQuizzes(subject, preparationNumber),
    {
      staleTime: 0,
      cacheTime: 0,
    }
  );

export default useListMonthlyExamQuizzes;
