import React, { FC } from "react";
import rocket from "../../assets/rocketBox.svg";
import completedRocket from "../../assets/masteredRocketBox.svg";
import rocketFirstUnlockedBox from "../../assets/rocketFirstUnlockedBox.svg";
import unlockStar from "../../assets/unlockStar.svg";
import lock from "../../assets/lock.svg";
import styles from "./_index.module.scss";
import CategoryBox from "../../../categorySelection/components/categoryBox";

export interface QuizCategoryBoxProps {
  name: string;
  imageUrl: string;
  count: number;
  isUnlocked: boolean;
  isMastered: boolean;
  unlockCategory: string;
}

const QuizCategoryBox: FC<QuizCategoryBoxProps> = ({
  name,
  imageUrl,
  count,
  isUnlocked,
  isMastered,
  unlockCategory,
}) => {
  function rocketBox() {
    if (isMastered) return completedRocket;
    if (name === unlockCategory) return rocketFirstUnlockedBox;
    return rocket;
  }

  return (
    <div className={styles.QuizCategoryBox}>
      <CategoryBox
        name={name}
        imageUrl={imageUrl}
        count={count}
        rocketBox={rocketBox()}
      />
      {!isUnlocked && (
        <div className={styles.lockedItem}>
          <div className={styles.backToTraining}>まなぶ</div>
          <div className={styles.lock}>
            <img src={lock} alt="locked" width="82px" height="110px" />
          </div>
        </div>
      )}
      {name === unlockCategory && (
        <div className={styles.firstUnlockedItem}>
          <img
            src={unlockStar}
            alt="unlockStar"
            className={styles.firstUnlockedStar}
          />
        </div>
      )}
    </div>
  );
};
export default QuizCategoryBox;
