import { useCallback, useState } from "react";
import dayjs from "dayjs";

const useDate = (initialDate: Date) => {
  const [date, setDate] = useState(initialDate);

  const goBackToPreviousMonth = useCallback(() => {
    setDate((prevDate) => dayjs(prevDate).subtract(1, "month").toDate());
  }, []);
  const goForwardToNextMonth = useCallback(() => {
    setDate((prevDate) => dayjs(prevDate).add(1, "month").toDate());
  }, []);

  return [date, { goBackToPreviousMonth, goForwardToNextMonth }] as const;
};

export default useDate;
