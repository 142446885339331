import { useMemo } from "react";
import janLeft from "features/loginCalendar/assets/jan/left.svg";
import janRight from "features/loginCalendar/assets/jan/right.svg";
import febLeft from "features/loginCalendar/assets/feb/left.svg";
import febRight from "features/loginCalendar/assets/feb/right.svg";
import marLeft from "features/loginCalendar/assets/mar/left.svg";
import marRight from "features/loginCalendar/assets/mar/right.svg";
import aprLeft from "features/loginCalendar/assets/apr/left.svg";
import aprRight from "features/loginCalendar/assets/apr/right.svg";
import mayLeft from "features/loginCalendar/assets/may/left.svg";
import mayRight from "features/loginCalendar/assets/may/right.svg";
import junLeft from "features/loginCalendar/assets/jun/left.svg";
import junRight from "features/loginCalendar/assets/jun/right.svg";
import julLeft from "features/loginCalendar/assets/jul/left.svg";
import julRight from "features/loginCalendar/assets/jul/right.svg";
import augLeft from "features/loginCalendar/assets/aug/left.svg";
import augRight from "features/loginCalendar/assets/aug/right.svg";
import sepLeft from "features/loginCalendar/assets/sep/left.svg";
import sepRight from "features/loginCalendar/assets/sep/right.svg";
import octLeft from "features/loginCalendar/assets/oct/left.svg";
import octRight from "features/loginCalendar/assets/oct/right.svg";
import novLeft from "features/loginCalendar/assets/nov/left.svg";
import novRight from "features/loginCalendar/assets/nov/right.svg";
import decLeft from "features/loginCalendar/assets/dec/left.svg";
import decRight from "features/loginCalendar/assets/dec/right.svg";
import useCalendarDate from "./useCalendarDate";

export interface MonthlyImage {
  left: string;
  right: string;
}

const useMonthlyImage = (date: Date): MonthlyImage => {
  const calendarDate = useCalendarDate(date);
  return useMemo(() => {
    switch (calendarDate.currentMonth) {
      case "1":
        return { left: janLeft, right: janRight };
      case "2":
        return { left: febLeft, right: febRight };
      case "3":
        return { left: marLeft, right: marRight };
      case "4":
        return { left: aprLeft, right: aprRight };
      case "5":
        return { left: mayLeft, right: mayRight };
      case "6":
        return { left: junLeft, right: junRight };
      case "7":
        return { left: julLeft, right: julRight };
      case "8":
        return { left: augLeft, right: augRight };
      case "9":
        return { left: sepLeft, right: sepRight };
      case "10":
        return { left: octLeft, right: octRight };
      case "11":
        return { left: novLeft, right: novRight };
      case "12":
        return { left: decLeft, right: decRight };
      default:
        return { left: "", right: "" };
    }
  }, [date]);
};
export default useMonthlyImage;
