import React, { FC } from "react";
import styles from "./_index.module.scss";
import PagingButton from "../pagingButton";

export interface BackButtonProps {
  disabled?: boolean;
  onClick: () => void;
}

const BackButton: FC<BackButtonProps> = ({ disabled = false, onClick }) => (
  <PagingButton onClick={onClick} disabled={disabled}>
    <svg
      className={styles.BackButton}
      width="43"
      height="49"
      viewBox="0 0 43 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_4666_67233)">
        <path
          className={styles.path}
          d="M4.99999 18.768C3.66666 19.5378 3.66667 21.4623 5 22.2321L35.75 39.9856C37.0833 40.7554 38.75 39.7931 38.75 38.2535L38.75 2.74648C38.75 1.20688 37.0833 0.244626 35.75 1.01443L4.99999 18.768Z"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4666_67233"
          x="0"
          y="0.742188"
          width="42.75"
          height="47.5156"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.72 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4666_67233"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4666_67233"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </PagingButton>
);

export default BackButton;
