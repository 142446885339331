import React, { FC, ReactNode } from "react";
import styles from "features/quiz/components/layouts/bottomAppBar/_index.module.scss";

export interface BottomAppBarProps {
  currentQuizNumber: number;
  totalQuizCount: number;
  centerItem?: ReactNode;
  rightItem?: ReactNode;
  leftItem?: ReactNode;
}

const BottomAppBar: FC<BottomAppBarProps> = ({
  currentQuizNumber,
  totalQuizCount,
  centerItem,
  rightItem,
  leftItem,
}) => (
  <div className={styles.BottomAppBar}>
    <div className={styles.row}>
      <div className={styles.item}>{leftItem && leftItem}</div>

      <div className={styles.item}>{centerItem && centerItem}</div>

      <div className={styles.item}>{rightItem && rightItem}</div>
    </div>

    <span className={styles.progress}>
      {currentQuizNumber}問目/{totalQuizCount}問
    </span>
  </div>
);

export default BottomAppBar;
