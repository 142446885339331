import React, { FC } from "react";
import AbsolutelyPositionedBox from "components/absolutelyPositionedBox";
import classNames from "classnames";
import styles from "./_index.module.scss";

export interface CategoryBoxProps {
  name: string;
  imageUrl: string;
  count: number;
  rocketBox: string;
}

const CategoryBox: FC<CategoryBoxProps> = ({
  name,
  imageUrl,
  count,
  rocketBox,
}) => {
  const nameClassName = classNames(styles.name, {
    [styles.longName]: name.length > 4,
  });
  const countClassName = count === 0 ? styles.countZero : styles.count;

  return (
    <div className={styles.CategoryBox}>
      <img src={rocketBox} alt="rocket-box" width="464px" height="200px" />
      <AbsolutelyPositionedBox top="52px" left="55px">
        <div className={styles.circle}>
          <img src={imageUrl} alt={name} width="71px" height="71px" />
        </div>
      </AbsolutelyPositionedBox>
      <AbsolutelyPositionedBox top="48px" left="175px">
        <div className={nameClassName}>{name}</div>
      </AbsolutelyPositionedBox>
      <AbsolutelyPositionedBox bottom="163px" left="340px">
        <div className={countClassName}>
          {count}
          <span className={styles.countTerm}>回目</span>
        </div>
      </AbsolutelyPositionedBox>
    </div>
  );
};
export default CategoryBox;
