import React, { FC, useMemo } from "react";
import BottomAppBar from "features/quiz/components/layouts/bottomAppBar";
import LabeledIconButton from "features/quiz/components/labeledIconButton";
import pause from "features/quiz/assets/pause.svg";
import next from "features/quiz/assets/next.svg";

export interface AnswerExplanationBottomAppBarProps {
  currentQuizNumber: number;
  totalQuizCount: number;
  onPauseClick: () => void;
  onNextClick: () => void;
}

const AnswerExplanationBottomAppBar: FC<AnswerExplanationBottomAppBarProps> = ({
  currentQuizNumber,
  totalQuizCount,
  onPauseClick,
  onNextClick,
}) => {
  const isLastQuiz = useMemo(
    () => currentQuizNumber === totalQuizCount,
    [currentQuizNumber, totalQuizCount]
  );

  return (
    <BottomAppBar
      currentQuizNumber={currentQuizNumber}
      totalQuizCount={totalQuizCount}
      navigationCenterItem={
        <LabeledIconButton
          icon={<img src={pause} alt="" />}
          label="ひとやすみ"
          onClick={onPauseClick}
        />
      }
      navigationRightItem={
        <LabeledIconButton
          icon={<img src={next} alt="" />}
          label={isLastQuiz ? "おわり" : "つぎへ"}
          onClick={onNextClick}
        />
      }
    />
  );
};

export default AnswerExplanationBottomAppBar;
