import AlertDialog from "components/alertDialog";
import AlertDialogButton from "components/alertDialogButton";
import React from "react";
import Ruby from "../ruby";

type Props = {
  onRetry: () => void;
};

const AlertDialogWithRetry: React.FC<Props> = ({ onRetry }) => (
  <AlertDialog
    message={
      <>
        <Ruby text="通信" ruby="つうしん" />
        エラーが
        <Ruby text="起" ruby="お" />
        きました
        <br />
        もう
        <Ruby text="一度" ruby="いちど" />
        <Ruby text="試" ruby="ため" />
        してください
      </>
    }
  >
    <AlertDialogButton color="red" onClick={onRetry}>
      もう
      <Ruby text="一度" ruby="いちど" />
      <Ruby text="試" ruby="ため" />す
    </AlertDialogButton>
  </AlertDialog>
);

export default AlertDialogWithRetry;
