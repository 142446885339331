import axios, { AxiosError } from "axios";
import { getStudentQueryKey } from "hooks/queries/queryKeys";
import { BASE_URL, UNAUTHORIZED_STATUS_CODE } from "./config";
import queryClient from "./queryClient";

const apiClient = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

apiClient.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (axios.isAxiosError(error) && error.response?.status !== undefined) {
      if (error.response.status === UNAUTHORIZED_STATUS_CODE) {
        queryClient.invalidateQueries({ queryKey: getStudentQueryKey() });
      }
    }
    return Promise.reject(error);
  }
);

export default apiClient;
