import React, { FC } from "react";
import { Link } from "react-router-dom";
import styles from "./_index.module.scss";

export interface LabeledImgLinkProps {
  imgSrc: string;
  label: string;
  link: string;
  top: string;
  left: string;
  imgWidth?: string;
  imgHeight?: string;
  fontSize?: string;
}

const LabeledImgLink: FC<LabeledImgLinkProps> = ({
  imgSrc,
  label,
  link,
  top,
  left,
  imgWidth,
  imgHeight,
  fontSize,
}) => (
  <div className={styles.LabeledIconButton} style={{ top, left }}>
    <Link className={styles.link} to={link} replace>
      <img
        src={imgSrc}
        alt={label}
        style={{ width: imgWidth, height: imgHeight }}
      />
      <span className={styles.label} style={{ fontSize: fontSize ?? "40px" }}>
        {" "}
        {label}
      </span>
    </Link>
  </div>
);

export default LabeledImgLink;
