import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import useCorrectAnswerRate from "hooks/queries/useCorrectAnswerRate";
import useLoggedInDates from "hooks/queries/useLoggedInDates";
import Spinner from "components/spinner";
import LoadingError from "components/loadingError";
import styles from "./_index.module.scss";
import TopAppBar from "./components/topAppBar";
import CalendarHeader from "./components/calendarHeader";
import DayOfWeek from "./components/dayOfWeek";
import CalendarDate from "./components/calendarDate";
import Spacer from "../../components/spacer";
import CalendarFooter from "./components/calendarFooter";
import useDate from "./hooks/useDate";

const LoginCalendar: FC = () => {
  const navigate = useNavigate();
  const [date, { goBackToPreviousMonth, goForwardToNextMonth }] = useDate(
    new Date()
  );
  const correctAnswerRate = useCorrectAnswerRate({
    year: date.getFullYear(),
    month: date.getMonth() + 1,
  });
  const loggedInDates = useLoggedInDates({
    year: date.getFullYear(),
    month: date.getMonth() + 1,
  });
  const today = new Date();
  const onRetry = () => {
    correctAnswerRate.refetch();
    loggedInDates.refetch();
  };

  if (correctAnswerRate.isLoading || loggedInDates.isLoading) {
    return <Spinner />;
  }

  if (
    correctAnswerRate.isError ||
    correctAnswerRate.data === undefined ||
    loggedInDates.isError ||
    loggedInDates.data === undefined
  ) {
    return <LoadingError onRetry={onRetry} />;
  }

  return (
    <div className={styles.LoginCalendar}>
      <TopAppBar
        onHomeIconClick={() => navigate("/", { replace: true })}
        onPreviousClick={() => navigate("/rocket", { replace: true })}
        onRocketClick={() => navigate("/rocket", { replace: true })}
      />
      <CalendarHeader
        date={date}
        today={today}
        onPreviousMonthClick={goBackToPreviousMonth}
        onNextMonthClick={goForwardToNextMonth}
      />
      <div className={styles.calendar}>
        <DayOfWeek />
        <Spacer size="10.86px" />
        <CalendarDate
          date={date}
          today={today}
          loggedInDates={loggedInDates.data}
        />
      </div>
      <CalendarFooter
        correctAnswerRate={correctAnswerRate.data}
        numberOfLoggedInDates={loggedInDates.data.length}
      />
    </div>
  );
};

export default LoginCalendar;
