import classNames from "classnames";
import RubiedText from "components/rubiedText";
import useCountRubiedText from "features/quiz/hooks/useCountRubiedText";
import SelectionQuizA from "models/selectionQuizA/selectionQuizA";
import SelectionQuizAAnswer from "models/selectionQuizA/selectionQuizAAnswer";
import SelectionQuizAChoice from "models/selectionQuizA/selectionQuizAChoice";
import React, { FC, useMemo } from "react";
import styles from "./_index.module.scss";

export interface ChoiceButtonProps {
  choice: SelectionQuizAChoice;
  isSelected: boolean;
  fontStyle: string;
  onClick: () => void;
}

const ChoiceButton: FC<ChoiceButtonProps> = ({
  choice,
  isSelected,
  fontStyle,
  onClick,
}) => {
  const classes = classNames({
    [styles["button-selected"]]: isSelected,
  });

  return (
    <button type="button" className={classes} onClick={onClick}>
      <RubiedText className={fontStyle} rubiedTextHtml={choice.name} />
    </button>
  );
};

export interface QuizFmtSelectionAProps {
  quiz: SelectionQuizA;
  answer?: SelectionQuizAAnswer;
  onAnswerChanged: (answer: SelectionQuizAAnswer) => void;
}

const QuizFmtSelectionA: FC<QuizFmtSelectionAProps> = ({
  quiz,
  answer,
  onAnswerChanged,
}) => {
  const maxLength = Math.max(
    ...quiz.choices.map((element) => {
      const length = useCountRubiedText(element.name);
      return length;
    })
  );

  const fontStyle = useMemo(() => {
    const isLongText = maxLength > 6;
    return classNames(isLongText ? styles["small-font"] : styles["large-font"]);
  }, [quiz]);

  return (
    <div
      className={styles.QuizFmtSelectionA}
      style={{ backgroundImage: `url(${quiz.imageUrl})` }}
    >
      <div className={styles["choice-zone"]}>
        {quiz.choices.map((choice) => (
          <ChoiceButton
            key={choice.id}
            choice={choice}
            isSelected={choice.id === answer?.choiceId}
            fontStyle={fontStyle}
            onClick={() => onAnswerChanged(new SelectionQuizAAnswer(choice.id))}
          />
        ))}
      </div>
    </div>
  );
};

export default QuizFmtSelectionA;
