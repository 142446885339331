import React, { FC } from "react";
import TextInputQuiz from "models/textInputQuiz/textInputQuiz";
import TextInputQuizAnswer from "models/textInputQuiz/textInputQuizAnswer";
import classNames from "classnames";
import styles from "./_index.module.scss";

export interface QuizFmtTextProps {
  quiz: TextInputQuiz;
  answer?: TextInputQuizAnswer;
  onAnswerChanged: (answer: TextInputQuizAnswer) => void;
}

const QuizFmtText: FC<QuizFmtTextProps> = ({
  quiz,
  answer,
  onAnswerChanged,
}) => (
  <div className={styles.QuizFmtText}>
    <div className={styles["img-frame"]}>
      <img className={styles["question-img"]} alt="" src={quiz.imageUrl} />
    </div>

    <div className={styles.form}>
      <div className={styles["form-column"]}>
        <input
          type="text"
          className={styles["answer-input"]}
          id="answer"
          name="answer"
          value={answer?.text ?? ""}
          readOnly
        />
        <div className={styles.keys}>
          <div className={styles["character-keys"]}>
            {quiz.characters.map((char) => (
              <button
                type="button"
                key={char}
                className={styles.key}
                onClick={() => {
                  const newText = (answer?.text ?? "") + char;
                  onAnswerChanged(new TextInputQuizAnswer(newText));
                }}
              >
                {char}
              </button>
            ))}
          </div>

          <button
            className={classNames(styles.key, styles["delete-key"])}
            type="button"
            onClick={() => {
              const text = answer?.text ?? "";
              const newText = text.slice(0, Math.max(0, text.length - 1));

              onAnswerChanged(new TextInputQuizAnswer(newText));
            }}
          >
            1文字けす
          </button>
        </div>
      </div>
    </div>
  </div>
);
export default QuizFmtText;
