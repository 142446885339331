import React, { FC, ReactNode } from "react";
import styles from "./_index.module.scss";

export interface ImageButtonProps {
  children: ReactNode;
  onClick: () => void;
}

const ImageButton: FC<ImageButtonProps> = ({ children, onClick }) => (
  <button className={styles.ImageButton} type="button" onClick={onClick}>
    {children}
  </button>
);

export default ImageButton;
