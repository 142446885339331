import React, { FC } from "react";
import styles from "features/loginCalendar/components/dayOfWeek/_index.module.scss";
import classNames from "classnames";

const DayOfWeek: FC = () => {
  const dayOfWeek = ["日", "月", "火", "水", "木", "金", "土"];
  const dayOfWeekElements = dayOfWeek.map((day) => {
    const classes = classNames(styles.name, {
      [styles.saturday]: day === "土",
      [styles.sunday]: day === "日",
    });
    return (
      <div className={styles.box} key={day}>
        <span className={classes}>{day}</span>
      </div>
    );
  });
  return <div className={styles.DayOfWeek}>{dayOfWeekElements}</div>;
};

export default DayOfWeek;
