import React, { FC, useState } from "react";
import AlertDialog from "components/alertDialog";
import AlertDialogButton from "components/alertDialogButton";
import Ruby from "../../../../components/ruby";

export interface MenuDialogProps {
  onResume: () => void;
  onNavigateToHome: () => void;
  onReset: () => void;
}

type Content = "pause" | "confirm_abort";

const MenuDialog: FC<MenuDialogProps> = ({
  onResume,
  onNavigateToHome,
  onReset,
}) => {
  const [content, setContent] = useState<Content>("pause");

  if (content === "pause") {
    return (
      <AlertDialog
        message={<Ruby text="一時停止中" ruby="いちじていしちゅう" />}
      >
        <AlertDialogButton color="red" onClick={onResume}>
          <Ruby text="再開" ruby="さいかい" />
          する
        </AlertDialogButton>

        <AlertDialogButton
          color="blue"
          onClick={() => setContent("confirm_abort")}
        >
          ホームに
          <Ruby text="戻" ruby="もど" />る
        </AlertDialogButton>

        <AlertDialogButton color="blue" onClick={onReset}>
          <Ruby text="最初" ruby="さいしょ" />
          からやり
          <Ruby text="直" ruby="なお" />す
        </AlertDialogButton>
      </AlertDialog>
    );
  }

  return (
    <AlertDialog
      message={
        <>
          データは
          <Ruby text="保存" ruby="ほぞん" />
          されません
          <br />
          <Ruby text="本当" ruby="ほんとう" />
          に
          <Ruby text="中断" ruby="ちゅうだん" />
          しますか？
        </>
      }
    >
      <AlertDialogButton color="red" onClick={onResume}>
        <Ruby text="中断" ruby="ちゅうだん" />
        しない
      </AlertDialogButton>

      <AlertDialogButton color="black" onClick={onNavigateToHome}>
        <Ruby text="中断" ruby="ちゅうだん" />
        する
      </AlertDialogButton>
    </AlertDialog>
  );
};

export default MenuDialog;
