import React, { FC } from "react";
import styles from "./_index.module.scss";
import CalendarIcon from "../../assets/calendar.svg";

export interface LoginCalendarButtonProps {
  onClick: () => void;
}
const LoginCalendarButton: FC<LoginCalendarButtonProps> = ({ onClick }) => (
  <button
    className={styles.LoginCalendarButton}
    type="button"
    onClick={onClick}
  >
    <span>ログインカレンダー</span>
    <img src={CalendarIcon} alt="calendar" width="118px" height="117px" />
  </button>
);

export default LoginCalendarButton;
