import Quiz from "models/quiz";
import SelectionQuizCChoice from "models/selectionQuizC/selectionQuizCChoice";

export default class SelectionQuizC implements Quiz {
  constructor(
    public readonly id: string,
    public readonly sentence: string,
    public readonly correctAnswerImageUrl: string,
    public readonly wrongAnswerImageUrl: string,
    public readonly timeLimitSeconds: number,
    public readonly consecutiveCorrectAnswerCount: number,
    public readonly imageUrl: string,
    public readonly choices: SelectionQuizCChoice[],
    public readonly correctChoiceId: string
  ) {}

  get quizAssetUrls(): string[] {
    return this.choices.map((choice) => choice.imageUrl).concat(this.imageUrl);
  }

  copy(args: { consecutiveCorrectAnswerCount: number }): SelectionQuizC {
    return new SelectionQuizC(
      this.id,
      this.sentence,
      this.correctAnswerImageUrl,
      this.wrongAnswerImageUrl,
      this.timeLimitSeconds,
      args.consecutiveCorrectAnswerCount,
      this.imageUrl,
      this.choices,
      this.correctChoiceId
    );
  }
}
