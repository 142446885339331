import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import QuizLayout from "features/quiz/components/layouts/quizLayout";
import QuizFmtSelectionA from "features/quiz/components/quizFmtSelectionA";
import { StudentAnswerType } from "models/studentAnswerType";
import SelectionQuizAStudentAnswer from "models/selectionQuizA/selectionQuizAStudentAnswer";
import { QuizAnswerType } from "models/quizAnswerType";
import { QuizType } from "models/quizType";
import useTimer from "features/quiz/hooks/useTimer";
import SelectionQuizBStudentAnswer from "models/selectionQuizB/selectionQuizBStudentAnswer";
import QuizFmtSelectionB from "features/quiz/components/quizFmtSelectionB";
import SelectionQuizCStudentAnswer from "models/selectionQuizC/selectionQuizCStudentAnswer";
import QuizFmtSelectionC from "features/quiz/components/quizFmtSelectionC";
import RearrangementQuizStudentAnswer from "models/rearrangementQuiz/rearrangementQuizStudentAnswer";
import QuizFmtRearrangement from "features/quiz/components/quizFmtRearrangement";
import QuizFmtText from "features/quiz/components/quizFmtText";
import useImages from "../../../../hooks/useImages";
import Spinner from "../../../../components/spinner";

export interface QuizContentProps {
  currentQuizNumber: number;
  totalQuizCount: number;
  studentAnswer: StudentAnswerType;
  isPausing: boolean;
  onNavigationIconClick: () => void;
  onAnswerChanged: (quiz: QuizType, answer: QuizAnswerType) => void;
  onConfirmAnswer: (quiz: QuizType, elapsedTimeMillis: number) => void;
  onPause: (quiz: QuizType, elapsedTimeMillis: number) => void;
}

const QuizContent: FC<QuizContentProps> = ({
  currentQuizNumber,
  totalQuizCount,
  studentAnswer,
  isPausing,
  onNavigationIconClick,
  onAnswerChanged,
  onConfirmAnswer,
  onPause,
}) => {
  const [isTimeUpOverlayVisible, setIsTimeUpOverlayVisible] = useState(false);

  const initialTimeLeftMillis = useMemo(
    () =>
      studentAnswer.quiz.timeLimitSeconds * 1_000 -
      (studentAnswer.elapsedTimeMillis ?? 0),
    [studentAnswer]
  );

  const onExpire = useCallback(() => {
    setIsTimeUpOverlayVisible(true);
    setTimeout(() => {
      onConfirmAnswer(
        studentAnswer.quiz,
        studentAnswer.quiz.timeLimitSeconds * 1_000
      );
      setIsTimeUpOverlayVisible(false);
    }, 2_000);
  }, [studentAnswer]);

  const { timeLeftMillis, startTimer, pauseTimer } = useTimer(
    studentAnswer.quiz.id,
    initialTimeLeftMillis,
    onExpire
  );

  const quizAssetUrls = useMemo(
    () => studentAnswer.quiz.quizAssetUrls,
    [studentAnswer]
  );
  const { isLoading: isImagesLoading } = useImages(quizAssetUrls);

  const quizAnswerAssetUrls = useMemo(
    () => [
      studentAnswer.quiz.correctAnswerImageUrl,
      studentAnswer.quiz.wrongAnswerImageUrl,
    ],
    [studentAnswer]
  );
  useImages(quizAnswerAssetUrls);

  useEffect(() => {
    if (!isImagesLoading && !isPausing) {
      startTimer();
    }
  }, [studentAnswer.quiz.id, isPausing, isImagesLoading]);

  const child = useMemo(() => {
    if (isImagesLoading) {
      return <Spinner />;
    }

    if (studentAnswer instanceof SelectionQuizAStudentAnswer) {
      return (
        <QuizFmtSelectionA
          quiz={studentAnswer.quiz}
          answer={studentAnswer.answer}
          onAnswerChanged={(answer) =>
            onAnswerChanged(studentAnswer.quiz, answer)
          }
        />
      );
    }

    if (studentAnswer instanceof SelectionQuizBStudentAnswer) {
      return (
        <QuizFmtSelectionB
          quiz={studentAnswer.quiz}
          answer={studentAnswer.answer}
          onAnswerChanged={(answer) => {
            onAnswerChanged(studentAnswer.quiz, answer);
          }}
        />
      );
    }

    if (studentAnswer instanceof SelectionQuizCStudentAnswer) {
      return (
        <QuizFmtSelectionC
          quiz={studentAnswer.quiz}
          answer={studentAnswer.answer}
          onAnswerChanged={(answer) => {
            onAnswerChanged(studentAnswer.quiz, answer);
          }}
        />
      );
    }

    if (studentAnswer instanceof RearrangementQuizStudentAnswer) {
      return (
        <QuizFmtRearrangement
          quiz={studentAnswer.quiz}
          answer={studentAnswer.answer}
          onAnswerChanged={(answer) => {
            onAnswerChanged(studentAnswer.quiz, answer);
          }}
        />
      );
    }

    return (
      <QuizFmtText
        quiz={studentAnswer.quiz}
        answer={studentAnswer.answer}
        onAnswerChanged={(answer) => {
          onAnswerChanged(studentAnswer.quiz, answer);
        }}
      />
    );
  }, [studentAnswer, isImagesLoading]);

  return (
    <QuizLayout
      currentQuizNumber={currentQuizNumber}
      totalQuizCount={totalQuizCount}
      sentence={studentAnswer.quiz.sentence}
      timeLeftMillis={timeLeftMillis}
      consecutiveCorrectAnswerCount={
        studentAnswer.quiz.consecutiveCorrectAnswerCount
      }
      isAnswered={studentAnswer.isAnswered}
      isTimeUpOverlayVisible={isTimeUpOverlayVisible}
      onNavigationIconClick={onNavigationIconClick}
      onPauseClick={() => {
        const elapsedTimeMillis = pauseTimer();
        onPause(studentAnswer.quiz, elapsedTimeMillis);
      }}
      onNextClick={() => {
        const elapsedTimeMillis = pauseTimer();
        onConfirmAnswer(studentAnswer.quiz, elapsedTimeMillis);
      }}
    >
      {child}
    </QuizLayout>
  );
};

export default QuizContent;
