import React, { FC } from "react";
import { SubjectStyle } from "features/dojo/models/subjectStyle";
import styles from "./_index.module.scss";
import Ruby from "../../../../components/ruby";

export interface OverlayProps {
  subjectStyle: SubjectStyle;
  handleClick: () => void;
}

export const Overlay: FC<OverlayProps> = ({ subjectStyle, handleClick }) => (
  <div className={styles.overlay}>
    <div
      className={styles.rectangle}
      style={{ background: subjectStyle.background }}
    >
      <div className={styles.text}>
        <Ruby
          text={subjectStyle.subject.label}
          ruby={subjectStyle.subject.labelRubi}
        />
      </div>
      <img
        className={styles.img}
        src={subjectStyle.image}
        alt={subjectStyle.subject.label}
      />
    </div>
    <button className={styles.button} type="button" onClick={handleClick}>
      けってい
    </button>
  </div>
);
