import React, { FC } from "react";
import RubiedText from "components/rubiedText";
import home from "features/training/assets/home.svg";
import LabeledIconButton from "../labeledIconButton";
import styles from "./_index.module.scss";

export interface TopAppBarProps {
  title: string;
  onNavigationIconClick: () => void;
}

export const TopAppBar: FC<TopAppBarProps> = ({
  title,
  onNavigationIconClick,
}) => (
  <div className={styles.TopAppBar}>
    <LabeledIconButton
      icon={<img src={home} alt="" />}
      label="ホーム"
      onClick={onNavigationIconClick}
    />
    <RubiedText className={styles.title} rubiedTextHtml={title} />
  </div>
);
