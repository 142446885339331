import { useMutation } from "react-query";
import apiClient from "services/apiClient";
import { QuizCountSetting } from "../../models/quizCountSetting";
import QuizCountSettings from "../../models/quizCountSettings";
import QuizCountSettingsResponse from "../../services/models/quizCountSettingsResponse";
import QuizCountSettingsSaveRequest from "../../services/models/quizCountSettingsSaveRequest";
import { mapToQuizCountSettings } from "../../services/mappers/quizCountSettings";
import queryClient from "../../services/queryClient";
import { getQuizCountSettingsKey } from "./queryKeys";

const postQuizCountSetting = async (
  setting: QuizCountSetting
): Promise<QuizCountSettings> => {
  const request: QuizCountSettingsSaveRequest = {
    quizSetting: {
      maxCount: (() => {
        switch (setting.value) {
          case "ten":
            return "QUIZ_SETTING_MAX_COUNT_TEN";
          case "twenty":
            return "QUIZ_SETTING_MAX_COUNT_TWENTY";
          default:
            return "QUIZ_SETTING_MAX_COUNT_ALL";
        }
      })(),
    },
  };

  const res = await apiClient.put<QuizCountSettingsResponse>(
    "/v1/settings",
    request
  );

  return mapToQuizCountSettings(res.data);
};

const useSaveQuizCountSetting = () =>
  useMutation({
    mutationFn: async (setting: QuizCountSetting) =>
      postQuizCountSetting(setting),
    onSuccess: (settings) => {
      queryClient.setQueryData(getQuizCountSettingsKey(), settings);
    },
  });

export default useSaveQuizCountSetting;
