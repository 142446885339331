import StudentAnswer from "models/studentAnswer";
import SelectionQuizC from "models/selectionQuizC/selectionQuizC";
import SelectionQuizCAnswer from "models/selectionQuizC/selectionQuizCAnswer";

export default class SelectionQuizCStudentAnswer
  implements StudentAnswer<SelectionQuizC>
{
  constructor(
    public readonly quiz: SelectionQuizC,
    public readonly answer?: SelectionQuizCAnswer,
    public readonly elapsedTimeMillis?: number
  ) {}

  get isAnswered(): boolean {
    return this.answer !== undefined;
  }

  get isCorrect(): boolean {
    return this.quiz.correctChoiceId === this.answer?.choiceId;
  }

  // eslint-disable-next-line class-methods-use-this
  get answerText(): string | undefined {
    return undefined;
  }
}
