import React, { FC } from "react";
import styles from "./_index.module.scss";
import { MonthlyExamPreparationSubject } from "../../../../models/monthlyExamPreparationSubject";

export interface ToggleSwitchProps {
  isCheckedFirst: boolean;
  onClick: () => void;
}

const ToggleSwitch: FC<ToggleSwitchProps> = ({ isCheckedFirst, onClick }) => (
  <div className={styles.ToggleSwitch}>
    <button
      className={styles["interaction-frame"]}
      type="button"
      aria-label="toggle-subject"
      onClick={onClick}
    />

    <div
      className={styles.handle}
      style={{ transform: `translate(${isCheckedFirst ? "0" : "100%"}, 0)` }}
    />

    <div className={styles.label}>
      <div className={styles.science}>
        {MonthlyExamPreparationSubject.Science.label}
      </div>
      <div className={styles["social-studies"]}>
        {MonthlyExamPreparationSubject.SocialStudies.label}
      </div>
    </div>
  </div>
);

export default ToggleSwitch;
