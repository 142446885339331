import React, { FC } from "react";
import styles from "./_index.module.scss";
import PagingButton from "../pagingButton";

export interface ForwardButtonProps {
  disabled?: boolean;
  onClick: () => void;
}

const ForwardButton: FC<ForwardButtonProps> = ({
  disabled = false,
  onClick,
}) => (
  <PagingButton onClick={onClick} disabled={disabled}>
    <svg
      className={styles.ForwardButton}
      width="43"
      height="49"
      viewBox="0 0 43 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_4666_6999)">
        <path
          className={styles.path}
          d="M38 18.768C39.3333 19.5378 39.3333 21.4623 38 22.2321L7.25 39.9856C5.91667 40.7554 4.25 39.7931 4.25 38.2535L4.25 2.74648C4.25 1.20688 5.91667 0.244626 7.25 1.01443L38 18.768Z"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4666_6999"
          x="0.25"
          y="0.742188"
          width="42.75"
          height="47.5156"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.72 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4666_6999"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4666_6999"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </PagingButton>
);

export default ForwardButton;
