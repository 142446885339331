import React, { FC } from "react";
import styles from "./_index.module.scss";

export interface ImgButtonProps {
  imgSrc: string;
  label: string;
  width: string;
  handleClick: () => void;
}

const ImgButton: FC<ImgButtonProps> = ({
  imgSrc,
  label,
  width,
  handleClick,
}) => (
  <button className={styles.ImgButton} onClick={handleClick} type="button">
    <img src={imgSrc} alt={label} style={{ width }} />
  </button>
);

export default ImgButton;
