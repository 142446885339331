import React, { FC } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import RubiedText from "components/rubiedText";
import { NavArgs } from "features/training";
import { Subject } from "models/subject";
import styles from "./_index.module.scss";
import penguinBlue from "./assets/penguin_blue.svg";
import penguinPink from "./assets/penguin_pink.svg";
import Ruby from "../../components/ruby";

export interface TrainingFinishedProps {
  subject: Subject;
}

const TrainingFinished: FC<TrainingFinishedProps> = ({ subject }) => {
  const location = useLocation();
  const navArgs = location.state as NavArgs;
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const onNextClick = () =>
    navigate(`/quiz/subject/${subject.name}/unlock`, {
      state: {
        id: Number(categoryId),
        name: navArgs.name,
      },
      replace: true,
    });
  const onHomeClick = () => navigate("/", { replace: true });
  const onAnotherClick = () =>
    navigate(`/training/subject/${subject.name}`, { replace: true });

  if (navArgs.name === undefined || categoryId === undefined) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className={styles.FinishedPage}>
      <div className={styles.backRec} />
      <div className={styles.frontRec1}>
        <span className={styles.text1}>おつかれさま！</span>
        <div className={styles.frontRec2}>
          <span className={styles.text2}>
            <RubiedText rubiedTextHtml={navArgs.name} />
            のまなぶモードは
            <br />
            これで
            <Ruby text="終" ruby="お" />
            わりだよ！
            <br />
            <Ruby text="楽" ruby="たの" />
            しかったね！
            <br />
          </span>
          <span className={styles.text3}>
            <Ruby text="何回" ruby="なんかい" />
            でも
            <Ruby text="遊" ruby="あそ" />
            べるから
            <br />
            また
            <Ruby text="勉強" ruby="べんきょう" />
            しようね！
          </span>
        </div>
      </div>
      <img
        className={styles.penguinBlue}
        src={penguinBlue}
        alt="ペンギン（青）"
      />
      <img
        className={styles.penguinPink}
        src={penguinPink}
        alt="ペンギン（ピンク）"
      />
      {navArgs.count === 0 ? (
        <button
          className={styles.nextButton}
          type="button"
          onClick={onNextClick}
        >
          つぎへ
        </button>
      ) : (
        <div>
          <button
            className={styles.homeButton}
            type="button"
            onClick={onHomeClick}
          >
            <Ruby text="終了" ruby="しゅうりょう" />
            してホームに
            <Ruby text="戻" ruby="もど" />る
          </button>
          <button
            className={styles.anotherButton}
            type="button"
            onClick={onAnotherClick}
          >
            ほかの
            <Ruby text="単元" ruby="たんげん" />
            もやる
          </button>
        </div>
      )}
    </div>
  );
};

export default TrainingFinished;
