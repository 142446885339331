import React, { FC, ReactNode } from "react";
import styles from "./_index.module.scss";

export interface PagingButtonProps {
  children: ReactNode;
  onClick: () => void;
  disabled?: boolean;
}

const PagingButton: FC<PagingButtonProps> = ({
  children,
  onClick,
  disabled = false,
}) => (
  <button
    className={styles.PagingButton}
    type="button"
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

export default PagingButton;
