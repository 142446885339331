import React, { FC, useMemo } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { UiState } from "./models/uiState";
import styles from "./_index.module.scss";
import {
  mapNavQuizTypeToModel,
  mapQuizTypeToNavModel,
  NavQuizType,
} from "../../navigation/models/navQuizType";
import Normal from "./models/normal";
import { MonthlyExamPreparationSubject } from "../../models/monthlyExamPreparationSubject";
import ClearedNavigationActions from "../quizFinished/components/cleardNavigationActions";
import QuizCleared from "../quizFinished/components/quizCleared";
import NormalResult from "../quizFinished/components/normalResult";
import NormalNavigationActions from "../quizFinished/components/normalNavigationActions";

export interface MonthlyExamPreparationFinishedState {
  incorrectQuizzes: NavQuizType[];
  correctAnswerCount?: number;
  totalQuizCount?: number;
}

const extractUiState = (
  state: MonthlyExamPreparationFinishedState
): UiState | undefined => {
  if (!state) {
    return undefined;
  }

  const incorrectQuizzes = state.incorrectQuizzes.map(mapNavQuizTypeToModel);

  if (
    state.correctAnswerCount !== undefined &&
    state.totalQuizCount !== undefined &&
    state.correctAnswerCount <= state.totalQuizCount
  ) {
    return new Normal(
      incorrectQuizzes,
      state.correctAnswerCount,
      state.totalQuizCount
    );
  }

  return "cleared";
};

export interface MonthlyExamPreparationFinishedProps {
  subject: MonthlyExamPreparationSubject;
}

const MonthlyExamPreparationFinished: FC<
  MonthlyExamPreparationFinishedProps
> = ({ subject }) => {
  const { id } = useParams();
  const preparationNumber = Number(id);

  const { state } = useLocation();
  const uiState = useMemo(
    () => extractUiState(state as MonthlyExamPreparationFinishedState),
    [state]
  );

  const navigate = useNavigate();

  if (uiState === undefined) {
    return <Navigate to="/" replace />;
  }

  const onHomeClick = () => navigate("/", { replace: true });

  return (
    <div className={styles.MonthlyExamPreparationFinished}>
      {uiState instanceof Normal ? (
        <NormalResult
          correctAnswerCount={uiState.correctAnswerCount}
          totalQuizCount={uiState.totalQuizCount}
        >
          <NormalNavigationActions
            reviewDisabled={uiState.incorrectQuizzes.length === 0}
            onHomeClick={onHomeClick}
            onRetryClick={() => {
              navigate(
                `/monthly-exam-preparation/${subject.name}/${preparationNumber}`,
                {
                  replace: true,
                }
              );
            }}
            onReviewClick={() => {
              navigate(
                `/monthly-exam-preparation/${subject.name}/${preparationNumber}`,
                {
                  replace: true,
                  state: {
                    incorrectQuizzes: uiState.incorrectQuizzes.map(
                      mapQuizTypeToNavModel
                    ),
                  },
                }
              );
            }}
          />
        </NormalResult>
      ) : (
        <QuizCleared text={`第 ${preparationNumber} 回をクリアしたよ！`}>
          <ClearedNavigationActions onHomeClick={onHomeClick} />
        </QuizCleared>
      )}
    </div>
  );
};

export default MonthlyExamPreparationFinished;
