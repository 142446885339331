import React, { FC } from "react";
import home from "assets/home.svg";
import previous from "../../assets/previous.svg";
import rocket from "../../assets/rocket.svg";
import styles from "./_index.module.scss";
import IconButton from "../iconButton";

export interface TopAppBarProps {
  onHomeIconClick: () => void;
  onPreviousClick: () => void;
  onRocketClick: () => void;
}
const TopAppBar: FC<TopAppBarProps> = ({
  onHomeIconClick,
  onPreviousClick,
  onRocketClick,
}) => (
  <div className={styles.TopAppBar}>
    <div className={styles.previousButton}>
      <IconButton onClick={onPreviousClick}>
        <img src={previous} alt="戻る" />
      </IconButton>
    </div>
    <div className={styles.title}>
      <span className={styles.title}>ログインカレンダー</span>
    </div>
    <div className={styles.navigationButtonWrapper}>
      <div className={styles.navigationButton}>
        <IconButton onClick={onRocketClick}>
          <img src={rocket} alt="ロケット" />
        </IconButton>
        <IconButton onClick={onHomeIconClick}>
          <img src={home} alt="ホーム" />
        </IconButton>
      </div>
    </div>
  </div>
);
export default TopAppBar;
