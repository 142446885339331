import React, { FC } from "react";
import styles from "./_index.module.scss";
import Ruby from "../../../../components/ruby";

export interface QuizCategoryModalProps {
  quiz: () => void;
  other: () => void;
  home: () => void;
}

const QuizCategoryModal: FC<QuizCategoryModalProps> = ({
  quiz,
  other,
  home,
}) => (
  <div className={styles.QuizCategoryModal}>
    <button className={styles.button} type="button" onClick={quiz}>
      しゅぎょうモードをやってみる
    </button>
    <button className={styles.button} type="button" onClick={other}>
      ほかの
      <Ruby text="単元" ruby="たんげん" />
      をまなぶ
    </button>
    <button className={styles.button} type="button" onClick={home}>
      ホームにもどる
    </button>
  </div>
);

export default QuizCategoryModal;
