import PageContainer from "components/layouts/pageContainer";
import PrivateRoute from "components/privateRoute";
import Dojo from "features/dojo";
import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import QuizCategoryListContent from "./features/categorySelection/quizCategoryList";
import TrainingCategoryList from "./features/categorySelection/trainingCategoryList";
import Home from "./features/home";
import HomeRocket from "./features/homeRocket";
import LoginCalendar from "./features/loginCalendar";
import Quiz from "./features/quiz";
import QuizFinished from "./features/quizFinished";
import QuizUnlockAnimation from "./features/quizUnlockAnimation";
import Training from "./features/training";
import TrainingFinished from "./features/trainingFinished";
import { AllSubjects } from "./models/subject";
import Inquiry from "./features/inquiry";
import MonthlyExamPreparationSelection from "./features/monthlyExamPreparationSelection";
import MonthlyExamPreparation from "./features/monthlyExamPreparation";
import { AllMonthlyExamPreparationSubjects } from "./models/monthlyExamPreparationSubject";
import MonthlyExamPreparationFinished from "./features/monthlyExamPreparationFinished";
import DebugQuiz from "./features/debugQuiz";

const App: FC = () => (
  <Routes>
    <Route path="/" element={<PageContainer />}>
      <Route
        index
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />

      <Route
        path="inquiry"
        element={
          <PrivateRoute>
            <Inquiry />
          </PrivateRoute>
        }
      />

      <Route
        path="dojo"
        element={
          <PrivateRoute>
            <Dojo />
          </PrivateRoute>
        }
      />

      {AllSubjects.map((subject) => (
        <Route
          key={subject.name}
          path={`quiz/subject/${subject.name}`}
          element={
            <PrivateRoute>
              <QuizCategoryListContent subject={subject} />
            </PrivateRoute>
          }
        />
      ))}

      {AllSubjects.map((subject) => (
        <Route
          key={subject.name}
          path={`quiz/subject/${subject.name}/category/:categoryId`}
          element={
            <PrivateRoute>
              <Quiz subject={subject} />
            </PrivateRoute>
          }
        />
      ))}

      {AllSubjects.map((subject) => (
        <Route
          key={subject.name}
          path={`quiz/subject/${subject.name}/category/:categoryId/finished`}
          element={
            <PrivateRoute>
              <QuizFinished subject={subject} />
            </PrivateRoute>
          }
        />
      ))}

      {AllSubjects.map((subject) => (
        <Route
          key={subject.name}
          path={`quiz/subject/${subject.name}/unlock`}
          element={
            <PrivateRoute>
              <QuizUnlockAnimation subject={subject} />
            </PrivateRoute>
          }
        />
      ))}

      {AllSubjects.map((subject) => (
        <Route
          key={subject.name}
          path={`training/subject/${subject.name}`}
          element={
            <PrivateRoute>
              <TrainingCategoryList subject={subject} />
            </PrivateRoute>
          }
        />
      ))}

      {AllSubjects.map((subject) => (
        <Route
          key={subject.name}
          path={`training/subject/${subject.name}/category/:categoryId`}
          element={
            <PrivateRoute>
              <Training />
            </PrivateRoute>
          }
        />
      ))}

      {AllSubjects.map((subject) => (
        <Route
          key={subject.name}
          path={`training/subject/${subject.name}/category/:categoryId/finished`}
          element={<TrainingFinished subject={subject} />}
        />
      ))}

      <Route
        path="rocket"
        element={
          <PrivateRoute>
            <HomeRocket />
          </PrivateRoute>
        }
      />
      <Route
        path="rocket/login-calendar"
        element={
          <PrivateRoute>
            <LoginCalendar />
          </PrivateRoute>
        }
      />

      <Route
        path="monthly-exam-preparation"
        element={
          <PrivateRoute>
            <MonthlyExamPreparationSelection />
          </PrivateRoute>
        }
      />

      <Route
        path="debug/quiz/:id"
        element={
          <PrivateRoute>
            <DebugQuiz />
          </PrivateRoute>
        }
      />

      {AllMonthlyExamPreparationSubjects.map((subject) => (
        <Route
          key={subject.name}
          path={`monthly-exam-preparation/${subject.name}/:id`}
          element={
            <PrivateRoute>
              <MonthlyExamPreparation subject={subject} />
            </PrivateRoute>
          }
        />
      ))}

      {AllMonthlyExamPreparationSubjects.map((subject) => (
        <Route
          key={subject.name}
          path={`monthly-exam-preparation/${subject.name}/:id/finished`}
          element={
            <PrivateRoute>
              <MonthlyExamPreparationFinished subject={subject} />
            </PrivateRoute>
          }
        />
      ))}
    </Route>
  </Routes>
);

export default App;
