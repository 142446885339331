import classNames from "classnames";
import React, { FC } from "react";
import styles from "./_index.module.scss";

export interface ToggleSwitchProps {
  label: string;
  checked: boolean;
  onToggleClick: () => void;
}

//
export const ToggleSwitch: FC<ToggleSwitchProps> = ({
  label,
  checked,
  onToggleClick,
}) => (
  <div className={styles.ToggleSwitch}>
    <label htmlFor={label} className={styles.switch_label}>
      <div className={styles.background} />
      <input
        type="checkbox"
        id={label}
        className={styles.input}
        onChange={onToggleClick}
        checked={checked}
      />
      <div className={styles.button} />
      <div className={classNames(styles.switchText, styles.textTraining)}>
        まなぶ
      </div>
      <div className={classNames(styles.switchText, styles.textQuiz)}>
        しゅぎょう
      </div>
    </label>
  </div>
);

export default ToggleSwitch;
