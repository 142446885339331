import { useMemo } from "react";
import dayjs from "dayjs";

export interface CalendarDate {
  today: number;
  startDayOfWeek: number;
  endDay: number;
  endDayOfWeek: number;
  currentMonth: string;
}

const useCalendarDate = (date: Date): CalendarDate =>
  useMemo(
    () => ({
      today: +dayjs(date).format("D"),
      startDayOfWeek: +dayjs(date).startOf("month").format("d"),
      endDay: +dayjs(date).endOf("month").format("D"),
      endDayOfWeek: +dayjs(date).endOf("month").format("d"),
      currentMonth: dayjs(date).format("M"),
    }),
    [date]
  );
export default useCalendarDate;
