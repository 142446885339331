import { Subject } from "models/subject";
import science from "features/dojo/assets/science.svg";
import socialStudies from "features/dojo/assets/social-studies.svg";
import scienceIcon from "features/dojo/assets/science-icon.png";
import socialStudiesIcon from "features/dojo/assets/social-studies-icon.png";

export const SubjectStyle = {
  Science: {
    subject: Subject.Science,
    image: science,
    icon: scienceIcon,
    background: "#76C824",
  },
  SocialStudies: {
    subject: Subject.SocialStudies,
    image: socialStudies,
    icon: socialStudiesIcon,
    background: "#FFA5A5",
  },
} as const;

// eslint-disable-next-line
export type SubjectStyle = typeof SubjectStyle[keyof typeof SubjectStyle];

export const AllSubjectStyle = Object.values(SubjectStyle);
