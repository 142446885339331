import { LoggedInDates } from "models/loggedInDate";
import { useQuery } from "react-query";
import apiClient from "services/apiClient";
import { getLoggedInDatesQueryKey } from "./queryKeys";

type Params = {
  year: number;
  month: number;
};

const fetchLoggedInDates = async (
  parameters: Params
): Promise<LoggedInDates> => {
  const res = await apiClient.get<LoggedInDates>(
    "/v1/calendar/logged-in-dates",
    {
      params: parameters,
    }
  );
  return res.data;
};

const useLoggedInDates = (params: Params, options?: { enabled?: boolean }) =>
  useQuery<Date[]>(
    getLoggedInDatesQueryKey(params.year, params.month),
    async () => (await fetchLoggedInDates(params)).loggedInDates,
    options
  );

export default useLoggedInDates;
