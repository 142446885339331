import React, { FC, ReactNode } from "react";
import classNames from "classnames";
import styles from "./_index.module.scss";

export interface AlertDialogButtonProps {
  children: ReactNode;
  color?: "red" | "blue" | "black";
  onClick: () => void;
}

const AlertDialogButton: FC<AlertDialogButtonProps> = ({
  children,
  color = "black",
  onClick,
}) => {
  const buttonClass = classNames(styles.AlertDialogButton, {
    [styles.red]: color === "red",
    [styles.blue]: color === "blue",
    [styles.black]: color === "black",
  });

  return (
    <button className={buttonClass} type="button" onClick={onClick}>
      {children}
    </button>
  );
};

export default AlertDialogButton;
