import React, { CSSProperties, FC } from "react";

export interface SpacerProps {
  size: string;
}

const Spacer: FC<SpacerProps> = ({ size }) => {
  const style: CSSProperties = {
    display: "block",
    width: size,
    height: size,
  };

  return <span style={style} />;
};

export default Spacer;
