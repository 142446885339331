import React, { FC, ReactNode } from "react";
import styles from "features/quiz/components/layouts/topAppBar/_index.module.scss";

export interface TopAppBarProps {
  children: ReactNode;
}

export const TopAppBar: FC<TopAppBarProps> = ({ children }) => (
  <div className={styles.TopAppBar}>{children}</div>
);
