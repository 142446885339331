import React, { FC, ReactNode } from "react";
import styles from "features/quiz/components/layouts/bottomAppBar/_index.module.scss";

export interface BottomAppBarProps {
  currentQuizNumber: number;
  totalQuizCount: number;
  leadingContent?: ReactNode;
  navigationCenterItem?: ReactNode;
  navigationRightItem?: ReactNode;
}

const BottomAppBar: FC<BottomAppBarProps> = ({
  currentQuizNumber,
  totalQuizCount,
  leadingContent,
  navigationCenterItem,
  navigationRightItem,
}) => (
  <div className={styles.BottomAppBar}>
    <div className={styles.leading}>{leadingContent}</div>

    <div className={styles.navigation}>
      <div className={styles.item} />

      <div className={styles.item}>
        {navigationCenterItem && navigationCenterItem}
      </div>

      <div className={styles.item}>
        {navigationRightItem && navigationRightItem}
      </div>
    </div>

    <div className={styles.progress}>
      {currentQuizNumber}問目/{totalQuizCount}問
    </div>
  </div>
);

export default BottomAppBar;
