import React, { FC, ReactNode } from "react";
import styles from "./_index.module.scss";

export interface QuizResultContainerProps {
  title: ReactNode;
  children: ReactNode;
}

const QuizResultContainer: FC<QuizResultContainerProps> = ({
  title,
  children,
}) => (
  <div className={styles.QuizResultContainer}>
    <div className={styles.title}>{title}</div>

    <div className={styles.content}>{children}</div>
  </div>
);

export default QuizResultContainer;
