import { useCallback, useEffect, useRef, useState } from "react";

const INTERVAL_MILLIS = 80;

const useTimer = (
  key: string,
  initialTimeLeftMillis: number,
  onExpire: () => void
): {
  timeLeftMillis: number;
  startTimer: () => void;
  pauseTimer: () => number;
} => {
  const [timeLeftMillis, setTimeLeftMillis] = useState(0);
  const timerRef = useRef<NodeJS.Timer>();

  const [isExpired, setIsExpired] = useState(false);

  const [startTimeMillis, setStartTimeMillis] = useState<number | undefined>(
    undefined
  );

  useEffect(
    () => setTimeLeftMillis(initialTimeLeftMillis),
    [key, initialTimeLeftMillis]
  );

  const pauseTimer = useCallback(() => {
    if (timerRef.current !== undefined) {
      clearInterval(timerRef.current);
      timerRef.current = undefined;
    }

    return startTimeMillis !== undefined ? Date.now() - startTimeMillis : 0;
  }, [startTimeMillis]);

  const startTimer = useCallback(() => {
    if (timerRef.current !== undefined) {
      clearInterval(timerRef.current);
      timerRef.current = undefined;
    }

    setStartTimeMillis(Date.now());

    timerRef.current = setInterval(() => {
      setTimeLeftMillis((prevState) => {
        const newValue = prevState - INTERVAL_MILLIS;

        if (newValue <= 0) {
          setIsExpired(true);
        }

        return Math.max(0, newValue);
      });
    }, INTERVAL_MILLIS);
  }, [onExpire]);

  useEffect(() => {
    if (isExpired) {
      pauseTimer();
      onExpire();
      setIsExpired(false);
    }
  }, [isExpired, onExpire]);

  return { timeLeftMillis, startTimer, pauseTimer };
};

export default useTimer;
