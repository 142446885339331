import React, { FC, ReactNode } from "react";
import classNames from "classnames";
import styles from "./_index.module.scss";

export interface QuizResultButtonProps {
  onClick: () => void;
  text: ReactNode;
  leadingIcon?: ReactNode;
  disabled?: boolean;
  spread?: boolean;
}

const QuizResultButton: FC<QuizResultButtonProps> = ({
  onClick,
  text,
  leadingIcon,
  disabled = false,
  spread = false,
}) => (
  <button
    type="button"
    className={styles.QuizResultButton}
    onClick={onClick}
    disabled={disabled}
  >
    {leadingIcon && leadingIcon}

    <span className={classNames({ [styles.spread]: spread })}>{text}</span>
  </button>
);

export default QuizResultButton;
