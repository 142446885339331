import React, { FC } from "react";
import classNames from "classnames";
import styles from "./_index.module.scss";

export interface RubyProps {
  text: string;
  ruby: string;
  className?: string;
}

const Ruby: FC<RubyProps> = ({ text, ruby, className }) => (
  <span className={classNames(styles.Ruby, className)}>
    <ruby data-ruby={ruby}>
      {text}
      <rp>(</rp>
      <rt>{ruby}</rt>
      <rp>)</rp>
    </ruby>
  </span>
);

export default Ruby;
