import { TrainingCategories, TrainingCategory } from "models/trainingCategory";
import { Subject } from "models/subject";
import { useQuery } from "react-query";
import apiClient from "services/apiClient";
import { getTrainingCategoriesQueryKey } from "./queryKeys";

export const convertSubjectParam = (subject: Subject) => {
  switch (subject) {
    case Subject.Science:
      return "SCIENCE";
    case Subject.SocialStudies:
      return "SOCIOLOGY";
    default:
      return "";
  }
};

type Params = {
  subject: Subject;
};

const fetchTrainingCategories = async (
  parameters: Params
): Promise<TrainingCategory[]> => {
  const res = await apiClient.get<TrainingCategories>(
    `/v1/trainings/categories?subject=${convertSubjectParam(
      parameters.subject
    )}`
  );
  return res.data.categories;
};

const useListTrainingCategories = (
  params: Params,
  options?: { enabled?: boolean }
) =>
  useQuery<TrainingCategory[]>(
    getTrainingCategoriesQueryKey(params.subject),
    async () => fetchTrainingCategories(params),
    options
  );

export default useListTrainingCategories;
