import { useQuery } from "react-query";
import { getMonthlyExamPreparationsKey } from "./queryKeys";
import MonthlyExamPreparations from "../../models/monthlyExamPreparations";
import apiClient from "../../services/apiClient";
import MonthlyExamPreparation from "../../models/monthlyExamPreparation";

interface Response {
  monthlyExams: MonthlyExamResponse[];
}

interface MonthlyExamResponse {
  number: number;
  startAt: string;
  endAt: string;
}

async function fetchMonthlyExamPreparations(): Promise<MonthlyExamPreparations> {
  const res = await apiClient.get<Response>("/v1/monthly_exams");
  const monthlyExams = res.data.monthlyExams.map<MonthlyExamPreparation>(
    (monthlyExam) => ({
      number: monthlyExam.number,
      startAt: new Date(monthlyExam.startAt),
      endAt: new Date(monthlyExam.endAt),
    })
  );

  return {
    science: monthlyExams,
    socialStudies: monthlyExams,
  };
}

const useMonthlyExamPreparations = () =>
  useQuery<MonthlyExamPreparations>(
    getMonthlyExamPreparationsKey(),
    fetchMonthlyExamPreparations,
    {}
  );
export default useMonthlyExamPreparations;
