import React, { FC, useMemo } from "react";
import styles from "./_index.module.scss";

export interface StopwatchProps {
  timeLeftMillis: number;
}

const Stopwatch: FC<StopwatchProps> = ({ timeLeftMillis }) => {
  const timeLeftSeconds = useMemo(
    () => parseFloat(`${timeLeftMillis / 1000}`).toFixed(2),
    [timeLeftMillis]
  );

  return (
    <div className={styles.Stopwatch}>
      <div className={styles.watch}>{timeLeftSeconds}</div>

      <p className={styles.label}>のこりじかん</p>
    </div>
  );
};

export default Stopwatch;
