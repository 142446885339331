import React, { FC } from "react";
import AlienUiState from "../../models/alienUiState";
import AlienGroup from "../alienGroup";
import MonthlyExamPreparation from "../../../../models/monthlyExamPreparation";
import styles from "./_index.module.scss";

const ALIEN_GROUP_ROTATION_DEGREE = 34;

export interface PlanetProps {
  pageIndex: number;
  alienGroupList: AlienUiState[][];
  onAlienClick: (preparation: MonthlyExamPreparation) => void;
}

const Planet: FC<PlanetProps> = ({
  pageIndex,
  alienGroupList,
  onAlienClick,
}) => (
  <div
    className={styles.Planet}
    style={{
      transform: `rotate(-${pageIndex * ALIEN_GROUP_ROTATION_DEGREE}deg)`,
    }}
  >
    <div className={styles.ground} />

    {alienGroupList.map((alienGroup, index) => (
      <div
        key={alienGroup.reduce(
          (sum, current) => sum + current.preparation.number,
          0
        )}
        className={styles["alien-group"]}
        style={{
          transform: `rotate(${index * ALIEN_GROUP_ROTATION_DEGREE}deg)`,
        }}
      >
        <AlienGroup alienGroup={alienGroup} onClick={onAlienClick} />
      </div>
    ))}
  </div>
);

export default Planet;
