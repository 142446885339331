import React, { FC } from "react";
import closest_preparation_balloon from "../../assets/closest_preparation_balloon.svg";
import { ClosestPreparationBalloon as BalloonModel } from "../../models/alienBalloon";
import styles from "./_index.module.scss";

export interface ClosestPreparationBalloonProps {
  balloon: BalloonModel;
  isVisible: boolean;
}

const ClosestPreparationBalloon: FC<ClosestPreparationBalloonProps> = ({
  balloon,
  isVisible,
}) => (
  <div
    className={styles.ClosestPreparationBalloon}
    style={{
      opacity: isVisible ? 1 : 0,
      visibility: isVisible ? "visible" : "hidden",
      left: `${balloon.offset.x}px`,
      top: `${balloon.offset.y}px`,
    }}
  >
    <img src={closest_preparation_balloon} alt="" />
  </div>
);

export default ClosestPreparationBalloon;
