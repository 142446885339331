import React, { FC, ReactNode, useState } from "react";
import styles from "./_index.module.scss";
import {
  AllQuizCountSettings,
  QuizCountSetting,
} from "../../models/quizCountSetting";
import AbsolutelyPositionedBox from "../absolutelyPositionedBox";

export interface QuizCountSettingDialogProps {
  initialSetting?: QuizCountSetting;
  variant: "orange" | "navy";
  children: ReactNode;
  onDismiss: (setting: QuizCountSetting) => void;
}

const QuizCountSettingDialog: FC<QuizCountSettingDialogProps> = ({
  initialSetting = QuizCountSetting.Ten,
  variant,
  children,
  onDismiss,
}) => {
  const [setting, setSetting] = useState<QuizCountSetting>(initialSetting);

  return (
    <div
      className={
        variant === "orange"
          ? styles.QuizCountSettingDialogOrange
          : styles.QuizCountSettingDialogNavy
      }
    >
      <div className={styles.frame}>
        <div className={styles.title}>問題数</div>

        <div className={styles.body}>{children}</div>

        <div className={styles.choices}>
          {AllQuizCountSettings.map((choice) => (
            <label htmlFor={choice.value} key={choice.value}>
              <input
                type="radio"
                id={choice.value}
                value={choice.value}
                checked={choice.value === setting.value}
                onChange={(e) =>
                  setSetting(
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    AllQuizCountSettings.find(
                      (quizCountSetting) =>
                        quizCountSetting.value === e.target.value
                    )!
                  )
                }
              />

              {choice.label}
            </label>
          ))}
        </div>

        <AbsolutelyPositionedBox top="16px" right="16px">
          <button
            type="button"
            className={styles.close}
            onClick={() => {
              onDismiss(setting);
            }}
          >
            <span>×</span>
          </button>
        </AbsolutelyPositionedBox>
      </div>
    </div>
  );
};

export default QuizCountSettingDialog;
