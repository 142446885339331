import React, { FC } from "react";
import { useDrag } from "react-dnd";
import Card from "features/quiz/models/rearrangementQuiz/card";
import { DraggableItemType } from "features/quiz/components/draggableItemType";
import classNames from "classnames";
import styles from "./_index.module.scss";

export interface ImageCardProps {
  card: Card;
}

export interface ImageCardDragItem {
  card: Card;
}

const ImageCard: FC<ImageCardProps> = ({ card }: ImageCardProps) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: DraggableItemType.ImageCard,
    item: { card },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const classes = classNames(styles.ImageCard, {
    [styles.dragging]: isDragging,
  });

  return (
    <div className={classes} ref={drag}>
      {card && <img src={card.imageUrl} alt="" />}
    </div>
  );
};

export default ImageCard;
