import { useQuery } from "react-query";
import { QuizType } from "../../models/quizType";
import { getQuizzesQueryKey } from "./queryKeys";
import apiClient from "../../services/apiClient";
import QuizzesResponse from "../../services/models/quizzesResponse";
import QuizResponse, {
  MasteredStatus,
} from "../../services/models/quizResponse";
import SelectionQuizA from "../../models/selectionQuizA/selectionQuizA";
import SelectionQuizAResponse from "../../services/models/selectionQuizA/selectionQuizAResponse";
import SelectionQuizAChoice from "../../models/selectionQuizA/selectionQuizAChoice";
import SelectionQuizBResponse from "../../services/models/selectionQuizB/selectionQuizBResponse";
import SelectionQuizB from "../../models/selectionQuizB/selectionQuizB";
import SelectionQuizBChoice from "../../models/selectionQuizB/selectionQuizBChoice";
import SelectionQuizCResponse from "../../services/models/selectionQuizC/selectionQuizCResponse";
import SelectionQuizCChoice from "../../models/selectionQuizC/selectionQuizCChoice";
import SelectionQuizC from "../../models/selectionQuizC/selectionQuizC";
import RearrangementQuizResponse from "../../services/models/rearrangementQuiz/rearrangementQuizResponse";
import RearrangementQuiz from "../../models/rearrangementQuiz/rearrangementQuiz";
import RearrangementQuizCard from "../../models/rearrangementQuiz/rearrangementQuizCard";
import TextInputQuizResponse from "../../services/models/textInputQuiz/textInputQuizResponse";
import TextInputQuiz from "../../models/textInputQuiz/textInputQuiz";
import { shuffle } from "../../collection";

const mapToConsecutiveCorrectAnswerCount = (
  masteredStatus: MasteredStatus
): number => {
  switch (masteredStatus) {
    case "WIP_ONE":
      return 1;
    case "WIP_ZERO":
      return 0;
    default:
      return 2;
  }
};

const mapToSelectionQuizA = (
  quizResponse: QuizResponse,
  {
    imageUrl,
    choices: choicesResponse,
    correctChoiceId,
  }: SelectionQuizAResponse
): SelectionQuizA => {
  const choices: SelectionQuizAChoice[] = choicesResponse.map(
    ({ id, name }) => ({ id, name })
  );

  return new SelectionQuizA(
    quizResponse.id,
    quizResponse.sentence,
    quizResponse.correctAnswerImageUrl,
    quizResponse.wrongAnswerImageUrl,
    quizResponse.timeLimit,
    mapToConsecutiveCorrectAnswerCount(quizResponse.masteredStatus),
    imageUrl,
    shuffle(choices),
    correctChoiceId
  );
};

const mapToSelectionQuizB = (
  quizResponse: QuizResponse,
  {
    imageUrl,
    choices: choicesResponse,
    correctChoiceId,
  }: SelectionQuizBResponse
): SelectionQuizB => {
  const choices: SelectionQuizBChoice[] = choicesResponse.map(
    ({ id, name }) => ({ id, name })
  );

  return new SelectionQuizB(
    quizResponse.id,
    quizResponse.sentence,
    quizResponse.correctAnswerImageUrl,
    quizResponse.wrongAnswerImageUrl,
    quizResponse.timeLimit,
    mapToConsecutiveCorrectAnswerCount(quizResponse.masteredStatus),
    imageUrl,
    shuffle(choices),
    correctChoiceId
  );
};

const mapToSelectionQuizC = (
  quizResponse: QuizResponse,
  {
    imageUrl,
    choices: choicesResponse,
    correctChoiceId,
  }: SelectionQuizCResponse
): SelectionQuizC => {
  const choices: SelectionQuizCChoice[] = choicesResponse.map(
    ({ id, imageUrl: choiceImageUrl }) => ({ id, imageUrl: choiceImageUrl })
  );

  return new SelectionQuizC(
    quizResponse.id,
    quizResponse.sentence,
    quizResponse.correctAnswerImageUrl,
    quizResponse.wrongAnswerImageUrl,
    quizResponse.timeLimit,
    mapToConsecutiveCorrectAnswerCount(quizResponse.masteredStatus),
    imageUrl,
    shuffle(choices),
    correctChoiceId
  );
};

const mapToRearrangementQuiz = (
  quizResponse: QuizResponse,
  { cards: cardsResponse }: RearrangementQuizResponse
): RearrangementQuiz => {
  const cards: RearrangementQuizCard[] = cardsResponse.map(
    ({ id, imageUrl, label }) => ({ id, imageUrl, label })
  );

  return new RearrangementQuiz(
    quizResponse.id,
    quizResponse.sentence,
    quizResponse.correctAnswerImageUrl,
    quizResponse.wrongAnswerImageUrl,
    quizResponse.timeLimit,
    mapToConsecutiveCorrectAnswerCount(quizResponse.masteredStatus),
    cards
  );
};

const mapToTextInputQuiz = (
  quizResponse: QuizResponse,
  { imageUrl, characters, correctAnswer }: TextInputQuizResponse
): TextInputQuiz =>
  new TextInputQuiz(
    quizResponse.id,
    quizResponse.sentence,
    quizResponse.correctAnswerImageUrl,
    quizResponse.wrongAnswerImageUrl,
    quizResponse.timeLimit,
    mapToConsecutiveCorrectAnswerCount(quizResponse.masteredStatus),
    imageUrl,
    shuffle(characters),
    correctAnswer
  );

export const parseQuizResponse = (
  response: QuizResponse
): QuizType | undefined => {
  if (response.choiceQuizA !== undefined) {
    return mapToSelectionQuizA(response, response.choiceQuizA);
  }

  if (response.choiceQuizB !== undefined) {
    return mapToSelectionQuizB(response, response.choiceQuizB);
  }

  if (response.choiceQuizC !== undefined) {
    return mapToSelectionQuizC(response, response.choiceQuizC);
  }

  if (response.rearrangementQuiz !== undefined) {
    return mapToRearrangementQuiz(response, response.rearrangementQuiz);
  }

  if (response.textInputQuiz !== undefined) {
    return mapToTextInputQuiz(response, response.textInputQuiz);
  }

  return undefined;
};

const fetchQuizzes = async (categoryId: string): Promise<QuizType[]> => {
  const response = (
    await apiClient.get<QuizzesResponse>("v1/quizzes", {
      params: { categoryId },
    })
  ).data;

  return response.quizSet.quizzes
    .map((quizResponse) => parseQuizResponse(quizResponse))
    .filter((quiz): quiz is QuizType => quiz !== undefined);
};

const useListQuiz = (categoryId: string) =>
  useQuery<QuizType[]>(
    getQuizzesQueryKey(categoryId),
    async () => fetchQuizzes(categoryId),
    {
      staleTime: 0,
      cacheTime: 0,
    }
  );

export default useListQuiz;
