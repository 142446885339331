import React, { FC, ReactNode, useMemo } from "react";
import Penguin from "assets/penguin.svg";
import AbsolutelyPositionedBox from "components/absolutelyPositionedBox";
import { Subject } from "models/subject";
import TopAppBar from "../topAppBar";
import styles from "./_index.module.scss";
import Spinner from "../../../../components/spinner";
import LoadingError from "../../../../components/loadingError";

export interface CategorySelectionProps {
  subject: Subject;
  isLoading: boolean;
  isError: boolean;
  children: ReactNode;
  actions: ReactNode;
  onPreviousClick: () => void;
  onRetry: () => void;
}

const CategorySelection: FC<CategorySelectionProps> = ({
  subject,
  children,
  actions,
  isLoading,
  isError,
  onPreviousClick,
  onRetry,
}) => {
  let className: string;
  switch (subject) {
    case Subject.Science:
      className = styles.CategorySelectionScience;
      break;
    default:
      className = styles.CategorySelectionSocialStudies;
      break;
  }

  const child = useMemo(() => {
    if (isLoading) {
      return (
        <div className={styles["spinner-frame"]}>
          <Spinner />
        </div>
      );
    }

    return children;
  }, [isLoading, children]);

  if (isError) {
    return <LoadingError onRetry={onRetry} />;
  }

  return (
    <div className={className}>
      <AbsolutelyPositionedBox top="-45px" left="588px">
        <div className={styles.penguin}>
          <img src={Penguin} alt="penguin" width="201px" height="145px" />
        </div>
      </AbsolutelyPositionedBox>
      <TopAppBar onPreviousClick={onPreviousClick} actions={actions} />
      {child}
    </div>
  );
};

export default CategorySelection;
