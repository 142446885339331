import React, { FC } from "react";
import AbsolutelyPositionedBox from "components/absolutelyPositionedBox";
import ImgButton from "../imgButton";
import TextButton from "../textButton";
import styles from "./_index.module.scss";
import rocket from "../../assets/rocket.svg";
import home from "../../assets/home.svg";

export interface TopAppBarProps {
  title: string;
  onPreviousClick: () => void;
  onRocketClick: () => void;
  onHomeClick: () => void;
}

export const TopAppBar: FC<TopAppBarProps> = ({
  title,
  onPreviousClick,
  onRocketClick,
  onHomeClick,
}) => (
  <div className={styles.TopAppBar}>
    <span className={styles.title}>{title}</span>
    <AbsolutelyPositionedBox left="20px">
      <TextButton
        text="◀︎"
        width="57px"
        fontSize="45px"
        handleClick={onPreviousClick}
      />
    </AbsolutelyPositionedBox>

    <AbsolutelyPositionedBox right="105px">
      <ImgButton
        imgSrc={rocket}
        label="ロケット"
        width="60px"
        handleClick={onRocketClick}
      />
    </AbsolutelyPositionedBox>

    <AbsolutelyPositionedBox right="19px">
      <ImgButton
        imgSrc={home}
        label="ホーム"
        width="60px"
        handleClick={onHomeClick}
      />
    </AbsolutelyPositionedBox>
  </div>
);
