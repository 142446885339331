import React, { FC } from "react";
import BottomAppBar from "features/quiz/components/layouts/bottomAppBar";
import LabeledIconButton from "features/quiz/components/labeledIconButton";
import pause from "features/quiz/assets/pause.svg";
import next from "features/quiz/assets/next.svg";
import styles from "./_index.module.scss";
import { CONSECUTIVE_CORRECT_ANSWER_COUNT_TO_MASTER } from "../../../../models/quiz";
import starActive from "../../assets/star_active.svg";
import starInactive from "../../assets/star_inactive.svg";

export interface QuizBottomAppBarProps {
  currentQuizNumber: number;
  totalQuizCount: number;
  consecutiveCorrectAnswerCount: number;
  isNextButtonVisible: boolean;
  onPauseClick: () => void;
  onNextClick: () => void;
}

const QuizBottomAppBar: FC<QuizBottomAppBarProps> = ({
  currentQuizNumber,
  totalQuizCount,
  consecutiveCorrectAnswerCount,
  isNextButtonVisible,
  onPauseClick,
  onNextClick,
}) => (
  <BottomAppBar
    currentQuizNumber={currentQuizNumber}
    totalQuizCount={totalQuizCount}
    leadingContent={
      <div className={styles.stars}>
        {[...Array(CONSECUTIVE_CORRECT_ANSWER_COUNT_TO_MASTER).keys()].map(
          (i) => (
            <img
              key={`star-${i}`}
              src={
                consecutiveCorrectAnswerCount > i ? starActive : starInactive
              }
              alt=""
            />
          )
        )}
      </div>
    }
    navigationCenterItem={
      <LabeledIconButton
        icon={<img src={pause} alt="" />}
        label="ひとやすみ"
        onClick={onPauseClick}
      />
    }
    navigationRightItem={
      isNextButtonVisible && (
        <LabeledIconButton
          icon={<img src={next} alt="" />}
          label="つぎへ"
          onClick={onNextClick}
        />
      )
    }
  />
);

export default QuizBottomAppBar;
