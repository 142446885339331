import React, { FC, useMemo } from "react";
import classNames from "classnames";
import QuizCategoryBox from "../quizCategoryBox";
import styles from "./_index.module.scss";
import { QuizCategory } from "../../../../models/quizCategory";
import { chunked } from "../../../../collection";
import { CATEGORY_COUNT_PER_PAGE } from "../../../categorySelection/const";

export interface QuizCategoryListProps {
  quizCategories: QuizCategory[];
  unlockCategory: string;
}

const QuizCategoryList: FC<QuizCategoryListProps> = ({
  quizCategories,
  unlockCategory,
}) => {
  const className = classNames(styles.CategoryList, {
    [styles.CategoryListOneItem]: quizCategories.length === 1,
  });

  const chunkedQuizCategories = useMemo(
    () => chunked(quizCategories, CATEGORY_COUNT_PER_PAGE),
    [quizCategories]
  );

  const pageIndex = useMemo(
    () =>
      chunkedQuizCategories.findIndex((categories) =>
        categories.some(
          (quizCategory) => quizCategory.category.name === unlockCategory
        )
      ),
    [chunkedQuizCategories, unlockCategory]
  );

  const visibleQuizCategories = useMemo(
    () => chunkedQuizCategories[pageIndex],
    [chunkedQuizCategories, pageIndex]
  );

  return (
    <div className={className}>
      {visibleQuizCategories.map((quizCategory) => (
        <QuizCategoryBox
          key={quizCategory.category.id}
          name={quizCategory.category.name}
          imageUrl={quizCategory.category.imageUrl}
          count={quizCategory.count}
          isUnlocked={quizCategory.isUnlocked}
          isMastered={quizCategory.isMastered}
          unlockCategory={unlockCategory}
        />
      ))}
    </div>
  );
};

export default QuizCategoryList;
