import { useQuery } from "react-query";
import apiClient from "services/apiClient";
import { getQuizCountSettingsKey } from "./queryKeys";
import QuizCountSettingsResponse from "../../services/models/quizCountSettingsResponse";
import QuizCountSettings from "../../models/quizCountSettings";
import { mapToQuizCountSettings } from "../../services/mappers/quizCountSettings"; // eslint-disable-next-line @typescript-eslint/no-unused-vars

const fetchQuizCountSettings = async (): Promise<QuizCountSettings> => {
  const response = await apiClient.get<QuizCountSettingsResponse>(
    "/v1/settings"
  );

  return mapToQuizCountSettings(response.data);
};

const useQuizCountSettings = () =>
  useQuery<QuizCountSettings>(getQuizCountSettingsKey(), async () =>
    fetchQuizCountSettings()
  );
export default useQuizCountSettings;
