import React, { FC, useMemo } from "react";
import Card from "features/quiz/models/rearrangementQuiz/card";
import CardPosition from "features/quiz/models/rearrangementQuiz/cardPosition";
import { useDragPreview } from "features/quiz/hooks/useDragPreview";
import ImageCard, {
  ImageCardDragItem,
} from "features/quiz/components/imageCard";
import LabeledDropZone from "features/quiz/components/labeledDropZone";
import DraggableZone from "features/quiz/components/draggableZone";
import { CardPositionType } from "features/quiz/models/rearrangementQuiz/cardPositionType";
import RearrangementQuiz from "models/rearrangementQuiz/rearrangementQuiz";
import RearrangementQuizAnswer from "models/rearrangementQuiz/rearrangementQuizAnswer";
import CardDropZone from "features/quiz/models/rearrangementQuiz/cardDropZone";
import styles from "./_index.module.scss";

interface DropZoneState {
  cardDropZone: CardDropZone;
  card?: Card;
}

interface InitialCardZoneState {
  card?: Card;
}

interface RearrangementQuizContentProps {
  cardDropZones: CardDropZone[];
  cardPositions: CardPosition[];
  onCardDropped: (position: CardPosition) => void;
}

const ImageCardPreview: FC = () => {
  const preview = useDragPreview<ImageCardDragItem>();

  if (preview === undefined) {
    return <div />;
  }

  const {
    item: { card },
    style,
  } = preview;

  return (
    <div style={style}>
      <ImageCard card={card} />
    </div>
  );
};

type CardMap = { [index: number]: Card };

const RearrangementQuizContent: FC<RearrangementQuizContentProps> = ({
  cardDropZones,
  cardPositions,
  onCardDropped,
}) => {
  const answerCards = cardPositions
    .filter((cardPosition) => cardPosition.type === CardPositionType.Answer)
    .reduce<CardMap>(
      (previous, cardPosition) => ({
        [cardPosition.index]: cardPosition.card,
        ...previous,
      }),
      {}
    );

  const dropZoneStates: Array<DropZoneState> = cardDropZones.map(
    (cardDropZone, index) => ({
      cardDropZone,
      card: answerCards[index],
    })
  );

  const initialPositionCards = cardPositions
    .filter((cardPosition) => cardPosition.type === CardPositionType.Initial)
    .reduce<CardMap>(
      (previous, cardPosition) => ({
        [cardPosition.index]: cardPosition.card,
        ...previous,
      }),
      {}
    );

  const initialCardZoneStates: Array<InitialCardZoneState> = new Array(
    cardDropZones.length
  )
    .fill(undefined)
    .map((_, index) => ({ card: initialPositionCards[index] }));

  return (
    <div className={styles.QuizPage}>
      <div className={styles["drop-zone-row"]}>
        {dropZoneStates.map((dropZoneState, index) => (
          <LabeledDropZone
            /* eslint-disable-next-line react/no-array-index-key */
            key={index}
            index={index}
            cardDropZone={dropZoneState.cardDropZone}
            card={dropZoneState.card}
            onCardDropped={onCardDropped}
          />
        ))}
      </div>

      <div className={styles["draggable-row"]}>
        {initialCardZoneStates.map((initialCardZoneState, index) => (
          <DraggableZone
            /* eslint-disable-next-line react/no-array-index-key */
            key={index}
            index={index}
            card={initialCardZoneState.card}
            onCardDropped={onCardDropped}
          />
        ))}
      </div>

      <ImageCardPreview />
    </div>
  );
};

export interface QuizFmtRearrangementProps {
  quiz: RearrangementQuiz;
  answer?: RearrangementQuizAnswer;
  onAnswerChanged: (answer: RearrangementQuizAnswer) => void;
}

const QuizFmtRearrangement: FC<QuizFmtRearrangementProps> = ({
  quiz,
  answer,
  onAnswerChanged,
}) => {
  const cardDropZones = useMemo<CardDropZone[]>(
    () => quiz.cards.map(({ id: choiceId, label }) => ({ choiceId, label })),
    [quiz]
  );

  const cardPositions = useMemo<CardPosition[]>(
    () =>
      quiz.cards.map((card) => {
        const currentPosition = answer?.positions?.find(
          (position) => position.card.choiceId === card.id
        );
        if (currentPosition !== undefined) {
          return currentPosition;
        }

        return {
          type: CardPositionType.Initial,
          index: quiz.initialCardPositionMap[card.id],
          card: {
            choiceId: card.id,
            imageUrl: card.imageUrl,
          },
        };
      }),
    [quiz, answer]
  );

  return (
    <RearrangementQuizContent
      cardDropZones={cardDropZones}
      cardPositions={cardPositions}
      onCardDropped={(position) => {
        onAnswerChanged(new RearrangementQuizAnswer([position]));
      }}
    />
  );
};

export default QuizFmtRearrangement;
