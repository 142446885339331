import React, { FC, ReactNode } from "react";
import styles from "./_index.module.scss";
import QuizResultContainer from "../quizResultContainer";
import Ruby from "../../../../components/ruby";
import penguin_blue from "../../assets/penguin_blue.svg";
import penguin_pink from "../../assets/penguin_pink.svg";

export interface NormalResultProps {
  correctAnswerCount: number;
  totalQuizCount: number;
  children: ReactNode;
}

const NormalResult: FC<NormalResultProps> = ({
  correctAnswerCount,
  totalQuizCount,
  children,
}) => (
  <div className={styles.NormalResult}>
    <QuizResultContainer title="おつかれさま！">
      <div className={styles.content}>
        <p className={styles["correct-answer-count-label"]}>
          <Ruby text="正解数" ruby="せいかいすう" />
        </p>

        <p className={styles["correct-answer-count"]}>
          {correctAnswerCount}/{totalQuizCount}
          <span className={styles["correct-answer-count-unit"]}>もん</span>
        </p>

        <p className={styles.message}>がんばったね！</p>
      </div>
    </QuizResultContainer>

    <div className={styles.actions}>{children}</div>

    <img className={styles["blue-penguin"]} src={penguin_blue} alt="" />
    <img className={styles["pink-penguin"]} src={penguin_pink} alt="" />
  </div>
);

export default NormalResult;
