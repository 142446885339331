import { useMutation } from "react-query";
import { AxiosResponse } from "axios";
import { v4 as uuidV4 } from "uuid";
import apiClient from "../../services/apiClient";
import { StudentAnswerType } from "../../models/studentAnswerType";
import QuizResultRequest from "../../services/models/quizResultRequest";
import QuizAnswerRequest from "../../services/models/quizAnswerRequest";
import RearrangementQuizAnswer from "../../models/rearrangementQuiz/rearrangementQuizAnswer";
import RearrangementQuiz from "../../models/rearrangementQuiz/rearrangementQuiz";
import RearrangementQuizAnswerRequest from "../../services/models/rearrangementQuiz/rearrangementQuizAnswerRequest";
import SelectionQuizAStudentAnswer from "../../models/selectionQuizA/selectionQuizAStudentAnswer";
import SelectionQuizBStudentAnswer from "../../models/selectionQuizB/selectionQuizBStudentAnswer";
import SelectionQuizCStudentAnswer from "../../models/selectionQuizC/selectionQuizCStudentAnswer";
import RearrangementQuizStudentAnswer from "../../models/rearrangementQuiz/rearrangementQuizStudentAnswer";
import { CardPositionType } from "../../features/quiz/models/rearrangementQuiz/cardPositionType";
import RearrangementQuizCardPair from "../../services/models/rearrangementQuiz/rearrangementQuizCardPair";
import { Subject } from "../../models/subject";
import queryClient from "../../services/queryClient";
import { getQuizCategoriesQueryKey } from "./queryKeys";
import { QuizResultSaveResponse } from "../../services/models/quizResultSaveResponse";
import QuizResultSaveResult from "../../models/quizResultSaveResult";

const mapToRearrangementAnswer = (
  quiz: RearrangementQuiz,
  answer: RearrangementQuizAnswer
): RearrangementQuizAnswerRequest => {
  const answerCards = answer.positions.filter(
    (position) => position.type === CardPositionType.Answer
  );

  const pairs = answerCards.map<RearrangementQuizCardPair>((answerCard) => {
    const dropZone = quiz.cards[answerCard.index];

    return {
      correctCardId: dropZone.id,
      actualCardId: answerCard.card.choiceId,
    };
  });

  return {
    id: uuidV4(),
    pairs,
  };
};

export const mapToQuizAnswerRequest = (
  studentAnswer: StudentAnswerType
): QuizAnswerRequest | undefined => {
  if (studentAnswer.answer === undefined) {
    return undefined;
  }

  const elapsedTimeString = (
    studentAnswer.elapsedTimeMillis ??
    studentAnswer.quiz.timeLimitSeconds * 1_000
  ).toString();

  const request: QuizAnswerRequest = {
    id: uuidV4(),
    quizId: studentAnswer.quiz.id,
    elapsedTime: elapsedTimeString,
    isCorrect: studentAnswer.isCorrect,
  };

  if (studentAnswer instanceof SelectionQuizAStudentAnswer) {
    request.choiceQuizA = {
      id: uuidV4(),
      choiceId: studentAnswer.answer.choiceId,
    };
  } else if (studentAnswer instanceof SelectionQuizBStudentAnswer) {
    request.choiceQuizB = {
      id: uuidV4(),
      choiceId: studentAnswer.answer.choiceId,
    };
  } else if (studentAnswer instanceof SelectionQuizCStudentAnswer) {
    request.choiceQuizC = {
      id: uuidV4(),
      choiceId: studentAnswer.answer.choiceId,
    };
  } else if (studentAnswer instanceof RearrangementQuizStudentAnswer) {
    request.rearrangementQuiz = mapToRearrangementAnswer(
      studentAnswer.quiz,
      studentAnswer.answer
    );
  } else {
    request.textInputQuiz = {
      id: uuidV4(),
      inputValue: studentAnswer.answer.text,
    };
  }

  return request;
};

type Data = {
  subject: Subject;
  categoryId: string;
  answers: StudentAnswerType[];
};

const saveQuizResult = async ({
  categoryId,
  answers,
}: Data): Promise<QuizResultSaveResponse> => {
  const apiAnswers = answers
    .map((studentAnswer) => mapToQuizAnswerRequest(studentAnswer))
    .filter((request): request is QuizAnswerRequest => request !== undefined);

  return (
    await apiClient.post<
      QuizResultSaveResponse,
      AxiosResponse<QuizResultSaveResponse>,
      QuizResultRequest
    >("v1/quizzes/result", {
      categoryId,
      answers: apiAnswers,
    })
  ).data;
};

const useSaveQuizResult = () =>
  useMutation<QuizResultSaveResult, unknown, Data, unknown>(
    async (data) => {
      const { completed } = await saveQuizResult(data);

      return {
        categoryCompleted: completed,
      };
    },
    {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries(
          getQuizCategoriesQueryKey(variables.subject)
        );
      },
    }
  );

export default useSaveQuizResult;
