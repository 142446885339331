import React, { FC, useMemo } from "react";
import classNames from "classnames";
import Alien from "../alien";
import styles from "./_index.module.scss";
import AlienUiState from "../../models/alienUiState";
import MonthlyExamPreparation from "../../../../models/monthlyExamPreparation";

export interface AlienGroupProps {
  alienGroup: AlienUiState[];
  onClick: (preparation: MonthlyExamPreparation) => void;
}

const AlienGroup: FC<AlienGroupProps> = ({ alienGroup, onClick }) => {
  const firstAlien = useMemo(() => alienGroup.at(0), [alienGroup]);
  const secondAlien = useMemo(() => alienGroup.at(1), [alienGroup]);
  const thirdAlien = useMemo(() => alienGroup.at(2), [alienGroup]);
  const fourthAlien = useMemo(() => alienGroup.at(3), [alienGroup]);

  return (
    <div className={styles.AlienGroup}>
      {firstAlien && (
        <div>
          <span
            className={classNames(
              styles["alien-label"],
              styles["alien-label-first"]
            )}
            style={{ opacity: firstAlien.showLabel ? 1 : 0 }}
          >
            第 {firstAlien.preparation.number} 回
          </span>

          <div className={classNames(styles.alien, styles["alien-first"])}>
            <Alien
              asset={firstAlien.asset}
              active={firstAlien.isSelected}
              onClick={() => onClick(firstAlien.preparation)}
            />
          </div>
        </div>
      )}

      {secondAlien && (
        <div>
          <span
            className={classNames(
              styles["alien-label"],
              styles["alien-label-second"]
            )}
            style={{ opacity: secondAlien.showLabel ? 1 : 0 }}
          >
            第 {secondAlien.preparation.number} 回
          </span>

          <div className={classNames(styles.alien, styles["alien-second"])}>
            <Alien
              asset={secondAlien.asset}
              active={secondAlien.isSelected}
              onClick={() => onClick(secondAlien.preparation)}
            />
          </div>
        </div>
      )}

      {thirdAlien && (
        <div>
          <span
            className={classNames(
              styles["alien-label"],
              styles["alien-label-third"]
            )}
            style={{ opacity: thirdAlien.showLabel ? 1 : 0 }}
          >
            第 {thirdAlien.preparation.number} 回
          </span>

          <div className={classNames(styles.alien, styles["alien-third"])}>
            <Alien
              asset={thirdAlien.asset}
              active={thirdAlien.isSelected}
              onClick={() => onClick(thirdAlien.preparation)}
            />
          </div>
        </div>
      )}

      {fourthAlien && (
        <div>
          <span
            className={classNames(
              styles["alien-label"],
              styles["alien-label-fourth"]
            )}
            style={{ opacity: fourthAlien.showLabel ? 1 : 0 }}
          >
            第 {fourthAlien.preparation.number} 回
          </span>

          <div className={classNames(styles.alien, styles["alien-fourth"])}>
            <Alien
              asset={fourthAlien.asset}
              active={fourthAlien.isSelected}
              onClick={() => onClick(fourthAlien.preparation)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AlienGroup;
