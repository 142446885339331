export const QuizCountSetting = {
  Ten: {
    value: "ten",
    label: "10問",
  },
  Twenty: {
    value: "twenty",
    label: "20問",
  },
  All: {
    value: "all",
    label: "未習得問題すべて",
  },
} as const;

// eslint-disable-next-line
export type QuizCountSetting =
  typeof QuizCountSetting[keyof typeof QuizCountSetting];
export const AllQuizCountSettings: QuizCountSetting[] =
  Object.values(QuizCountSetting);
