import React, { CSSProperties, FC, useMemo } from "react";
import useSanitizedRubiedTextHtml from "hooks/useSanitizedRubiedTextHtml";
import classNames from "classnames";
import styles from "./_index.module.scss";

export interface RubiedTextProps {
  className?: string;
  style?: CSSProperties;
  rubiedTextHtml: string;
}

const RubiedText: FC<RubiedTextProps> = ({
  className,
  style,
  rubiedTextHtml,
}) => {
  const fixedHtml = useMemo(() => {
    const parser = new DOMParser();
    const document = parser.parseFromString(rubiedTextHtml, "text/html");

    Array.from(document.body.children).forEach((child) => {
      if (!child || child.nodeName !== "RUBY") {
        return;
      }

      Array.from(child.children).forEach((rubyChild) => {
        if (
          rubyChild &&
          rubyChild.nodeName === "RT" &&
          rubyChild.innerHTML.length > 0
        ) {
          child.setAttribute("data-ruby", rubyChild.innerHTML);
        }
      });
    });

    // eslint-disable-next-line consistent-return
    return document.body.innerHTML;
  }, [rubiedTextHtml]);

  const sanitizedHtml = useSanitizedRubiedTextHtml(fixedHtml);

  return (
    <span
      className={classNames(className, styles.RubiedText)}
      style={style}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
    />
  );
};

export default RubiedText;
