import { QuizType } from "./quizType";

export default interface Quiz {
  id: string;
  sentence: string;
  correctAnswerImageUrl: string;
  wrongAnswerImageUrl: string;
  timeLimitSeconds: number;
  quizAssetUrls: string[];
  consecutiveCorrectAnswerCount: number;

  copy(args: { consecutiveCorrectAnswerCount: number }): QuizType;
}

export const CONSECUTIVE_CORRECT_ANSWER_COUNT_TO_MASTER = 2;
