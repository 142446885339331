export const MonthlyExamPreparationSubject = {
  Science: {
    name: "science",
    label: "理科",
  },
  SocialStudies: {
    name: "social-studies",
    label: "社会",
  },
} as const;

// eslint-disable-next-line
export type MonthlyExamPreparationSubject =
  typeof MonthlyExamPreparationSubject[keyof typeof MonthlyExamPreparationSubject];
export const AllMonthlyExamPreparationSubjects: MonthlyExamPreparationSubject[] =
  Object.values(MonthlyExamPreparationSubject);
