import React, { CSSProperties, FC, ReactNode } from "react";
import CSS from "csstype";
import styles from "./_index.module.scss";

export interface AbsolutelyPositionedBoxProps {
  children: ReactNode;
  top?: CSS.Property.Top;
  left?: CSS.Property.Left;
  bottom?: CSS.Property.Bottom;
  right?: CSS.Property.Right;
}

const AbsolutelyPositionedBox: FC<AbsolutelyPositionedBoxProps> = ({
  children,
  top,
  left,
  bottom,
  right,
}) => {
  const style: CSSProperties = {
    top,
    left,
    bottom,
    right,
  };

  return (
    <div className={styles.Box} style={style}>
      {children}
    </div>
  );
};

export default AbsolutelyPositionedBox;
