import { StudentAnswerType } from "./studentAnswerType";

export default class StudentAnswers {
  constructor(public readonly answers: StudentAnswerType[]) {}

  get totalQuizCount(): number {
    return this.answers.length;
  }

  get correctAnswerCount(): number {
    return this.answers.reduce(
      (previous, answer) => (answer.isCorrect ? previous + 1 : previous),
      0
    );
  }
}
