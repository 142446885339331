import { StudentAnswerType } from "models/studentAnswerType";
import { QuizType } from "../../../../models/quizType";

export default class ExplainingAnswer {
  constructor(
    public readonly currentQuizNumber: number,
    public readonly totalQuizCount: number,
    public readonly studentAnswer: StudentAnswerType,
    public readonly nextQuiz?: QuizType
  ) {}
}
