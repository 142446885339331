import React, { FC } from "react";
import styles from "./_index.module.scss";

export interface ImgProps {
  imgSrc: string;
  top: string;
  alt: string;
  left: string;
  width: string;
}

const Img: FC<ImgProps> = ({ imgSrc, alt, top, left, width }) => (
  <div className={styles.Img} style={{ top, left }}>
    <img className={styles.img} src={imgSrc} alt={alt} style={{ width }} />
  </div>
);

export default Img;
