import React, { FC } from "react";
import styles from "./_index.module.scss";

const TimeUpOverlay: FC = () => (
  <div className={styles.TimeUpOverlay}>
    <div className={styles.timer}>TIME UP!</div>
  </div>
);

export default TimeUpOverlay;
