export const AlienPosition = {
  First: {
    closestPreparationBalloon: {
      offset: {
        x: 45,
        y: 202,
      },
      stroke: {
        x1: 295,
        y1: 330,
        x2: 255,
        y2: 480,
      },
    },
    selectedPreparationBalloon: {
      offset: {
        x: 343,
        y: 144,
      },
      stroke: {
        x1: 355,
        y1: 328,
        x2: 244,
        y2: 466,
      },
    },
  },
  Second: {
    closestPreparationBalloon: {
      offset: {
        x: 116,
        y: 192,
      },
      stroke: {
        x1: 331,
        y1: 320,
        x2: 414,
        y2: 484,
      },
    },
    selectedPreparationBalloon: {
      offset: {
        x: 343,
        y: 144,
      },
      stroke: {
        x1: 562,
        y1: 348,
        x2: 544,
        y2: 408,
      },
    },
  },
  Third: {
    closestPreparationBalloon: {
      offset: {
        x: 825,
        y: 192,
      },
      stroke: {
        x1: 1039,
        y1: 320,
        x2: 942,
        y2: 478,
      },
    },
    selectedPreparationBalloon: {
      offset: {
        x: 343,
        y: 144,
      },
      stroke: {
        x1: 786,
        y1: 348,
        x2: 806,
        y2: 410,
      },
    },
  },
  Fourth: {
    closestPreparationBalloon: {
      offset: {
        x: 925,
        y: 196,
      },
      stroke: {
        x1: 1066,
        y1: 323,
        x2: 1084,
        y2: 502,
      },
    },
    selectedPreparationBalloon: {
      offset: {
        x: 343,
        y: 144,
      },
      stroke: {
        x1: 993,
        y1: 327,
        x2: 1089,
        y2: 486,
      },
    },
  },
} as const;

// eslint-disable-next-line
export type AlienPosition = typeof AlienPosition[keyof typeof AlienPosition];
export const AllAlienPositions: AlienPosition[] = Object.values(AlienPosition);
