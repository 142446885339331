import React, { FC, ReactNode } from "react";
import styles from "./_index.module.scss";

export interface TopAppBarProps {
  onPreviousClick: () => void;
  actions: ReactNode;
}

const TopAppBar: FC<TopAppBarProps> = ({ onPreviousClick, actions }) => (
  <div className={styles.TopAppBar}>
    <button
      className={styles.previousButton}
      type="button"
      onClick={onPreviousClick}
    >
      ︎<span className={styles.previous}>◀</span>
      <span className={styles.previousButtonName}>科目・モード選択</span>
    </button>
    <div className={styles.home}>{actions}</div>
  </div>
);

export default TopAppBar;
