import QuizCountSettingsResponse from "../models/quizCountSettingsResponse";
import { QuizCountSetting } from "../../models/quizCountSetting";

export const mapToQuizCountSettings = (
  response: QuizCountSettingsResponse
) => ({
  quiz: (() => {
    switch (response.quizSetting.maxCount) {
      case "QUIZ_SETTING_MAX_COUNT_TEN":
        return QuizCountSetting.Ten;
      case "QUIZ_SETTING_MAX_COUNT_TWENTY":
        return QuizCountSetting.Twenty;
      default:
        return QuizCountSetting.All;
    }
  })(),
  monthlyExamPreparation: (() => {
    switch (response.monthlyExamSetting.maxCount) {
      case "MONTHLY_EXAM_SETTING_MAX_COUNT_TEN":
        return QuizCountSetting.Ten;
      case "MONTHLY_EXAM_SETTING_MAX_COUNT_TWENTY":
        return QuizCountSetting.Twenty;
      default:
        return QuizCountSetting.All;
    }
  })(),
});

export default mapToQuizCountSettings;
