import React, { FC, useContext, useState } from "react";
import AuthContext from "components/auth/authContext";
import AuthState from "models/authState";
import { useNavigate } from "react-router-dom";
import AbsolutelyPositionedBox from "../../components/absolutelyPositionedBox";
import AlertDialog from "../../components/alertDialog";
import AlertDialogButton from "../../components/alertDialogButton";
import AlertDialogWithRetry from "../../components/alertDialogWithRetry";
import Ruby from "../../components/ruby";
import Spinner from "../../components/spinner";
import useLogout from "../../hooks/queries/useLogout";
import dojo from "./assets/dojo.png";
import logoutBackground from "./assets/logout.svg";
import penguin from "./assets/penguin.png";
import rocket from "./assets/rocket.png";
import squirrel from "./assets/squirrel.png";
import toitsuTest from "./assets/toitsuTest.png";
import monthly_exam_preparation from "./assets/monthly_exam_preparation.svg";
import Img from "./components/img";
import ImgLink from "./components/imgLink";
import LabeledImgLink from "./components/labeledImgLink";
import FilledButton from "./components/filledButton";
import styles from "./_index.module.scss";

const Home: FC = () => {
  const [isLogoutAlertVisible, setIsLogoutAlertVisible] = useState(false);
  const { isLoading, isError, isSuccess, mutate } = useLogout();
  const { state } = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <div className={styles.Home}>
      <Img imgSrc={squirrel} alt="リス" top="50px" left="200px" width="250px" />
      <Img
        imgSrc={penguin}
        alt="ペンギン"
        top="200px"
        left="800px"
        width="300px"
      />
      <ImgLink
        imgSrc={toitsuTest}
        label="全国統一小学生テスト"
        href="https://www.yotsuyaotsuka.com/toitsutest/"
        top="350px"
        left="50px"
        width="150px"
      />
      <LabeledImgLink
        imgSrc={dojo}
        label="クイズ道場"
        link="/dojo"
        top="350px"
        left="300px"
      />
      <LabeledImgLink
        imgSrc={rocket}
        label="ロケット"
        link="/rocket"
        top="550px"
        left="850px"
      />
      <LabeledImgLink
        imgSrc={monthly_exam_preparation}
        label="月例テスト対策"
        link="/monthly-exam-preparation"
        top="9px"
        left="1111px"
        imgWidth="218px"
        imgHeight="281px"
        fontSize="34px"
      />
      {state === AuthState.Authorized && (
        <AbsolutelyPositionedBox right="20px" bottom="20px">
          <div className={styles.actions}>
            <FilledButton
              containerColor="#333"
              contentColor="#FFF"
              onClick={() => {
                navigate("inquiry", { replace: true });
              }}
            >
              お問い合わせ
            </FilledButton>

            <FilledButton
              containerColor="#F83600"
              contentColor="#FFF"
              onClick={() => {
                setIsLogoutAlertVisible(true);
              }}
            >
              ログアウト
            </FilledButton>
          </div>
        </AbsolutelyPositionedBox>
      )}

      {isLogoutAlertVisible && (
        <AlertDialog
          message={
            <>
              アプリでの
              <Ruby text="勉強" ruby="べんきょう" />
              を
              <br />
              <Ruby text="終" ruby="お" />
              わりにしますか？
            </>
          }
        >
          <AlertDialogButton
            color="red"
            onClick={() => {
              mutate();
              setIsLogoutAlertVisible(false);
            }}
          >
            <Ruby text="終" ruby="お" />
            わりにする
          </AlertDialogButton>

          <AlertDialogButton
            color="blue"
            onClick={() => {
              setIsLogoutAlertVisible(false);
            }}
          >
            <Ruby text="続" ruby="つづ" />
            ける
          </AlertDialogButton>
        </AlertDialog>
      )}

      {isLoading && <Spinner />}

      {isError && (
        <AlertDialogWithRetry
          onRetry={() => {
            mutate();
          }}
        />
      )}

      {isSuccess && (
        <img className={styles["logout-image"]} src={logoutBackground} alt="" />
      )}
    </div>
  );
};

export default Home;
