import React, { FC } from "react";
import styles from "./_index.module.scss";

export interface TextButtonProps {
  text: string;
  width: string;
  fontSize: string;
  handleClick: () => void;
}

const TextButton: FC<TextButtonProps> = ({
  text,
  width,
  fontSize,
  handleClick,
}) => (
  <button
    type="button"
    className={styles.TextButton}
    style={{ width, fontSize }}
    onClick={handleClick}
  >
    {text}
  </button>
);

export default TextButton;
