import { useMemo } from "react";

const useNumberOfRowsInCalendar = (
  startDayOfWeek: number,
  endDay: number,
  endDayOfWeek: number
) =>
  useMemo(() => {
    const totalCalendarBoxes = startDayOfWeek + endDay + 6 - endDayOfWeek;
    const numberOfRows = totalCalendarBoxes > 35 ? 6 : 5;
    return numberOfRows;
  }, [startDayOfWeek, endDay, endDayOfWeek]);
export default useNumberOfRowsInCalendar;
