import { useQuery } from "react-query";
import { TrainingData, TrainingPage } from "models/trainingPages";
import apiClient from "services/apiClient";
import { getTrainingPagesQueryKey } from "./queryKeys";

type Params = {
  categoryId: string;
};

const fetchTrainingPages = async (
  parameters: Params
): Promise<TrainingPage[]> => {
  const res = await apiClient.get<TrainingData>("/v1/trainings", {
    params: parameters,
  });
  return res.data.training.pages;
};

const useTrainingPages = (params: Params, options?: { enabled?: boolean }) =>
  useQuery<TrainingPage[]>(
    getTrainingPagesQueryKey(params.categoryId),
    async () => fetchTrainingPages(params),
    options
  );
export default useTrainingPages;
