import StudentAnswer from "models/studentAnswer";
import TextInputQuiz from "models/textInputQuiz/textInputQuiz";
import TextInputQuizAnswer from "models/textInputQuiz/textInputQuizAnswer";

export default class TextInputQuizStudentAnswer
  implements StudentAnswer<TextInputQuiz>
{
  constructor(
    public readonly quiz: TextInputQuiz,
    public readonly answer?: TextInputQuizAnswer,
    public readonly elapsedTimeMillis?: number
  ) {}

  get isAnswered(): boolean {
    return this.answer !== undefined;
  }

  get isCorrect(): boolean {
    return this.quiz.correctAnswer === this.answer?.text;
  }

  get answerText(): string | undefined {
    return this.quiz.correctAnswer;
  }
}
