import React, { FC, useMemo } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import useDebugQuizUiState from "./hooks/useDebugQuizUiState";
import Spinner from "../../components/spinner";
import QuizLoadError from "../quiz/models/uiState/quizLoadError";
import LoadingError from "../../components/loadingError";
import LoadingQuiz from "../quiz/models/uiState/loadingQuiz";
import AnsweringQuiz from "../quiz/models/uiState/answeringQuiz";
import QuizContent from "../quiz/components/quizContent";
import styles from "./_index.module.scss";
import MenuDialog from "../quiz/components/menuDialog";
import AnswerExplanation from "./components/answerExplanation";
import useListDebugQuiz from "../../hooks/queries/useListDebugQuiz";

export interface ContentProps {
  quizId: string;
}

const Content: FC<ContentProps> = ({ quizId }) => {
  const navigate = useNavigate();
  const { isLoading, isError, data, refetch } = useListDebugQuiz(quizId);
  const quizzes = useMemo(() => data ?? [], [data]);

  const {
    uiState,
    isPausing,
    onAnswerQuiz,
    onConfirmAnswer,
    onPauseTimer,
    onBack,
    onPauseAnswerExplanation,
    onResume,
    onReset,
  } = useDebugQuizUiState(quizId, isLoading, isError, quizzes);

  const child = useMemo(() => {
    if (uiState instanceof LoadingQuiz) {
      return <Spinner />;
    }

    if (uiState instanceof QuizLoadError) {
      return <LoadingError onRetry={refetch} />;
    }

    if (uiState instanceof AnsweringQuiz) {
      return (
        <QuizContent
          currentQuizNumber={uiState.currentQuizNumber}
          totalQuizCount={uiState.totalQuizCount}
          studentAnswer={uiState.studentAnswer}
          // debug画面ではtimerを動かさない
          isPausing
          onNavigationIconClick={() => {
            navigate("/", { replace: true });
          }}
          onAnswerChanged={onAnswerQuiz}
          onConfirmAnswer={onConfirmAnswer}
          onPause={onPauseTimer}
        />
      );
    }

    return (
      <AnswerExplanation
        currentQuizNumber={uiState.currentQuizNumber}
        totalQuizCount={uiState.totalQuizCount}
        studentAnswer={uiState.studentAnswer}
        onNavigationIconClick={() => {
          navigate("/", { replace: true });
        }}
        onPauseClick={onPauseAnswerExplanation}
        onBackClick={onBack}
      />
    );
  }, [uiState]);

  return (
    <div className={styles.Quiz}>
      {child}
      {isPausing && (
        <MenuDialog
          onResume={onResume}
          onNavigateToHome={() => {
            navigate("/", { replace: true });
          }}
          onReset={onReset}
        />
      )}
    </div>
  );
};

const DebugQuiz: FC = () => {
  const { id } = useParams<{ id: string }>();
  if (id === undefined) {
    return <Navigate to="/" replace />;
  }
  return <Content quizId={id} />;
};

export default DebugQuiz;
