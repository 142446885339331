import { AxiosError } from "axios";
import { Student } from "models/student";
import { QueryKey, useQuery } from "react-query";
import apiClient from "services/apiClient";
import { getStudentQueryKey } from "./queryKeys";

const fetchStudent = async (): Promise<Student> => {
  const res = await apiClient.get<Student>("/v1/students/me");
  return res.data;
};

const useStudent = () =>
  useQuery<Student, AxiosError, Student, QueryKey>(
    getStudentQueryKey(),
    async () => fetchStudent()
  );

export default useStudent;
